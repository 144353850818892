/*
Obfuscate URL

Transform a URL (HTTP/HTTPS) to a javascript: URI that obfuscate the original value, but allow to be visited
It is used to hide URLs to SEO crawlers
Does client usage (HTML rendered client side) is needed is doubtful: link crawling is made on raw HTML of the server rendered page
Note: the user can't use open in a new tab functionality with this URI
 */

export default (url = '', target = '_self', noopener = null, noreferrer = null) => {
  if (!url) return null;

  const encodedUrl = btoa(url);
  switch (target) {
    case '_self':
      return `javascript:void(location=atob('${encodedUrl}'))`;

    case '_blank': {
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_features
      const blankWindowFeatures = new Set();
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#browser_compatibility target="_blank" implies rel="noopener" behavior
      if (noopener !== false) {
        blankWindowFeatures.add('noopener');
      }
      if (noreferrer === true) {
        blankWindowFeatures.add('noreferrer');
      }
      return `javascript:void(open(atob('${encodedUrl}'),'','${[...blankWindowFeatures].join()}'))`;
    }

    case '_parent':
      return `javascript:void(parent.location=atob('${encodedUrl}'))`;

    case '_top':
      return `javascript:void(top.location=atob('${encodedUrl}'))`;

    default: {
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/open#window_features
      const windowFeatures = new Set();
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#browser_compatibility target="_blank" implies rel="noopener" behavior
      if (noopener === true) {
        windowFeatures.add('noopener');
      }
      if (noreferrer === true) {
        windowFeatures.add('noreferrer');
      }
      return `javascript:void(open(atob('${encodedUrl}'),'${target}','${[...windowFeatures].join()}'))`;
    }
  }
};
