/**
 * Carousel using CSS Scroll Snap
 *
 * Build and handle indicators
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Scroll_Snap
 * @see https://nolanlawson.com/2019/02/10/building-a-modern-carousel-with-css-scroll-snap-smooth-scrolling-and-pinch-zoom/
 * @see https://developers.google.com/web/updates/2018/07/css-scroll-snap
 */

const CAROUSEL_SCROLLER_CLASS = 'f-carousel__scroller';
const CAROUSEL_ITEM_CLASS = 'f-carousel__item';
// const CAROUSEL_ITEM_AUDIO_CLASS = 'f-carousel__item--audio';
// const CAROUSEL_INDICATORS_CLASS = 'f-carousel__indicators';
const CAROUSEL_INDICATOR_CLASS = 'f-carousel__indicator';
const CAROUSEL_INDICATOR_ACTIVE_CLASS = 'f-carousel__indicator--active';
// const CAROUSEL_INDICATOR_AUDIO_CLASS = 'f-carousel__indicator--audio';

export default class {
  #scroller;
  #items;
  #indicators;

  constructor({ el }) {
    this.el = el;

    this.#scroller = this.el.querySelector(`.${CAROUSEL_SCROLLER_CLASS}`);
    this.#items = this.el.getElementsByClassName(CAROUSEL_ITEM_CLASS); // live collection
    this.#indicators = this.el.getElementsByClassName(CAROUSEL_INDICATOR_CLASS); // live collection

    // this.#buildIndicators();
    this.#scroller.addEventListener('scroll', () => {
      const index = Math.round((this.#scroller.scrollLeft / this.#scroller.scrollWidth) * this.#items.length);
      this.#update(index);
    });
  }

  // /**
  //  * Scrolls to a specific slide
  //  * @param {number} index index of targeted slide
  //  */
  // scrollToItem(index) {
  //   this.#scroller.scrollLeft = (this.#scroller.scrollWidth / this.#items.length) * index;
  // }

  /**
   * Set indicator bullet as active
   *
   * Add or remove class `f-carousel__indicator--active`
   *
   * @param {number} index Index of current slide
   */
  #update(index) {
    const current = this.#indicators[index];
    for (const indicator of this.#indicators) {
      indicator.classList.toggle(CAROUSEL_INDICATOR_ACTIVE_CLASS, indicator === current);
    }
  }

  /* Build bullets indicators */
  // #buildIndicators() {
  //   const indicators = this.el.querySelector(`.${CAROUSEL_INDICATORS_CLASS}`);
  //   if (!indicators) {
  //     return;
  //   }
  //   const firstItem = this.#items[0];
  //   let audioIndicator = null;
  //   for (const item of this.#items) {
  //     const indicator = document.createElement('li');
  //     indicator.classList.toggle(CAROUSEL_INDICATOR_ACTIVE_CLASS, item === firstItem);
  //     indicator.classList.add(CAROUSEL_INDICATOR_CLASS);
  //     if (item.classList.contains(CAROUSEL_ITEM_AUDIO_CLASS)) {
  //       indicator.classList.add(CAROUSEL_INDICATOR_AUDIO_CLASS);
  //       indicator.setAttribute('data-automation-id', 'product-visual-audio-indicator');
  //       audioIndicator = indicator;
  //       continue;
  //     }
  //     indicators.appendChild(indicator);
  //   }
  //   if (audioIndicator) {
  //     indicators.appendChild(audioIndicator);
  //   }

  //   this.el.appendChild(indicators);
  // }
}
