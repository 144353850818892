const properties = new Map();

/**
 * Get the value of a property from global object (window) This function exist because some third parties don't know how
 * to implement a bootstrap that register calls before their own script is executed. The simplest solution that could be
 * implemented (by the thrid party script) is a queue (with a `push()` method) Some examples of third parties that
 * implement this:
 *
 * - https://developers.kameleoon.com/command-queue.html `window.kameleoonQueue = window.kameleoonQueue || [];
 *   window.kameleoonQueue.push(['Events.trigger', 'anotherEvent']);`
 * - https://developers.google.com/tag-platform/tag-manager/web/datalayer#how_data_layer_information_is_processed
 *   `window.dataLayer = window.dataLayer || []; window.dataLayer.push({'event': 'event_name'});`
 * - https://experienceleague.adobe.com/docs/experience-platform/tags/client-side/satellite-object.html#monitor
 *   `window._satellite = window._satellite || {}; window._satellite._monitors = window._satellite._monitors || [];
 *   window._satellite._monitors.push({...config})`
 * - https://docs.contentsquare.com/webtracking-impl-en/ "Custom HTML": `window._uxa = window._uxa || [];
 *   window._uxa.push([funcName, arg1, arg2, ...args])`
 * - https://help.iadvize.com/hc/en-gb/articles/4403097203986-Cookies-with-explicit-consent-implementation-guide#step-2-set-up-an-additional-script-to-indicate-the-visitor-consent-on-the-cookie-banner:
 *   `window.iAdvizeInterface = window.iAdvizeInterface || []; iAdvizeInterface.push(function(iAdvize){...});`
 *
 * @param {string} propName Property name to get from the global object
 * @returns {Promise<any>}
 * @example
 *   getGlobalProperty('');
 */
export default (propName) => {
  if (properties.has(propName)) {
    return properties.get(propName);
  }

  const promise = new Promise((resolve) => {
    // To know when the property is defined:
    // - first check if not already exist (late call)
    // - else, define a setter that replace itself with the final value and resolve the promise

    if (propName in window) {
      resolve(window[propName]);
      return;
    }

    Object.defineProperty(window, propName, {
      set: (value) => {
        Object.defineProperty(window, propName, { value, configurable: true });
        resolve(value);
      },
      configurable: true,
    });
  });
  properties.set(propName, promise);

  return promise;
};
