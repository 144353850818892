import '../../../styles/vendors/magnific-popup.less';
import translation from '../../../helpers/translation/translation.js';
import { isMobile } from '../../../configuration/Configuration.js';
import { deepAssign } from '../../../helpers/object/index.js';
import trapFocus from '../../../helpers/focus/trapFocus.js';
import asArray from '../../../helpers/type/as-array.js';

export default class {
  #element;
  #options;

  constructor(element, options = {}) {
    this.#options = options;

    this.#element = element ? asArray(element) : [];

    for (const element of this.#element) {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        this.open(this.#options);
      });
    }
  }

  get instance() {
    // If you have an error, that means you use instance property before the lib is loaded (async)
    // You can only after options.callbacks.open() has been called
    return $.magnificPopup?.instance;
  }

  async open(options = {}) {
    await import('magnific-popup');
    const closeTitle = translation('assets.javascript.magnificpopup.close');

    // If the instance is already opened, force to close it or options are ignored and handle it async
    // See https://github.com/dimsemenov/Magnific-Popup/issues/889
    // See https://github.com/dimsemenov/Magnific-Popup/blob/92c92875b21020f5e73e8ae01d94521a06877ee2/src/js/core.js#L376-L381
    // Add setTimeout to wait for click events listener to finish (especialy the one that handle click on backdrop that autoclose the popin). See https://github.com/dimsemenov/Magnific-Popup/blob/92c92875b21020f5e73e8ae01d94521a06877ee2/src/js/core.js#L211-L213
    if (this.instance?.isOpen ?? false) {
      const closePromise = new Promise((resolve) =>
        this.instance.ev.one('mfpAfterClose', () => setTimeout(() => resolve(), 0))
      );
      this.instance.close();
      await closePromise;
    }

    // https://dimsemenov.com/plugins/magnific-popup/documentation.html#translating
    // https://dimsemenov.com/plugins/magnific-popup/documentation.html#closemarkup
    $.magnificPopup.defaults = deepAssign($.magnificPopup.defaults, {
      tClose: closeTitle, // Alt text on close button
      tLoading: translation('assets.javascript.magnificpopup.loading'), // Text displayed during loading
      closeMarkup: `<button title="${closeTitle}" type="button" class="mfp-close" aria-label="${closeTitle}">&times;</button>`, //TODO use symbol instead a char
      ajax: {
        tError: translation('assets.javascript.magnificpopup.error'), // Text when ajax request failed
      },
      removalDelay: isMobile ? '300' : '0', // Delay before popin is removed from DOM. Used for the animation,
      focus: '.mfp-close', // first element to be focused is the close button
    });

    // handle focus when popin is opened
    if (!options.callbacks) {
      options.callbacks = {};
    }
    const initialCallbackOpen = options.callbacks?.open;
    options.callbacks.open = () => {
      initialCallbackOpen?.();
      this.#handleFocus();
      // reset open call to initial state to avoid infinite call
      options.callbacks.open = initialCallbackOpen;
    };

    if (this.#element.length) {
      $(this.#element).magnificPopup(options).magnificPopup('open');
      return;
    }

    $.magnificPopup.open(options);
    $.magnificPopup.instance.container.on('click', '.js-mfp-close', () => {
      this.instance.close();
    });
  }

  close() {
    this.instance?.close();
  }

  /** Handle focus inside modal. Set first / last focusable element in modal. */
  #handleFocus() {
    const wrapper = this.instance.wrap[0];
    wrapper.removeAttribute('tabindex');
    trapFocus(wrapper);
  }
}
