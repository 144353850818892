import { getWishlistsURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
export default async () => {
  const response = await rejectNonOkResponse(
    fetch(getWishlistsURL, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  );

  /*
{
   "addBasketLink":"http://sgew1085.hq.fr.fnac.group/Front.Fnaccom.R19.1/basket/add",
   "wishlists":[
      {
         "publicId":"b8db15c3-71fd-40e6-87d7-04342b9781f6",
         "name":"test2",
         "numberOfItems":1,
         "creationDate":"2018-10-18T14:41:22.69",
         "publicWishListLink":"http://sgew1085.hq.fr.fnac.group/Front.Fnaccom.R19.1/wishlist/b8db15c3-71fd-40e6-87d7-04342b9781f6",
         "itemsDetails":[
            {
               "Prid":10814954,
               "Catalog":1,
               "AddBasketPayload":{
                  "ProductId":10814954
               }
            }
         ]
      },
      {
         "publicId":"e293663f-75d1-4b29-991f-a9053ace261d",
         "name":"test",
         "numberOfItems":2,
         "creationDate":"2019-05-14T12:20:42.777",
         "publicWishListLink":"http://sgew1085.hq.fr.fnac.group/Front.Fnaccom.R19.1/wishlist/e293663f-75d1-4b29-991f-a9053ace261d",
         "itemsDetails":[
            {
               "Prid":8868117,
               "Catalog":1,
               "AddBasketPayload":{
                  "ProductId":8868117
               }
            },
            {
               "Prid":10115798,
               "Catalog":1,
               "AddBasketPayload":{
                  "ProductId":10115798,
                  "Offer":"6def75fa-ce23-93a7-2eb0-d158c8592ddf"
               }
            }
         ]
      },
      {
         "publicId":"a73780cb-fbc6-47f2-8758-e15631ff9110",
         "name":"test",
         "numberOfItems":0,
         "creationDate":"2019-05-14T11:54:36.707",
         "publicWishListLink":"http://sgew1085.hq.fr.fnac.group/Front.Fnaccom.R19.1/wishlist/a73780cb-fbc6-47f2-8758-e15631ff9110",
         "itemsDetails":[

         ]
      },
   ]
}
*/

  const { wishlists } = await response.json();

  return wishlists;
};
