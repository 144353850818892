import renderWishlistPopin from './_WishlistPopin.liquid';
import WishlistDialog from './WishlistDialog.js';
import { isMobile, myAccountWishlistTemplateURL, signInURL } from '../../configuration/Configuration.js';
import renderWishlistPopinItems from './_WishlistPopinItems.liquid';
import renderWishlistPopinConfirmation from './_WishlistPopinConfirmation.liquid';
import renderWishlistPopinNotLogged from './_WishlistPopinNotLogged.liquid';
import renderWishlistDialogChoices from './_WishlistDialogChoices.liquid';
import parseHTML from '../../helpers/dom/parseHTMLFragment.js';
import componentRegistry from '../registry.js';

export default class extends WishlistDialog {
  constructor({ el } = {}) {
    super({
      el: el ?? parseHTML(renderWishlistPopin()).firstElementChild,
      renderChoices: (data) => renderWishlistDialogChoices(data),
      renderUnauthenticated: (data) =>
        renderWishlistPopinNotLogged({
          ...data,
          signInURL,
        }),
      renderComplete: (data) =>
        renderWishlistPopinConfirmation({
          ...data,
          myAccountWishlistURL: myAccountWishlistTemplateURL(),
        }),
      renderItems: (data) => renderWishlistPopinItems(data),
    });

    this.el.querySelector('.js-addtoWishlist-close')?.addEventListener('click', this.#handleClickEvent.bind(this));
  }

  #handleClickEvent() {
    this.close();
  }

  showModal() {
    if (!this.open) {
      this.el.classList.add('f-wishlist-dialog--open');
    }

    super.showModal({
      items: {
        src: this.el,
      },
      fixedContentPos: true,
      mainClass: `mfp--modal ${isMobile ? ' mfp--mobile' : ''}`,
      callbacks: {
        close: () => {
          this.close();
        },
      },
    });
  }

  close() {
    if (!this.open) {
      return;
    }

    this.el.classList.remove('f-wishlist-dialog--open');

    this.magnificPopup.close();
    super.close();
  }
}

componentRegistry.define('js-wishlistDialog', WishlistDialog);
