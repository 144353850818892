import { trackView, trackIntent } from '../../helpers/tracking/track.js';

const getElement = (target) => target?.closest('.js-HeaderTabContent') ?? null; // FIXME use the constructor instead classname for Web Component

const upperCamelCase = (s) =>
  s
    .split(/\W+/)
    .map((s) => s.substr(0, 1).toUpperCase() + s.slice(1))
    .join('');

window.addEventListener('show', ({ target }) => {
  const tab = getElement(target);
  if (!tab) return;
  trackView(`show`, `Header${upperCamelCase(tab.name)}TabContent`); // name: shipping, cart, store (store-fav, store-gen), account
});
// see FnaccomOrderpipe Src/www/OrderPipe/LayerBasket/Core/LayerBasket/_View.cshtml
// Cart modal One click link clicked
window.addEventListener(
  'click',
  ({ target }) =>
    getElement(target.closest(/*js-basketModal*/ '.js-Basket-oneclick'))?.name === 'cart' &&
    trackIntent('oneclick', 'HeaderCartTab')
);
