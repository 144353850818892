import Dialog from '../../components/dialog/index.js';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import componentRegistry from '../registry.js';

export default class CartPreviewDialog extends Dialog {
  #magnificPopup = new MagnificPopup();
  #serviceSubmitters;

  constructor({ el }) {
    super({ el });

    this.#serviceSubmitters = this.el.getElementsByClassName('js-ProductBuy-addService'); // live collection
    this.el.addEventListener('addcartitem', this.#handleAddCartItem.bind(this));
    this.el.addEventListener('beforeaddcartitem', this.#handleBeforeAddCartItem.bind(this));
  }

  showModal() {
    super.showModal();

    this.#magnificPopup.open({
      items: {
        src: this.el,
        overflowY: 'hidden',
      },
      mainClass: `mfp--modal${IS_MOBILE ? ' mfp--mobile' : ''}`,
      preloader: true,
      fixedContentPos: true,
      closeBtnInside: true,
      callbacks: {
        close: () => {
          this.close();
        },
        open: () => {
          this.el.querySelector('.js-basketModal-close')?.addEventListener('click', () => this.#magnificPopup.close());
        },
      },
    });
  }

  // Only one type of service can be added (Exception : If there are insurance and guarantee)
  // If the use add one, hide buy button of other ones
  // We make assumption that the item has been added to cart by using beforeaddcartitem instead of addcartitem, but the event could be default prevented, or an network/server error could happened
  // There are 3 solutions to solve that with drawbacks:
  // - use addcartitem event, but this show a delay between inline buy button show immediately "added to cart" and other warranty buy buttons are hidden
  // - use addcartitem event, and remove performance optim of buy button that immediately show "added to cart" and revert in case of error (listen for error event of ProductBuyForm)
  // - listen beforeaddcartitem, wait next microtask (await Promise.resolve()) and check if the event has been default prevented and listen error event of ProductBuyForm to show again other warranty buy buttons
  #handleBeforeAddCartItem({ target }) {
    const currentSubmitter = target.querySelector('.js-ProductBuy-addService');

    if (currentSubmitter.dataset.serviceType) {
      const sameTypeServiceOtherSubmitters = Array.from(this.#serviceSubmitters).filter(
        (s) => s !== currentSubmitter && s.dataset.serviceType === currentSubmitter.dataset.serviceType
      );
      for (const submitter of sameTypeServiceOtherSubmitters) {
        submitter.hidden = true;
      }
    }
  }

  // Because the backend API of add items to cart doesn't work as excepted, we need to add again all services already added to the cart in same time with the new one
  // This is a workaround to avoid this issue.
  // And the side effect of this, is all others services already added are tracked as a new products in the cart each times you add a new service
  // It's hacky, but there is no other way to do that right now.
  // A better way would be got the current cart content, but the only API we could use (~/orderpipe/api/v1/pop/cart) is not updated synchronously (at least when we use ~/basket/add API)
  #handleAddCartItem({ target }) {
    const currentSubmitter = target.querySelector('.js-ProductBuy-addService');

    for (const submitter of Array.from(this.#serviceSubmitters).filter((s) => s !== currentSubmitter)) {
      // Each times you add a service, inject the hidden product input in the form of others services
      const fields = currentSubmitter.form.elements.namedItem('products');
      const [firstProductField] = fields instanceof RadioNodeList ? [...fields] : [fields];
      submitter.form.append(firstProductField.cloneNode());
    }
  }
}

componentRegistry.define('js-cartPreviewDialog', CartPreviewDialog);
