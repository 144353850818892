import componentRegistry from '../registry.js';
import Overlay from '../overlay/index.js';
import isFromEscapeKey from '../../helpers/event/is-from-escape-key.js';
import trapFocus from '../../helpers/focus/trapFocus.js';
import './side-nav-panel.less';
import './sub-side-nav-panel.js';

const CLASSNAMES = {
  SIDE_NAV_MENU: `js-SubSideNavPanelBloc`,
  ITEM: `js-SideNavPanel-listItem`,
  ITEM_EXTRA: 'SideNav-extra',
  LINK: `js-SideNavPanel-listLink`,
  ACTIVE: 'isActive',
  HIDE: 'hide',
};

/** SideNavPanel */
export default class SideNavPanel {
  #activeMenu = null;
  #isVisible = false;
  #isLoading = false;
  #mouseOverTimeout;

  constructor({ el }) {
    this.el = el;
    this.subPanelContainer = null;
    this.closeButton = this.el.querySelector('.close');
    this.listWrapper = this.el.querySelector('.js-SideNavPanel-listWrapper');

    Object.defineProperties(this.el, {
      onLoading: {
        value: this.onLoading,
      },
      slideIn: {
        value: this.slideIn,
        configurable: true,
      },
      slideOut: {
        value: this.slideOut,
      },
    });

    this.overlay = new Overlay({
      modifier: 'menu',
      spinner: false,
      closeOnClick: true,
      disableScroll: true,
    });

    this.overlay.addEventListener('close', () => this.slideOut());

    this.menuItems = [];

    this.#useOver();

    this.closeButton.addEventListener('click', this.handleOverlayHide);
    window.addEventListener(
      'keydown',
      ({ key }) => this.#isVisible && isFromEscapeKey({ key }) && this.handleOverlayHide()
    );

    this.el.addEventListener('transitionend', this.onTransitionEnd);

    // side nav MoreView
    this.el.querySelector('.js-ContentToggle')?.addEventListener('click', (e) => {
      e.preventDefault();
      this.el.querySelector('.js-Content').classList.toggle('js-moreContentNav--active');
      this.el.querySelector('.js-moreContentNav-plus').classList.toggle('js-moreContentNav--active');
      this.el.querySelector('.js-moreContentNav-less').classList.toggle('js-moreContentNav--active');
    });

    // set new top position based on sub nav header scroll
    window.addEventListener('header-sub-nav:scroll', ({ detail }) => {
      if (!this.isHomePage) {
        this.listWrapper.style.top = `${detail.headerHeight}px`;
        this.listWrapper.style.height = `calc(100% - ${detail.headerHeight}px)`;
      }
      this.el.querySelector('.js-header-logo-panel').style.height = `${detail.headerHeight}px`;
    });

    // load menus on home page
    this.isHomePage && this.setMenuItems();
    // calculate transition duration
    // window.addEventListener('resize', this.setTransitionDuration);
    // this.setTransitionDuration();
  }

  async #useOver() {
    const supportOver = matchMedia('(hover: hover)').matches;
    if (supportOver) {
      this.el.addEventListener('mouseover', (event) => {
        if (this.isHomePage && !this.#isVisible) {
          this.#mouseOverTimeout = setTimeout(() => this.openMenu(event), 250);
        } else {
          this.openMenu(event);
        }
        if (this.isHomePage) {
          this.closeMenuOver();
        }
      });
      this.el.addEventListener('mouseout', () => clearTimeout(this.#mouseOverTimeout));
    } else {
      this.el.addEventListener('click', (event) => this.openMenu(event));
    }
  }

  // OpenMenu
  openMenu = (event) => {
    if (event.target.classList.contains(CLASSNAMES.LINK)) {
      event.preventDefault();
      this.showSubPanel(event.target.getAttribute('aria-controls'));
    }
    if (event.target.classList.contains(CLASSNAMES.ITEM_EXTRA)) {
      this.handleOverlayHide();
    }
  };

  // CloseMenuOver
  closeMenuOver = () => {
    document.querySelector('.f-overlay')?.addEventListener('mouseover', (e) => this.handleOverlayHide(e));
  };

  get isHomePage() {
    return this.el.classList.contains('SideNavPanel--home');
  }

  /**
   * Manage Panel visibility (Show/Hide) We remove display properties only after transitionEnd
   *
   * 1. On home page animation is set on subPanel
   * 2. On other pages animation is set on panel
   *
   * @param {boolean} isVisible
   */
  set toggleVisible(isVisible) {
    this.#isVisible = isVisible;
    this.el.setAttribute('aria-hidden', !isVisible);
    // the active class that does not use toggle mode are removed on transition end
    if (this.isHomePage) {
      if (isVisible) {
        this.el.classList.add(CLASSNAMES.ACTIVE);
      }
      this.subPanelContainer.classList.toggle(CLASSNAMES.ACTIVE, isVisible);
    } else {
      if (isVisible) {
        this.subPanelContainer.classList.add(CLASSNAMES.ACTIVE);
      }
      this.el.classList.toggle(CLASSNAMES.ACTIVE, isVisible);
    }
    // hide close button immediately
    if (!isVisible) {
      this.closeButton.classList.add(CLASSNAMES.HIDE);
    }
  }

  /**
   * Aria control is the id of sub panel
   *
   * @returns {string} PanelID
   */
  get ariaControl() {
    return this.activeMenu.getAttribute('aria-controls');
  }

  /**
   * Manage menu link state (Activate/Deactivate)
   *
   * @param {number | null} index
   */
  set activeMenu(index) {
    // unset previous state
    if (this.#activeMenu) {
      this.#activeMenu?.classList.remove(CLASSNAMES.ACTIVE);
      this.#activeMenu?.setAttribute('aria-expanded', 'false');
    }
    // set the new state or clear if null
    if (index != null) {
      this.#activeMenu = this.menuItems[index];
      this.#activeMenu.classList.add(CLASSNAMES.ACTIVE);
      this.#activeMenu.setAttribute('aria-expanded', 'true');
    } else {
      this.#activeMenu = null;
    }
  }

  get activeMenu() {
    return this.#activeMenu;
  }

  setMenuItems() {
    this.subPanelContainer = this.el.querySelector('.js-SubSideNavPanel');
    this.menuItems = [...this.el.querySelectorAll(`.${CLASSNAMES.ITEM}`)].map((item) => {
      const panel = item.querySelector(`.${CLASSNAMES.SIDE_NAV_MENU}`);
      if (!panel) return;
      this.subPanelContainer.appendPanelBloc(panel);
      return item.querySelector(`.${CLASSNAMES.LINK}`);
    });
  }

  /**
   * 1. Hide overlay if it is opened by side panel
   * 2. If not dispatch event hide to the toggler's overlay
   */
  handleOverlayHide = () => {
    if (this.isHomePage) {
      this.overlay.hide();
    } else {
      this.el.dispatchEvent(new CustomEvent('hide'));
    }
  };

  /**
   * When user click on a menu link we show its sub panel in the order below:
   *
   * 1. If the same panel is already opened do nothing
   * 2. If another sub panel opened we hide it and we reset the state
   * 3. Show the clicked sub panel
   *
   * @param {string} id
   */
  showSubPanel(id) {
    if (!id || id === this.subPanelContainer.activeID) {
      return;
    }
    this.toggleVisible = true;
    // slide in the clicked panel
    this.activeMenu = this.menuItems.findIndex((menu) => menu.getAttribute('aria-controls') === id);
    this.subPanelContainer.show(id);
    if (!this.isHomePage && !this.activeMenuIndex === 1) {
      this.activeMenu.focus();
    }
    // do not open overlay if toggler's overlay is opened
    this.isHomePage && !this.overlay.isVisible && this.overlay.show();
  }

  /**
   * Slide in the panel
   *
   * @param {{ activeMenuIndex: number }} options
   */
  slideIn = (options = {}) => {
    if (!this.menuItems.length) {
      this.setMenuItems();
    }
    this.toggleVisible = true;
    if (options.activeMenuIndex >= 0) {
      this.activeMenu = options.activeMenuIndex;
      this.subPanelContainer.show(this.ariaControl);
      this.closeButton.focus();
    }
  };

  /** Slide out the panel */
  slideOut = () => {
    this.toggleVisible = false;
    if (this.isHomePage) {
      this.activeMenu?.focus();
    }
    this.activeMenu = null;
  };

  /**
   * Some divs should be display none when we close their containers. To preserve animation we need to wait until the
   * container is fully hidden, then we display none its children.
   *
   * @param {HTMLDivElement} target
   */
  onTransitionEnd = ({ target }) => {
    // do not close sub panel immediately, only after transitionend of parent panel
    if (!this.#isVisible && this.subPanelContainer) {
      if (target === this.el) {
        this.subPanelContainer.classList.remove(CLASSNAMES.ACTIVE);
      } else if (this.isHomePage && target === this.subPanelContainer) {
        this.el.classList.remove(CLASSNAMES.ACTIVE);
      }
      this.subPanelContainer.hide();
    }
    // show close button after animation
    if (this.#isVisible || this.#isLoading) {
      this.#isLoading = false;
      this.closeButton.classList.remove(CLASSNAMES.HIDE);
      if (this.isHomePage || (!this.isHomePage && !this.activeMenuIndex === 1)) {
        this.closeButton.focus();
      } else {
        this.activeMenu?.focus();
        trapFocus(this.listWrapper);
      }
    }
  };

  /** Set transition duration For other side panels with 375px they 0.2s duration */
  /*setTransitionDuration = () => {
    const MAX_DURATION = 0.5;
    const factor = 0.2 / 375;
    let distance = Math.min(MAX_WIDTH, window.innerWidth);
    if (!this.isHomePage) {
      const duration = Math.min(MAX_DURATION, distance * factor);
      this.el.style.transitionDuration = `${duration}s`;
    } else {
      distance -= 200;
      const duration = Math.min(MAX_DURATION, distance * factor);
      this.subPanelContainer.style.transitionDuration = `${duration}s`;
    }
  };*/

  /** Triggers when we fetch content of side panel via toggler */
  onLoading = () => {
    this.#isLoading = true;
    this.el.classList.add(CLASSNAMES.ACTIVE);
    this.closeButton.classList.remove(CLASSNAMES.HIDE);
  };
}

componentRegistry.define('js-SideNavPanel', SideNavPanel);
