import componentRegistry from '../registry.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';
import getSatellite from '../../helpers/vendor/getSatellite.js';
import isOneTrustAlertBoxClosedAndValid from '../../helpers/vendor/isOneTrustAlertBoxClosedAndValid.js';

const cache = {};

const fetchWithCache = (href) => {
  if (cache[href] == null) {
    cache[href] = rejectNonOkResponse(
      fetch(href, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    ).then((r) => r.json());
  }
  return cache[href];
};

const getContent = async (href, names) => {
  const json = await fetchWithCache(href);

  for (const name of names) {
    if (json[name]) return json[name];
  }
  return '';
};

export default class LazyHtmlCacheable {
  constructor({ el }) {
    this.el = el;

    Promise.all([isOneTrustAlertBoxClosedAndValid(), this.isEnabled()]).then(([, enabled]) => {
      if(enabled) this.load()
    });
  }

  async isEnabled() {
    const satellite = await getSatellite();
    return satellite.getVar("Kamino SSR - Active");
  }

  async load() {
    const { href, format } = this.el.dataset;
    const formats = format.split(',').map((el) => el.trim());
    const content = await getContent(href, formats);
    this.el.innerHTML = content;
  }
}

componentRegistry.define('js-lazy-html-cacheable', LazyHtmlCacheable);