/**
 * @param {HTMLElement} el The element that will delegate event for it's child with the specific selector
 * @param {string} type Type of event
 * @param {string} selector The target element by (css selector)
 * @param {Function} listener Function to execute after event fired
 */
export default (el, type, selector, listener) => {
  el.addEventListener(type, (event) => {
    if (event.target.closest(selector)) listener(event);
  });
};
