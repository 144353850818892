import rejectNonOkResponse from '../rejectNonOkResponse.js';
import { cartUpdateURL } from '../../../configuration/Configuration.js';

/** @typedef {import('../../../../components/product-buy-form/typedefs.js').CartProduct} CartProduct */

/**
 * Update products from cart
 *
 * @param {CartProduct[]} products Products to update
 */
export default async (products) =>
  rejectNonOkResponse(
    fetch(cartUpdateURL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify(
        products.map(({ prid, catalog, offer, relatedPrid, quantity }) => ({
          productID: prid,
          referential: catalog,
          offer,
          masterProductID: relatedPrid,
          quantity,
        }))
      ),
    })
  ).then(() => true);
