import componentRegistry from '../registry.js';

const fakeElement = {
  getAttribute() {
    return '';
  },
};
const WINDOW_NAME = 'FnacShare';

/**
 * @param {string} url
 * @param {object} options
 */
function open(url, options) {
  window.open(
    url,
    WINDOW_NAME,
    Object.entries(options)
      .map(([key, value]) => `${key}=${value}`)
      .join(', ')
  );
}

// FIXME hey use navigator.share() when supported, instead of custom ones
export default class ShareButton {
  constructor({ el }) {
    this.el = el;
    this.el.addEventListener('click', this.#handleClick.bind(this));
  }

  #handleClick(event) {
    if (!event.target.closest('button')) {
      event.preventDefault();
      console.assert(false, `The target is a <button type="button">`);
    }

    const {
      type,
      url = Object.assign(new URL(document.location), { hash: '' }).href,
      text = (document.querySelector('meta[property="og:title"]') || fakeElement).getAttribute('content'),
      title = document.title,
    } = this.el.dataset;

    switch (type) {
      case 'facebook': {
        const sharerURL = new URL('https://www.facebook.com/sharer/sharer.php');
        sharerURL.searchParams.set('u', url);
        open(sharerURL.href, { width: 500, height: 500 });
        break;
      }
      case 'whatsapp': {
        const sharerURL = new URL('whatsapp://send');
        sharerURL.searchParams.set('text', `${text} #fnac`);
        sharerURL.searchParams.set('url', url);
        open(sharerURL.href, { width: 500, height: 500 });
        break;
      }
      case 'twitter': {
        const sharerURL = new URL('https://twitter.com/share');
        sharerURL.searchParams.set('text', `${text || title} #fnac`);
        sharerURL.searchParams.set('url', url);
        open(sharerURL.href, { width: 500, height: 255 });
        break;
      }
      case 'email': {
        const sharerURL = new URL('mailto:');
        sharerURL.searchParams.set('body', text.replace('{0}', url));
        sharerURL.searchParams.set('subject', title);
        // "all spaces SHOULD be encoded as %20"
        // https://tools.ietf.org/html/rfc6068#section-5
        // "Please note that '+' characters are frequently used as part of an email address to indicate a subaddress, as for example in <bill+ietf@example.org>"
        // We don't have the email "to" nor "cc" querystring, that means we can replace all plus char to its final version
        document.location = sharerURL.href.replace(/\+/g, '%20');
        break;
      }
    }
  }
}

componentRegistry.define('js-share', ShareButton);
