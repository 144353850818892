import escapeAttribute from './escape-attribute.js';
import escape from './escape.js';
/**
 * Note: `xlink:href` still required (vs w/o namespace `xlink`: `href`) for browsers like Safari < 12.1 or Chrome < 50
 *
 * @param {string} src Icon source
 * @param {string} arg1 Additional class name(s)
 * @param {string} arg1.className Additional class name(s)
 * @param {string} arg1.title Used for aria-label
 * @returns {string} Icon HTML
 */
export default function (src, { className = null, title = null } = {}) {
  const finalClassName = ['f-icon', ...(className ?? '').split(' ')].join(' ');
  const extraAttributes = title ? ' aria-hidden="true" focusable="false"' : '';
  return `<svg role="img" height="20" class="${escapeAttribute(finalClassName)}"${extraAttributes}>
    ${title ? `<title>${escape(title)}</title>` : ''}
    <use xlink:href="${escapeAttribute(src)}" />
  </svg>`;
}
