const escapeMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
};

/**
 * @param {string} value
 * @returns {string} The given value with some char transformed to HTML entities
 * @see https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#output-encoding-for-html-contexts
 * @see https://www.w3.org/International/questions/qa-escapes#use
 */
export default function (value) {
  return String(value).replace(/&|<|>|"|'/g, (m) => escapeMap[m]);
}
