/**
 * @param {number} duration
 * @param {boolean} showHours
 * @returns {string}
 */
export default (duration, showHours = false) => {
  const hours = Math.floor((duration % 86400) / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);
  const durationArr = [minutes, seconds];
  showHours && hours > 0 ? durationArr.unshift(hours) : null;
  return durationArr.map((value) => value.toString().padStart(2, '0')).join(':');
};
