import { cartViewURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
export default async () => {
  const response = await rejectNonOkResponse(
    fetch(cartViewURL, {
      mode: 'cors',
      credentials: 'include',
    })
  );

  return response.text();
};
