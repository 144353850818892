import postTrackingK from './tracking.js';
import { isMobile } from '../../configuration/Configuration.js';

// Carousels
import NCarousel from '../../components/n-carousel/index.js';
import CarouselScrollSnap from '../../scripts/common/components/CarouselScrollSnap.js';

export default class Kamino {
  links;
  buyButtons;
  carousel;
  trackingImp;
  trackingView;
  trackingLoad;
  trackingClick;
  trackingVideo;
  trackingPview;
  thirdPartyTracking;

  constructor({ el, autoInitTracking = true }) {
    this.el = el;
    this.trackingImp = el.dataset.trackingImp;
    this.trackingView = el.dataset.trackingView;
    this.trackingLoad = el.dataset.trackingLoad;
    this.trackingClick = el.dataset.trackingClick;
    this.trackingVideo = el.dataset.trackingVideo;
    this.trackingPview = el.dataset.trackingPview;
    this.thirdPartyTracking = el.dataset.thirdPartyTracking;

    this.postTracking(this.trackingImp);
    if (autoInitTracking) this.initTracking();

    this.carousel = this.el.querySelector('.f-nCarousel');

    if (this.carousel) {
      this.#initCarousel();
    }

    var trackingViewOccurence = [false, false, false];
    const visibleTimeRequired = 2; // seconds
    this.intersectionObserver = new IntersectionObserver(
      ([{ isIntersecting }]) => {
        let visibleTime = 0; // track the amount of time the element is visible
        let visibilityTimer;
        if (isIntersecting) {
          if (trackingViewOccurence[visibleTime] === false) {
            this.postTracking(this.trackingView, { st: visibleTime });
            trackingViewOccurence[visibleTime] = true;
          }
          // Start tracking visible time
          visibilityTimer = setInterval(() => {
            visibleTime += 1;
            if (trackingViewOccurence[visibleTime] === false) this.postTracking(this.trackingPview, { st: visibleTime }); //Post tracking every seconds
            trackingViewOccurence[visibleTime] = true;
            if (visibleTime >= visibleTimeRequired) {
              // Clear the interval after the required time is met
              clearInterval(visibilityTimer);
              //remove intersectionObserver once all the visibility trackers has been called
              this.intersectionObserver.disconnect();
            }
          }, 1000);
        } else {
          clearInterval(visibilityTimer);
          visibleTime = 0;
        }
      },
      { threshold: 0.5 }
    );
    this.intersectionObserver.observe(el);
  }

  initTracking() {
    this.links = this.el.querySelectorAll(
      '.miniFA__link, .miniFA__subtitle a, .miniFA__rating, .f-kamino-visual-link, .f-kamino-title, .js-Search-hashLink'
    );

    const getArticleInfo = (elem) => {
      const form = elem.closest('form');

      return {
        catalog: form.dataset.catalog || form.querySelector('input')?.dataset.catalog,
        prid: form.querySelector('input').value.substring(0, 8),
      };
    };

    this.links?.forEach((link) => {
      const { prid, catalog } = getArticleInfo(link);
      link.addEventListener('click', this.#onClickLink.bind(this, catalog + '-' + prid));
    });

    this.buyButtons = this.el.querySelectorAll('.js-ProductBuy-add');

    this.buyButtons?.forEach((button) => {
      const { prid, catalog } = getArticleInfo(button);
      button.closest('.js-ProductBuy').addEventListener('submit', this.#onAddToCart.bind(this, catalog + '-' + prid));
    });

    this.el.addEventListener('infinite-carousel:prev', this.#onClickLeftArrow.bind(this));
    this.el.addEventListener('infinite-carousel:next', this.#onClickRightArrow.bind(this));
  }

  #initCarousel() {
    const isBillboard = ['billboard', 'billboard-display'].indexOf(this.el.dataset.format) >= 0;

    if (!isBillboard && isMobile) {
      new CarouselScrollSnap({ el: this.carousel });
    } else {
      new NCarousel({
        el: this.carousel,
        options: {
          nativeScroll: false,
        },
      });
    }

    const leftArrow = this.el.querySelector('.f-nCarousel__arrow--prev:not(.disabled)');
    const rightArrow = this.el.querySelector('.f-nCarousel__arrow--next:not(.disabled)');

    leftArrow?.addEventListener('click', this.#onClickLeftArrow.bind(this));
    rightArrow?.addEventListener('click', this.#onClickRightArrow.bind(this));

    this.el.addEventListener('infinite-carousel:prev', this.#onClickLeftArrow.bind(this));
    this.el.addEventListener('infinite-carousel:next', this.#onClickRightArrow.bind(this));
  }

  #onClickLeftArrow() {
    // fire on click on left active arrow from caroussel
    this.postTracking(this.trackingClick, { click_zone: 'format', act: 'nav-prev' });
  }

  #onClickRightArrow() {
    // fire on click on right active arrow from caroussel
    this.postTracking(this.trackingClick, { click_zone: 'format', act: 'nav-next' });
  }

  #onClickLink(product_id) {
    // fire on click product info tracking event
    this.postTracking(this.trackingClick, { click_zone: 'product', act: 'product-page', product_id });
  }

  #onAddToCart(product_id) {
    // fire on click add to cart tracking event
    this.postTracking(this.trackingClick, { click_zone: 'product', act: 'add-to-cart', product_id });
  }

  postTracking = (urlTracking, jsonQueryParams) => postTrackingK(urlTracking, jsonQueryParams, this.thirdPartyTracking);
}
