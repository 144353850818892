import translation from '../../helpers/translation/translation.js';
import {
  currency,
  currencyHTML,
  isFnacPro,
  isMobile,
  isMobileApplication,
  isUserAuthenticated,
  isUserIdentified,
  isValidAdherent,
  lang,
  locale,
  region,
} from '../Configuration.js';
import replacePriceToken from '../../helpers/string/replacePriceToken.js';
import obfuscate from '../../helpers/string/obfuscate.js';
import icon from '../../helpers/html/icon.js';

const suffixes = new Set(
  Object.entries({
    '.mobile': isMobile,
  })
    .filter(([, enabled]) => enabled)
    .map(([suffix]) => suffix)
);

/** @typedef {import('liquidjs').Liquid} Liquid */
/** @typedef {import('liquidjs/dist/fs/fs.d.ts').FS} LiquidFS */

/** @this {Liquid} */
export default function () {
  // Register filters
  // See src/FnacDirect.Technical.Framework.Web/Mvc/Liquid/LiquidCustomFilter.cs
  // Use snake case: ends_with not endsWith
  for (const [name, filter] of new Map([
    ['translation', translation],
    ['t', translation],
    ['replace_price_token', replacePriceToken],
    ['url_obfuscate', obfuscate],
    // ['icon_tag', (src, ...entries) => icon(src, Object.fromEntries(entries))],// backend don't support named params. LiquidJS gives named params as {[name1, value1], [name2, value2], ...}
    ['icon_tag', (src, className, title) => icon(src, { className, title })],
  ])) {
    this.registerFilter(name, filter);
  }

  addSuffixesSupport(this.options.fs, suffixes);

  // Register predefined vars
  Object.assign(this.options.globals, {
    isMobile,
    isMobileApplication,
    isUserIdentified,
    isUserAuthenticated,
    isValidAdherent,
    isFnacPro,
    locale,
    lang,
    region,
    //environment,
    currency,
    currencyHTML,
  });
}

/**
 * Override file system resolver to support suffixes Works only with synchrone file system
 *
 * @param {LiquidFS} fs Liquid filesystem
 * @param {Set<string>} suffixes Suffixes to support
 * @returns {LiquidFS}
 * @example
 *   addSuffixesSupport(fs, new Set(['.mobile']));
 */
function addSuffixesSupport(fs, suffixes) {
  if (!suffixes.size) {
    return fs;
  }

  const { resolve } = fs;
  fs.resolve = function (root, filepath, ext) {
    for (const suffix of suffixes) {
      const filepathWithSuffix = filepath.endsWith(ext)
        ? filepath.slice(0, -ext.length) + suffix + ext
        : filepath + suffix;
      const resolved = resolve.call(this, root, filepathWithSuffix, ext);
      if (this.existsSync(resolved)) {
        return resolved;
      }
    }

    return resolve.call(this, root, filepath, ext);
  };

  return fs;
}
