import getCartProductCount from '../../helpers/counter/getCartProductCount.js';
import translation from '../../helpers/translation/translation.js';
import componentRegistry from '../registry.js';

export default class CartItemsCounter {
  el = null;
  #value = 0;

  constructor({ el }) {
    this.el = el;

    window.addEventListener('cartchange', this.#update); // TODO use this event to provide the content of the cart: event.detail.items
    setInterval(this.#update, 1500); // auto update
    this.#render();
    this.#update();
  }

  #update = () => {
    const value = getCartProductCount();
    if (this.#value !== value) {
      this.#value = value;
      this.#render();
    }
  };

  /**
   * Propagates changes to the dom.
   *
   * @returns {void}
   */
  #render() {
    this.el.textContent =
      this.#value < 100 ? this.#value : translation('core.common.header.headerbasket.mybasket.maxproduct');
    this.el.classList.toggle('isEmpty', this.#value === 0);
  }
}

componentRegistry.define('js-CartCount', CartItemsCounter);
