import IntersectionObserver from '../../scripts/polyfills/web.naive-intersectionobserver.js';

/**
 * @param {Element} target
 * @param {IntersectionObserverInit} options
 */
export default function (target, options) {
  return new Promise((resolve) => {
    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      if (isIntersecting) {
        // Only observe once
        observer.disconnect();
        resolve();
      }
    });
    observer.observe(target, options);
  });
}
