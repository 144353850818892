import renderAutocompleteItem from './autocomplete-item.liquid';
import DebounceEventListener from '../../helpers/event/debounce-listener.js';
import componentRegistry from '../registry.js';
import addDelegateEventListener from '../../helpers/event/add-delegate-event-listener.js';
import isFromValidationKey from '../../helpers/event/is-from-validation-key.js';
import deleteHistorySuggestion from '../../scripts/api/autocomplete/deleteHistorySuggestion.js';
import addHistorySuggestion from '../../scripts/api/autocomplete/addHistorySuggestion.js';
import getSuggestions from '../../scripts/api/autocomplete/getSuggestions.js';
import './header-search.less';
import './header-search-mobile.less'; //remove after adding the mobile header on Fnacpro

const ENTRIES_LIMIT = 7;
const MIN_HISTORY_ENTRIES = 2;
const MIN_CHARS_TO_SHOW_HISTORY = 3;
const TRACKING_HISTORY_KEY = 'hist';
const CLASSNAMES = {
  HEADER: 'js-HeaderSearch',
  AUTOCOMPLETE: 'js-autocomplete',
  AUTOCOMPLETE_ITEM: 'js-autocomplete-item',
  AUTOCOMPLETE_ITEM_TITLE: 'js-autocomplete-item-title',
  AUTOCOMPLETE_HISTORY_REMOVE: 'js-autocomplete-history-remove',
};

export default class HeaderSearch {
  #previousValue = '';
  #activeItem = null;
  #form;
  #searchFields;
  #searchInput;
  #searchEmpty;
  #list;

  constructor({ el }) {
    this.el = el;

    this.#form = this.el.querySelector('#QuickSearchForm');
    this.#searchFields = this.el.querySelector(`.${CLASSNAMES.HEADER}-fields`);
    this.#searchInput = this.el.querySelector(`.${CLASSNAMES.HEADER}-input`);
    this.#searchEmpty = this.el.querySelector(`.${CLASSNAMES.HEADER}-inputClear`);
    this.#previousValue = this.#searchInput.value;

    this.#list = this.el.querySelector('.js-autocomplete-list');

    this.#searchInput.addEventListener('input', new DebounceEventListener(() => this.#loadAutocompleteList(), 250));
    this.#searchInput.addEventListener('focus', this.#onInputFocus.bind(this));

    this.#searchEmpty.addEventListener('click', this.#clearSearchBar.bind(this));
    this.#searchInput.addEventListener('keyup', this.#toggleSearchEmpty.bind(this));
    this.#searchInput.addEventListener('keydown', this.#navigateItems.bind(this));
    this.#searchInput.addEventListener('blur', this.#hideAutocomplete, true);
    this.#form.addEventListener('submit', this.#onSubmitForm.bind(this));
    // remove history events
    addDelegateEventListener(
      this.#list,
      'keydown',
      `.${CLASSNAMES.AUTOCOMPLETE_HISTORY_REMOVE}`,
      (event) => isFromValidationKey(event) && this.#onRemoveHistory(event)
    );
    addDelegateEventListener(
      this.#list,
      'mousedown',
      `.${CLASSNAMES.AUTOCOMPLETE_HISTORY_REMOVE}`,
      this.#onRemoveHistory
    );
    // validate suggestion
    addDelegateEventListener(
      this.#list,
      'keydown',
      `.${CLASSNAMES.AUTOCOMPLETE_ITEM_TITLE}`,
      (event) => isFromValidationKey(event) && this.#onItemClick(event)
    );
    addDelegateEventListener(this.#list, 'mousedown', `.${CLASSNAMES.AUTOCOMPLETE_ITEM_TITLE}`, this.#onItemClick);

    this.#toggleSearchEmpty();
  }

  set #searchTerm(value) {
    this.#searchInput.value = value;
  }

  get #searchTerm() {
    return this.#searchInput.value;
  }

  /**
   * Functions
   *
   * @param {FocusEvent} e
   */
  #hideAutocomplete = (e) => {
    if (!this.#searchFields.contains(e.relatedTarget)) {
      this.#list.classList.remove('active');
      this.#list.replaceChildren();
    }
  };

  #toggleSearchEmpty() {
    this.#searchEmpty.classList.toggle('on', this.#searchTerm.length);
  }

  #navigateItems(event) {
    // 1: for IE, see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
    switch (event.key) {
      case 'ArrowUp':
      case 'Up': // 1
        this.#gotToItem('up');
        event.preventDefault();
        return;
      case 'ArrowDown':
      case 'Down': // 1
        this.#gotToItem('down');
        return;
      case 'Enter':
        if (!this.#activeItem) return;
        this.#activeItem.classList.add('active');
        this.#onItemClick({ target: this.#activeItem });
        return;
    }
  }

  #clearSearchBar() {
    this.#searchEmpty.classList.remove('on');
    this.#searchTerm = '';
    this.#searchInput.focus();
  }

  #onInputFocus() {
    if (!this.#searchTerm) {
      this.#renderInitialHistory();
    }
  }

  #autoCompleteEntries = null;
  async #loadAutocompleteList() {
    if (this.#searchTerm === this.#previousValue) {
      return;
    }

    const term = (this.#previousValue = this.#searchTerm);

    const promise = (this.#autoCompleteEntries = getSuggestions(term, {
      historyCount: term.length >= MIN_CHARS_TO_SHOW_HISTORY ? MIN_HISTORY_ENTRIES : 0,
      count: ENTRIES_LIMIT,
    }));

    const entries = await promise;
    // loadAutocompleteList could be called while the promise is waiting, in that case, discard the result of the former
    if (promise !== this.#autoCompleteEntries) {
      return;
    }

    this.#displayData(entries);
  }

  #displayData(data) {
    this.#activeItem = null;
    this.#list.classList.toggle('active', data.length);

    this.#list.replaceChildren();

    if (data.length) {
      const content = data.map((singleData, i) => renderAutocompleteItem({ ...singleData, index: i })).join('');
      this.#list.insertAdjacentHTML('beforeend', content);
    }
  }

  #onItemClick = ({ target }) => {
    const currentTarget = target.closest(`.${CLASSNAMES.AUTOCOMPLETE_ITEM}`); // for event delegation
    if (!currentTarget) {
      return;
    }

    const { value, type } = currentTarget.dataset;

    // store the previous value to avoid a new request if the values are equal
    this.#previousValue = value;

    // replace input value by clicked text
    this.#searchTerm = value;

    // change value of hidden field for tracking
    this.#form.querySelector('.js-searchTracking').value = type === 'history' ? TRACKING_HISTORY_KEY : 1;

    // PCD-1477
    this.#list.classList.remove('active');

    addHistorySuggestion(value);

    // submit form to go to search page
    this.#form.submit();
  };

  #onRemoveHistory = (event) => {
    const currentTarget = event.target.closest(`.${CLASSNAMES.AUTOCOMPLETE_ITEM}`);
    const { value } = currentTarget.dataset;
    event.preventDefault();
    deleteHistorySuggestion(value);

    currentTarget.remove();
    if (!this.#list.children.length) {
      this.#list.classList.remove('active');
    }
    if (currentTarget === this.#activeItem) {
      this.#activeItem = null;
    }
    // Put the focus to the end of the search field
    const inputLength = this.#searchInput.value.length;
    this.#searchInput.setSelectionRange(inputLength, inputLength);
    this.#searchInput.focus();
  };

  #gotToItem(direction) {
    const items = [...this.#list.querySelectorAll(`.${CLASSNAMES.AUTOCOMPLETE_ITEM}`)];
    const nextItem =
      direction === 'down'
        ? this.#activeItem?.nextElementSibling ?? items[0]
        : this.#activeItem?.previousElementSibling ?? items[items.length - 1];
    this.#activeItem?.classList.remove('hover', 'active');

    if (nextItem) {
      nextItem.classList.add('hover');
      this.#activeItem = nextItem;
      // PCD-1476
      this.#searchTerm = nextItem.dataset.value;
    }
  }

  #onSubmitForm() {
    const value = (this.#searchTerm = this.#searchTerm.trim());
    if (value) {
      addHistorySuggestion(value);
    }
  }

  async #renderInitialHistory() {
    const data = await getSuggestions('', { historyCount: ENTRIES_LIMIT, count: ENTRIES_LIMIT });
    this.#displayData(data);
  }
}

componentRegistry.define('js-Header-search', HeaderSearch);
