/**
 * Function to check if the user clicked on escape key
 *
 * @param {KeyboardEvent} event
 * @param {string} event.key
 * @returns {boolean}
 */
export default ({ key }) => {
  return key === 'Escape' || key === 'Esc';
};
