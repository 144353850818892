import { DEFAULT_OFFER } from '../product/product.js';

export default (element) => {
  // Enrich with dataset data (used for the datalayer)
  const {
    prid = null, // should be required
    catalog = '1',
    offer = DEFAULT_OFFER,
    availability = '0',
    nature = 'physic',
    eqnatures: eqNatures = '',
    sellertypes = '',
    condition = null,
    fulfillment = null,
    sellerid: sellerId = null,
    sellerlocation: sellerLocation = null,
    sellername: sellerName = null,
    sellertype: sellerType = null,
  } = element?.dataset ?? {};

  return {
    prid,
    catalog,
    offer,
    availability,
    nature,
    eqNatures: parseTokenList(eqNatures),
    sellerTypes: parseTokenList(sellertypes),
    condition,
    fulfillment,
    sellerId,
    sellerLocation,
    sellerName,
    sellerType,
  };
};

/** @param {string} value */
// FIXME move to helpers
function parseTokenList(value) {
  value = value?.trim();
  if (value === '') return [];
  return value.split(/\s+/);
}
