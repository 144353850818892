// See also https://www.npmjs.com/package/focus-trap

import getTabbableElements from './getTabbableElements.js';

// FIXME create releaseFocus()
export default (element) => element.addEventListener('keydown', listener);

// Declare the listener outside trapFocus() (vs inner function) to always use the same listener, or call trapFocus() multiple times will call the listener multiples times too
/** @param {Event} event */
function listener(event) {
  const element = event.currentTarget;
  const tabbableElements = getTabbableElements(element);
  const [firstTabbable] = tabbableElements;
  const [lastTabbable] = tabbableElements.slice(-1);

  const isTabPressed = event.key === 'Tab';
  if (!isTabPressed) {
    return;
  }

  const { activeElement } = element.ownerDocument;
  if (event.shiftKey) {
    if (activeElement === firstTabbable) {
      lastTabbable.focus();
      event.preventDefault();
    }
  } else {
    if (activeElement === lastTabbable) {
      firstTabbable.focus();
      event.preventDefault();
    }
  }
}
