const errorInstance = Object.freeze(Object.create(null)); // fake instance used when the component constructor throw an error
const registry = new Map();
const collections = new Map();
const upgradedElements = new WeakMap();
const upgradeConflicts = new WeakMap();

// FIXME support lifecycle callbacks https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#Using_the_lifecycle_callbacks

// https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry
export default {
  define(name, constructor, options = {}) {
    if (registry.has(name) || [...registry.values()].includes(constructor)) {
      throw Object.assign(
        new Error('The registry already contains an entry with the same name or the same constructor'),
        { name: 'NotSupportedError' }
      );
    }

    if (!name.startsWith('js-') || name.length < 4) {
      throw new SyntaxError('The provided name is not a valid component name');
    }

    if (typeof constructor !== 'function') {
      throw new TypeError('The referenced constructor is not a constructor.');
    }

    registry.set(name, constructor);
    const collection = document.getElementsByClassName(name);
    collections.set(name, collection);
    requestIdleCallback(() => updateCollection(collection, name), { timeout: 100 }); // defer initialization (don't block the main tread)
  },

  get(name) {
    return registry.get(name);
  },

  whenDefined(name) {
    throw new Error('Not implemented');
  },
};

/**
 * @param {Iterable<Element>} collection
 * @param {string} name
 */
function updateCollection(collection, name) {
  for (const element of collection) {
    const constructor = registry.get(name);
    const upgradedElementInstance = upgradedElements.get(element);

    if (upgradedElementInstance === undefined) {
      element.setAttribute('component:' + name, ''); // for debug purpose only
      let instance;
      try {
        instance = new constructor({ el: element });
      } catch (error) {
        reportError(error);
        instance = errorInstance; // replace the can't-be-created instance with a placeholder
      }
      upgradedElements.set(element, instance);
      continue;
    }

    if (
      // The construction of that element thrown an error
      upgradedElementInstance === errorInstance ||
      // Same constructor (no conflict), already instantiated, nothing to do
      upgradedElementInstance instanceof constructor
    ) {
      continue;
    }

    let conflict = upgradeConflicts.get(element);
    // has conflict with this constructor, but already known (to log the error only once)
    if (conflict && conflict.has(constructor)) {
      continue;
    }

    if (conflict === undefined) {
      conflict = new Set();
    }

    upgradeConflicts.set(element, conflict.add(constructor));
    reportError(new TypeError(`Components can't reuse same element`));
  }
}

new MutationObserver(() => {
  for (const [name, collection] of collections) {
    updateCollection(collection, name);
  }
}).observe(document.documentElement, {
  childList: true,
  subtree: true,
  attributes: true,
});
