// TODO use something like https://github.com/grassator/webpack-extract-translation-keys or https://webpack.js.org/configuration/externals/#externalstype
const config = {};
for (const configElement of document.getElementsByClassName('js-configuration')) {
  try {
    Object.assign(config, JSON.parse(configElement.text));
  } catch (error) {
    reportError(error);
  }
}

export const {
  serviceWorkerScope,
  serviceWorkerURL,
  isUserIdentified, // it's allowed to read information about the user (his/her name is displayed in the header)
  isUserAuthenticated, // it's allowed to read and write user information (place an order, cancel order, add to wishlist, etc.)
  isValidAdherent, // it's used to show some prices
  cookiesHostname,
  cookiesPath,
  // See also isTouch
  // @deprecated Use IS_MOBILE instead
  isMobile,
  isMobileApplication,
  isFnacPro,
  locale,
  lang,
  region,
  currency,
  currencyHTML,
  cartViewURL,
  cartURL,
  cartAddURL,
  cartDeleteURL,
  cartUpdateURL,
  cartOneClickURL,
  searchAutoCompleteURL,
  setFavoriteStoreURL,
  getAlertsURL,
  addAlertURL,
  deleteAlertURL,
  searchStoreURL,
  compareItemLimit,
  isProduction,
  countryGeocoordinates,
  navigationHistoryURL,
  getProductsURL,

  // Compare
  comparePreviewURL,
  compareURL,
  cartPreviewCrossSellURL,
} = config;

export const cookieRules = config.cookieRules.map((pattern, i) => new RegExp(pattern));

export const headerTabTemplateURL = templateURL(config.headerTabTemplateURL);

export const productReviewFeedbackTemplateURL = templateURL(config.productReviewFeedbackTemplateURL);

export const productReviewTemplateURL = templateURL(config.productReviewTemplateURL);
export const productTopReviewsTemplateURL = templateURL(config.productTopReviewsTemplateURL);

export const getOffersListTemplateURL = templateURL(config.getOffersListTemplateURL);

// Template URLs (parameterizable URLs):
export const fnacSpectacleSearchTemplateURL = templateURL(config.fnacSpectacleSearchTemplateURL);
export const geocodingTemplateURL = templateURL(config.geocodingTemplateURL);

// Configurator
export const configuratorGetOptionsURL = templateURL(config.configuratorGetOptionsURL);
export const configuratorGetResultURL = templateURL(config.configuratorGetResultURL);

// Wishlist
export const { getWishlistsURL, createWishlistURL } = config;
export const addWishlistItemTemplateURL = templateURL(config.addWishlistItemTemplateURL);
export const deleteWishlistItemTemplateURL = templateURL(config.deleteWishlistItemTemplateURL);
export const myAccountWishlistTemplateURL = templateURL(config.myAccountWishlistTemplateURL);

// Reviews
export const { getReviewsURL } = config;
export const writeReviewTemplateURL = templateURL(config.writeReviewTemplateURL);

// Retrait Magasin
export const getNearestPickupStoresTemplateURL = templateURL(config.getNearestPickupStoresTemplateURL);
export const getPickupStoresPopinTemplateURL = templateURL(config.getPickupStoresPopinTemplateURL);

export const getHiddenInfoSellerTemplateURL = templateURL(config.getHiddenInfoSellerTemplateURL);

// Popin dle
export const getShippingOptionURL = templateURL(config.getShippingOptionURL);
export const getListStoreURL = templateURL(config.getListStoreURL);

// Store Locator (mobile only?)
export const getNearestStoresWithAvailabilityTemplateURL = config.getNearestStoresWithAvailabilityTemplateURL
  ? templateURL(config.getNearestStoresWithAvailabilityTemplateURL)
  : () => '';
export const getNearestStoresTemplateURL = config.getNearestStoresTemplateURL
  ? templateURL(config.getNearestStoresTemplateURL)
  : () => '';

export const trackingKaminoURL = config.trackingKaminoURL;

export const signInTemplateURL = templateURL(config.signInTemplateURL);
export const signInURL = () => signInTemplateURL({ currentUrl: location.href }); // TODO remove this

export const mobileMenuEntryTemplateURL = templateURL(config.mobileMenuEntryTemplateURL);

// Installment payment
export const installmentPaymentTemplateURL = templateURL(config.installmentPaymentTemplateURL);

// is primary pointer having limited accuracy, or the primary input mechanism can hover
// see also isMobile
export const isTouch = matchMedia('(pointer: coarse), (hover: none)').matches;

export const { kameleoonActive } = config;

// Mega menu
export const { megaMenuURL } = config;

export const productPopinTemplateURL = templateURL(config.productPopinTemplateURL);

// Epub/PDF reader
export const readerTemplateURL = templateURL(config.readerTemplateURL);

// Product carrousel (SMILE)
export const { productListURL } = config;

// Search
export const searchTemplateURL = templateURL(config.searchTemplateURL);
/**
 * @param {URLSearchParams | string | Record<string, string | string[]> | Iterable<[string, string]>} params
 * @returns {string}
 */
export const searchPageTemplateURL = (params = {}) => {
  const url = new URL(config.searchPageURL, document.documentURI);
  url.search = new URLSearchParams([...url.searchParams, ...new URLSearchParams(params)]).toString(); // merge params
  return url.href;
};

/**
 * Create a route template function Try to support the same routes syntax that ASP.Net use
 *
 * TODO: replace with RFC 6570 URI Template, see package url-template
 *
 * @param {string} url
 * @param {Record<string, string>} [defaultData]
 * @returns {function(Record<string, string>):string}
 * @see https://github.com/microsoft/referencesource/blob/5697c29004a34d80acdaf5742d7e699022c64ecd/System.Web/Routing/RouteParser.cs#L93-L154
 * @see https://docs.microsoft.com/en-us/aspnet/core/fundamentals/routing?view=aspnetcore-2.2 ASP.Net routing
 * @see https://github.com/aspnet/AspNetCore/blob/master/src/Http/Routing/src/Patterns/RoutePatternFactory.cs
 * @see https://github.com/aspnet/AspNetCore/blob/master/src/Http/Routing/src/InlineRouteParameterParser.cs
 * @see https://github.com/aspnet/AspNetCore/blob/master/src/Http/Routing/src/Patterns/RoutePatternParser.cs
 * @see https://github.com/aspnet/AspNetCore/blob/master/src/Http/Routing/src/Patterns/RouteParameterParser.cs
 * @see https://github.com/aspnet/AspNetCore/blob/master/src/Mvc/Mvc.Core/src/Routing/UrlHelper.cs Action, RouteUrl and GetVirtualPathData
 * @example
 *   const myTemplateURL = templateURL('{controller=Home}/{action=Index}/{id:int?}');
 *   myTemplateURL({ id: 10 }); //> "Home/Index/10"
 *   myTemplateURL(); //> "Home/Index"
 */
function templateURL(url, defaultData = {}) {
  const [
    ,
    // ignored
    base = '/',
    route = '',
  ] = /^((?:(?:https?:)?\/\/[^/]+)?\/)(.*)$/.exec(url || '') || [];

  // - "=value": will use "value" as default
  // - ":transformer": will use "transformer" to match
  // - "?": default value is UrlParameter.Optional
  const regexp = /^{(\*\*?)?([^{].*?)(:.*?)?(?:=(.*?)|(\?))?}$/;

  const routeSegments = route.split('/').map((segment) => {
    if (segment === '') {
      throw new TypeError('Invalid route segment');
    }

    // Parse all parameter parts
    return segment
      .split(/({[^{].*?})/)
      .filter((part) => part !== '')
      .map((part) => {
        const match = regexp.exec(part);
        if (match) {
          const [, , name, , defaultValue = null] = match;
          const isOptional = match[5] === '?';

          if (isOptional && defaultValue !== null) {
            throw new Error(`Optional param ${name} can't have a default value`);
          }

          return {
            name,
            isOptional,
            defaultValue,
          };
        } else {
          // It's a literal
          return part;
        }
      });
  });

  return (data = {}) => {
    data = Object.assign({}, defaultData, data);

    const finalRouteSegments = routeSegments
      .map((parts) =>
        parts
          .map((part) => {
            if (part instanceof Object) {
              const { name, isOptional, defaultValue } = part;
              const hasValue = data[name] != null; // null or undefined

              if (!hasValue && isOptional) {
                return null; // this segment contains an optional param without any value, we ignore it
              }

              if (!hasValue && defaultValue === null) {
                throw new TypeError(`No value is provided for param ${name}`);
              }

              return hasValue ? encodeURIComponent(data[name]) : defaultValue;
            }

            return part;
          })
          .filter((segment) => segment)
          .join('')
      )
      .filter((segment) => segment);

    return base + finalRouteSegments.join('/');
  };
}

// jawg url services
export const jawgStyleURL = templateURL(config.jawgStyleUrl);

export const compareItemsLSKey = 'compareItems';

export const autocompleteHistoryLSKey = 'search-historic';
