import BasketTabContent from './BasketTabContent.js';
import HeaderTabContent from './HeaderTabContent.js';
import componentRegistry from '../registry.js';
import headerHandleScroll from './header-handle-scroll.js';
import Overlay from '../overlay/index.js';
import './header-panel.less';

export default class HeaderTabs {
  #open = false;
  /** @type {HeaderTabContent} */
  #currentTab = null;
  #tabContent = null;
  #opener = null; // used to update pointer/arrow style
  #loader;
  #overlayPanel;

  constructor({ el }) {
    this.el = el;

    this.#tabContent = document.querySelector('.js-HeaderTab-content');

    this.#loader = new Overlay({
      closeOnClick: true,
      modifier: 'sidepanel',
      spinner: false,
      disableScroll: true,
    });
    this.#loader.addEventListener('close', () => this.#close());

    this.#overlayPanel = new Overlay({
      container: this.#tabContent,
      modifier: 'inside__rounded',
    });

    window.addEventListener('header-tab:close', this.#closeAll);
    this.el.addEventListener('click', this.#handleTabButtonClick);

    headerHandleScroll({ el });
  }

  #close = () => {
    this.#open = false;
    this.#opener = null;

    this.#tabContent.classList.remove('open');

    this.#currentTab?.el.remove();
    this.#currentTab = null;
  };

  #closeAll = () => {
    this.#close();
    this.#loader.hide();
  };

  #handleTabButtonClick = async (e) => {
    const target = e.target.closest('.js-HeaderTab-toggle');

    if (!target) {
      return;
    }

    e.preventDefault();
    this.#loader.show();

    // Switch to another tab
    if (this.#currentTab) {
      this.#currentTab.el.remove();
    }

    const name = target.dataset.headerTab;

    if (this.#currentTab?.name === name) {
      this.#closeAll();
      return;
    }

    const tab = name === 'basket' ? new BasketTabContent() : new HeaderTabContent({ name });

    this.#currentTab = tab;
    this.#tabContent.appendChild(tab.el);
    this.#opener = target;

    // Why use rAF?
    requestAnimationFrame(async () => {
      this.#tabContent.classList.add('open');
      this.#overlayPanel.show();
      await this.#currentTab.load();
      this.#overlayPanel.hide();
      this.#currentTab.el.dispatchEvent(new CustomEvent('show', { bubbles: true }));
    });
  };
}

componentRegistry.define('js-HeaderTabs', HeaderTabs);
