import componentRegistry from '../registry.js';

// TODO use a neutral element (like noscript) placeholder to store the HTML of lazy media (this allow to support anything like img with srcset, picture, video and audio)

/**
 * Lazyload images with js-LazyImage class name (require data-lazyimage or data-lazyimage-background attributes) Use
 * IntersectionObserver if available or use DOM events
 *
 * @see https://medium.com/@paul_irish/requestanimationframe-scheduling-for-nerds-9c57f7438ef4 requestAnimationFrame Scheduling For Nerds – Paul Irish – Medium
 * @see https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
 * @example
 *   <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200'%2F%3E" data-lazyimage="myimage_200x200.jpg" alt="My image" class="js-LazyImage" width="200" height="200">
 * @example
 *   <div data-lazyimage-background></div>;
 */
import IntersectionObserver from '../../scripts/polyfills/web.naive-intersectionobserver.js';

// classes and attributes of lazy elements
const LAZY_CLASS = 'js-LazyImage';
const LAZY_LOADING_CLASS = `${LAZY_CLASS}--loading`;
const LAZY_COMPLETE_CLASS = `${LAZY_CLASS}--complete`;
const LAZY_ERROR_CLASS = `${LAZY_CLASS}--error`;
const LAZY_IMG_ATTR = 'data-lazyimage';
const LAZY_IMG_BG_ATTR = 'data-lazyimage-background';

const INTERSECT_OPTIONS = {
  root: null, // default = document's viewport
  rootMargin: '10% 0px',
  threshold: 0.01,
};

// Same values as FileReader.readyState https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readyState
const EMPTY = 0;
const LOADING = 1;
const DONE = 1;

const lazyImages = new WeakMap();
// TODO use an other IntersectionObserver to observe a larger area than the viewport, for preload. See https://github.com/aFarkas/lazysizes/blob/gh-pages/src/lazysizes-intersection.js#L406-L413
const intersectionObserver = new IntersectionObserver((entries) => {
  for (const { target, isIntersecting } of entries) {
    // Not intersecting
    if (!isIntersecting) {
      continue;
    }

    const lazyImage = lazyImages.get(target);
    lazyImage.load();
  }
}, INTERSECT_OPTIONS);

/**
 *
 */
function waitNextAnimationFrame() {
  return new Promise((resolve) => requestAnimationFrame(resolve));
}

/**
 * @param {string} src
 * @param {HTMLImageElement} [image]
 */
async function loadImage(src, image = new Image()) {
  // TODO use createImageBitmap + canvas? see https://aerotwist.com/blog/the-hack-is-back/

  image.decoding = 'async'; // if supported, request to decode asynchronously
  image.src = src;
  await image.decode();
  return image;
}

// Map of preloaded/preloading background images
const preloadedBackgrounds = new Map();

export default class LazyImage {
  constructor({ el }) {
    this._element = el;
    this._readyState = EMPTY;

    lazyImages.set(el, this);
    intersectionObserver.observe(el);
  }

  /**
   * Load image. Only mutate the given element. No measurement should be made here Decode image should be made
   * asynchronously, and all changes must be made in a RAF, see
   * https://html.spec.whatwg.org/multipage/embedded-content.html#the-img-element:dom-img-decode-4
   */
  async load() {
    if (this._readyState !== EMPTY) {
      return;
    }

    this._readyState = LOADING;

    try {
      // Pure image
      if (this._element.tagName === 'IMG' && this._element.hasAttribute(LAZY_IMG_ATTR)) {
        await loadImage(this._element.getAttribute(LAZY_IMG_ATTR), this._element);
        await waitNextAnimationFrame();
      }
      // Background image
      else if (this._element.hasAttribute(LAZY_IMG_BG_ATTR)) {
        const src = this._element.getAttribute(LAZY_IMG_BG_ATTR);
        // If background are already preloaded
        if (preloadedBackgrounds.has(src)) {
          await preloadedBackgrounds.get(src);
        } else {
          const promise = loadImage(src);
          preloadedBackgrounds.set(src, promise);
          await promise;
        }

        await waitNextAnimationFrame();
        this._element.style.backgroundImage = `url("${src}")`;
      } else {
        await waitNextAnimationFrame();
        // Do nothing if there is no valid image URL
        // Next frame, let at least one frame end with the loading state style for animations/transitions
        throw new TypeError('Lazyload error: Invalid URI');
      }
    } catch (error) {
      this._element.classList.add(LAZY_ERROR_CLASS);
      reportError(error);
    }

    this._element.classList.remove(LAZY_LOADING_CLASS);
    this._element.classList.add(LAZY_COMPLETE_CLASS);

    this._readyState = DONE;
  }
}

componentRegistry.define('js-LazyImage', LazyImage);
