import translation from '../../helpers/translation/translation.js';

export const PLAY_VIDEO_LABEL = translation('front.core.views.product.fa.medias.video.play');
export const PAUSE_VIDEO_LABEL = translation('front.core.views.product.fa.medias.video.pause');
export const ACTIVATE_AUDIO_LABEL = translation('front.core.views.product.fa.medias.audio.activate');
export const DEACTIVATE_AUDIO_LABEL = translation('front.core.views.product.fa.medias.audio.deactivate');
export const VOLUME_AUDIO_LABEL = (value) => translation('front.core.views.product.fa.medias.audio.volume', value);
export const FULLSCREEN_VIDEO_LABEL = translation('front.core.views.product.fa.medias.video.fullscreen');
export const QUIT_FULLSCREEN_VIDEO_LABEL = translation('front.core.views.product.fa.medias.video.quitfullscreen');
export const PLAYLIST_SHOW_LABEL = translation('front.core.views.product.fa.medias.playlist.show');
export const PLAYLIST_HIDE_LABEL = translation('front.core.views.product.fa.medias.playlist.hide');
