// Do not try to use that class, use instead native input[type=number]
// TODO replace with native input[type=number]
import componentRegistry from '../registry.js';
import './product-buy-quantity.less';

export default class ProductBuyQuantity {
  #decreaseButton = null;
  #increaseButton = null;
  #input = null;
  constructor({ el }) {
    this.el = el;

    this.#decreaseButton = this.el.querySelector('.js-BasketList-decrease,.js-ProductBuy-decrease');
    this.#increaseButton = this.el.querySelector('.js-BasketList-increase,.js-ProductBuy-increase');
    this.#input = this.el.querySelector('.js-BasketList-quantity,.js-ProductBuy-changeQuantityInput');

    this.el.addEventListener('click', this.#handleClick.bind(this));
  }

  get value() {
    return this.#input.value;
  }

  get valueAsNumber() {
    return parseInt(this.#input.value, 10); // can use this._quantityInput.valueAsNumber if quantityInput is
  }

  set valueAsNumber(value) {
    this.#input.value = value;
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#max
  get max() {
    return this.#input.max || '';
  }

  get min() {
    return '1';
  }

  #handleClick({ target }) {
    // Wrong target
    if (target !== this.#decreaseButton && target !== this.#increaseButton) {
      return;
    }

    const value = this.valueAsNumber + (target === this.#decreaseButton ? -1 : 1);

    // Out of bounds
    if ((this.min && value < parseInt(this.min, 10)) || (this.max && value > parseInt(this.max, 10))) {
      return;
    }

    this.valueAsNumber = value;
    // Dispatch a fake input event to simulate a native input (dispatching input event)
    this.#input.dispatchEvent(new CustomEvent('input', { bubbles: true })); // TODO use InputEvent instead of CustomEvent
  }
}

componentRegistry.define('js-ProductBuy-changeQuantity', ProductBuyQuantity);
