import componentRegistry from '../registry.js';
import formatDuration from '../../helpers/datetime/format-duration.js';
import './video-player.less';
import clamp from '../../helpers/math/clamp.js';
import isIntersecting from '../../helpers/dom/isIntersecting.js';
import isFromValidationKey from '../../helpers/event/is-from-validation-key.js';
import isFromHorizontalArrowKeys from '../../helpers/event/is-from-horizontal-arrow-keys.js';
import isFromLeftArrowKey from '../../helpers/event/is-from-arrow-left-key.js';
import {
  ACTIVATE_AUDIO_LABEL,
  DEACTIVATE_AUDIO_LABEL,
  FULLSCREEN_VIDEO_LABEL,
  PAUSE_VIDEO_LABEL,
  PLAY_VIDEO_LABEL,
  PLAYLIST_HIDE_LABEL,
  PLAYLIST_SHOW_LABEL,
  QUIT_FULLSCREEN_VIDEO_LABEL,
  VOLUME_AUDIO_LABEL,
} from './video-labels.js';

const CSS_PREFIX = 'videoPlayer';
const CLASSNAMES = {
  PARENT_VIDEO_VISUAL: `f-productVisuals__video`,
  VIDEO: `${CSS_PREFIX}__mediaVideo`,
  VIDEO_VISUAL: `${CSS_PREFIX}__container--visual`,
  SELECTED_PLAYLIST: `${CSS_PREFIX}__playlist-item--selected`,
  PLAYER_CONTENT: `${CSS_PREFIX}__content`,
  PLAYER_TITLE: `${CSS_PREFIX}__headerTitle`,
  PLAYER_MEDIA_BTN: `${CSS_PREFIX}__mediaBtn`,
  PLAYER_BIG_BTN: `${CSS_PREFIX}__playBtnBig`,
  PLAYER_BTN: `${CSS_PREFIX}__playBtn`,
  PLAYER_VOLUME_BTN: `${CSS_PREFIX}__volume-btn`,
  PLAYER_VOLUME_BAR: `${CSS_PREFIX}__volume-bar`,
  PLAYER_VOLUME_BAR_FILL: `${CSS_PREFIX}__volume-bar-filled`,
  PLAYER_PROGRESS_BAR: `${CSS_PREFIX}__progress`,
  PLAYER_PROGRESS_BAR_FILL: `${CSS_PREFIX}__progress-filled`,
  PLAYER_PLAYLIST: `${CSS_PREFIX}__playlist`,
  PLAYER_PLAYLIST_HEADER: `${CSS_PREFIX}__headerPlaylist`,
  PLAYER_PLAYLIST_ITEM: `${CSS_PREFIX}__playlist-item`,
  PLAYER_PLAYLIST_NAVLEFT: `${CSS_PREFIX}__navPlaylistBtnLeft`,
  PLAYER_PLAYLIST_NAVRIGHT: `${CSS_PREFIX}__navPlaylistBtnRight`,
  PLAYER_FULLSCREEN: `${CSS_PREFIX}__fullscreen`,
};

/**
 * @typedef {object} VideoPlayer
 * @property {HTMLVideoElement} video - The html video.
 * @property {HTMLButtonElement} playButtonCtrl - The html play button.
 */
export class VideoPlayer {
  #timeoutNavPlaylistID = null;
  #videoContent;
  #videoTitle;
  #video;
  #playBigButton;
  #videoPlayerBtn;
  #playButtonCtrl;
  #volumeButton;
  #fullscreenButton;
  #volumeBar;
  #volumeBarState;
  #progressBar;
  #progressBarState;
  #currentTime;
  #playlistElem;
  #playlistToggleBtn;
  #playlistItems;
  #playlistNavLeft;
  #playlistNavRight;
  #lastVolume = 0;
  #selectedPlaylist = null;
  #playListCount = 0;
  #isPlayListVisible = false;

  constructor({ el }) {
    this.el = el;

    // Reroute properties, remove that when use Web Components
    Object.defineProperties(this.el, {
      pause: {
        value: this.pause.bind(this),
        configurable: true,
      },
      play: {
        value: this.play.bind(this),
        configurable: true,
      },
      paused: {
        get: () => this.paused,
        configurable: true,
      },
    });

    this.#videoContent = this.el.querySelector(`.${CLASSNAMES.PLAYER_CONTENT}`);
    this.#videoTitle = this.el.querySelector(`.${CLASSNAMES.PLAYER_TITLE}`);
    this.#video = this.el.querySelector(`.${CLASSNAMES.VIDEO}`);
    this.#playBigButton = this.el.querySelector(`.${CLASSNAMES.PLAYER_BIG_BTN}`);
    this.#videoPlayerBtn = this.el.querySelector(`.${CLASSNAMES.PLAYER_MEDIA_BTN}`);
    this.#playButtonCtrl = this.el.querySelector(`.${CLASSNAMES.PLAYER_BTN}`);
    this.#volumeButton = this.el.querySelector(`.${CLASSNAMES.PLAYER_VOLUME_BTN}`);
    this.#fullscreenButton = this.el?.querySelector(`.${CLASSNAMES.PLAYER_FULLSCREEN}`);
    this.#volumeBar = this.el.querySelector(`.${CLASSNAMES.PLAYER_VOLUME_BAR}`);
    this.#volumeBarState = this.#volumeBar?.querySelector(`.${CLASSNAMES.PLAYER_VOLUME_BAR_FILL}`);
    this.#progressBar = this.el.querySelector(`.${CLASSNAMES.PLAYER_PROGRESS_BAR}`);
    this.#progressBarState = this.#progressBar?.querySelector(`.${CLASSNAMES.PLAYER_PROGRESS_BAR_FILL}`);
    this.#currentTime = this.el.querySelector('.js-video-player-currentTime');
    this.#playlistElem = this.el.querySelector(`.${CLASSNAMES.PLAYER_PLAYLIST}`);
    this.#playlistToggleBtn = this.el.querySelector(`.${CLASSNAMES.PLAYER_PLAYLIST_HEADER}`);
    this.#playlistItems = Array.from(this.el.querySelectorAll(`.${CLASSNAMES.PLAYER_PLAYLIST_ITEM}`));
    this.#playlistNavLeft = this.el.querySelector(`.${CLASSNAMES.PLAYER_PLAYLIST_NAVLEFT}`);
    this.#playlistNavRight = this.el.querySelector(`.${CLASSNAMES.PLAYER_PLAYLIST_NAVRIGHT}`);

    this.#playButtonCtrl?.addEventListener('click', this.#playPause);
    this.#playBigButton.addEventListener('click', this.#playPause);
    this.#volumeButton?.addEventListener('click', this.#toggleMute);
    this.#volumeBar?.addEventListener('click', this.#handleChangeVolume);
    this.#progressBar?.addEventListener('click', this.#handleProgressBarClick);
    this.#fullscreenButton?.addEventListener('click', this.#toggleFullscreen);
    this.#playlistToggleBtn?.addEventListener('click', this.#togglePlaylist);
    this.el.addEventListener('mouseleave', this.#hidePlaylist);
    for (const playlistItem of this.#playlistItems) {
      playlistItem.addEventListener('click', () => this.#togglePlaylistItem(playlistItem));
    }
    this.#playlistNavLeft?.addEventListener('click', (event) => this.#navPlaylist(event, 'left'));
    this.#playlistNavRight?.addEventListener('click', (event) => this.#navPlaylist(event, 'right'));

    for (const eventName of ['play', 'pause', 'ended']) {
      this.#video.addEventListener(eventName, this.#updateState);
    }
    for (const eventName of ['timeupdate', 'canplay']) {
      this.#video.addEventListener(eventName, this.#updateProgress);
    }
    // bind keys
    this.#videoPlayerBtn.addEventListener('keyup', (event) => isFromValidationKey(event) && this.#playPause());
    this.#volumeBar.addEventListener('keydown', this.#handleChangeVolume);

    this.#playListCount = this.#playlistItems?.length ?? 0;
    this.#selectedPlaylist = this.#playListCount ? this.#playlistItems[0] : null;

    // Lazyload
    isIntersecting(this.el).then(() => this.#load());
  }

  #load() {
    if (this.#video.readyState === 0) {
      this.#video.load();
    }
  }

  #playPause = () => {
    this.paused ? this.play() : this.pause();
  };

  get paused() {
    return this.#video.paused;
  }

  play() {
    if (!this.paused) {
      return;
    }
    this.#video.play();
    this.#hidePlaylist();
    this.#updateState();

    // ?
    this.el.classList.add('launched');
  }

  pause() {
    if (this.paused) {
      return;
    }
    this.#video.pause();
    this.#updateState();
  }

  /**
   * - Based on element's class 'pause' we change state of all elements inside video (playButtonCtrl, BigPlayButton...)
   * - Update labels of play/pause controls (a11y)
   */
  #updateState = () => {
    this.el.classList.toggle('paused', this.paused);
    // update labels
    const ariaLabel = this.paused ? PLAY_VIDEO_LABEL : PAUSE_VIDEO_LABEL;
    this.#videoPlayerBtn.setAttribute('aria-label', ariaLabel);
    this.#playButtonCtrl.setAttribute('aria-label', ariaLabel);
    this.#updatePlayListLabels();
  };

  /** When the user click on volume icon we mute or unmute the video. */
  #toggleMute = () => {
    if (this.#video.volume) {
      this.#changeVolume(0);
    } else {
      this.#changeVolume(this.#lastVolume);
    }
    this.#updateVolumeLabels();
  };

  /**
   * When the user click on the volume bar we update the video's volume.
   *
   * @param {MouseEvent} e
   */
  #handleChangeVolume = (e) => {
    let volume = null;
    if (e.offsetX) {
      volume = e.offsetX / this.#volumeBar.offsetWidth;
    } else if (isFromHorizontalArrowKeys(e)) {
      volume = this.#video.volume + (isFromLeftArrowKey(e) ? -0.1 : 0.1);
    } else {
      return;
    }
    volume = volume < 0.1 ? 0 : volume >= 1 ? 1 : volume;
    this.#changeVolume(volume);
  };

  #changeVolume = (value) => {
    this.#lastVolume = this.#video.volume;
    this.#volumeBarState.style.width = `${value * 100}%`;
    this.#video.volume = value;
    const { classList } = this.#volumeButton;
    classList.toggle('muted', value === 0);
    classList.toggle('quiet', value <= 0.7 && value > 0);
    classList.toggle('loud', value > 0.7);
    this.#updateVolumeLabels();
  };

  /** While video is playing we update the progress bar width and also the time. */
  #updateProgress = () => {
    this.#progressBarState.style.width = `${(this.#video.currentTime / this.#video.duration) * 100}%`;
    this.#currentTime.innerHTML = `${formatDuration(this.#video.currentTime)} / ${formatDuration(
      this.#video.duration
    )}`;
  };

  /**
   * When the user click on the progress bar we update the current time of the video
   *
   * @param {MouseEvent} e
   */
  #handleProgressBarClick = (e) => {
    const newTime = clamp(e.offsetX / this.#progressBar.offsetWidth, 0, 1);
    this.#video.currentTime = newTime * this.#video.duration;
  };

  #toggleFullscreen = async () => {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
      this.#fullscreenButton.setAttribute('aria-label', FULLSCREEN_VIDEO_LABEL);
    } else {
      await this.#videoContent.requestFullscreen();
      this.#fullscreenButton.setAttribute('aria-label', QUIT_FULLSCREEN_VIDEO_LABEL);
    }
  };

  #togglePlaylist = () => {
    this.#isPlayListVisible = !this.#isPlayListVisible;
    this.#playlistElem?.classList.toggle(`${CLASSNAMES.PLAYER_PLAYLIST}--show`);
    this.#updatePlayListLabels();
  };

  #hidePlaylist = () => {
    this.#isPlayListVisible = false;
    this.#playlistElem?.classList.remove(`${CLASSNAMES.PLAYER_PLAYLIST}--show`);
    this.#updatePlayListLabels();
  };

  /** Based on playlist visibility we update labels and we enables buttons */
  #updatePlayListLabels = () => {
    this.#playlistToggleBtn?.setAttribute(
      'aria-label',
      this.#isPlayListVisible ? PLAYLIST_HIDE_LABEL : PLAYLIST_SHOW_LABEL
    );
    this.#playlistElem?.setAttribute('aria-hidden', !this.#isPlayListVisible);
    this.#playlistItems?.forEach((button) => {
      button.disabled = !this.#isPlayListVisible;
      if (button !== this.#selectedPlaylist) {
        button.setAttribute('aria-label', PLAY_VIDEO_LABEL);
      }
    });
    this.#selectedPlaylist?.setAttribute('aria-label', this.paused ? PLAY_VIDEO_LABEL : PAUSE_VIDEO_LABEL);
  };

  /** @param {HTMLButtonElement} playlistItem */
  #togglePlaylistItem = (playlistItem) => {
    this.#selectedPlaylist = playlistItem;
    const sourceElements = getPlaylistItemSources(playlistItem).map(({ src, type }) =>
      Object.assign(document.createElement('source'), { src, type })
    );
    // Use sources element, not video.src
    this.#video.replaceChildren(...sourceElements);
    this.#video.load(); // apply sources change
    for (const item of this.#playlistItems) {
      item.classList.remove(CLASSNAMES.SELECTED_PLAYLIST);
    }
    this.#videoTitle.innerText = playlistItem.dataset.title;
    playlistItem.classList.add(CLASSNAMES.SELECTED_PLAYLIST);
    this.#hidePlaylist();
    this.play();
    // update playlist nav buttons
    const index = this.#playlistItems.indexOf(playlistItem);
    const lastIndex = this.#playListCount - 1;
    this.#playlistNavLeft.disabled = index === 0;
    this.#playlistNavRight.disabled = index === lastIndex;
  };

  /**
   * Set the selected playlist when we click on a nav button and toggle playlist state.
   *
   * @param {MouseEvent} event
   * @param {'right' | 'left'} direction Used to determine which nav button clicked for playlist.
   */
  #navPlaylist = (event, direction) => {
    const isFromMouse = event.offsetX;
    let index = this.#playlistItems.indexOf(this.#selectedPlaylist);
    const lastIndex = this.#playListCount - 1;

    if (direction === 'left' && index >= 1) {
      index--;
    } else if (direction === 'right' && index < lastIndex) {
      index++;
    }
    const selectedPlaylist = (this.#selectedPlaylist = this.#playlistItems[index]);
    // handle fast click when nav between playlists
    if (this.#timeoutNavPlaylistID) {
      clearInterval(this.#timeoutNavPlaylistID);
    }
    this.#timeoutNavPlaylistID = setTimeout(() => this.#togglePlaylistItem(selectedPlaylist), 400);
    // handle a11y, if we reach playlist boundaries we focus the play button
    if (!isFromMouse && (index === 0 || index === lastIndex)) {
      this.#playButtonCtrl.focus();
    }
  };

  /**
   * - Volume state label Activate/Deactivate
   * - Volume percentage label
   */
  #updateVolumeLabels = () => {
    this.#volumeButton.setAttribute('aria-label', this.#video.volume ? DEACTIVATE_AUDIO_LABEL : ACTIVATE_AUDIO_LABEL);
    this.#volumeBar.setAttribute('aria-label', VOLUME_AUDIO_LABEL(Math.round(this.#video.volume * 100)));
  };
}

/**
 * @typedef {object} VideoSource
 * @property {string} url Source (URL)
 * @property {string} type Media type
 * @property {number | null} width Width of media, in pixels
 * @property {number | null} height Height of media, in pixels
 */

/**
 * @param {Element} element Playlist item element
 * @returns {VideoSource[]}
 */
function getPlaylistItemSources({ dataset: { sources } }) {
  try {
    return JSON.parse(sources || '[]');
  } catch (error) {
    reportError(error);
    return [];
  }
}

componentRegistry.define('js-video-player', VideoPlayer);
