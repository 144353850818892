import addToCartInternal from './addToCartInternal.js';
import { cartOneClickURL, cartURL } from '../../../configuration/Configuration.js';

/** @typedef {import('../../../../components/product-buy-form/typedefs.js').CartProduct} CartProduct */

/**
 * Add products to cart and get redirection
 *
 * @param {CartProduct[]} products Products to add
 * @param {string} url URL is different than cartAddURL
 */
export default async (products, url) => {
  // Horrible hack because fetch polyfill can't emulate redirect mode https://github.com/github/fetch/blob/master/README.md#redirect-modes
  // And because APIs are not consistent:
  // - CrossSellBasketController Add() redirect to OrderPipe Root page with 302 if header "X-Requested-With: XMLHttpRequest" is set else with cart modal content (HTML)
  // - OneClickBasketController Add() redirect to OrderPipe Root or login pages with 201 if header "X-Requested-With: XMLHttpRequest" is set else with 302
  const response = await addToCartInternal({ url, products });
  return new URL(url, document.baseURI).href === new URL(cartOneClickURL, document.baseURI).href
    ? response.headers.get('Location')
    : cartURL;

  // const response = await addToCartInternal({
  //   url,
  //   redirect: 'manual',
  //   products,
  // });
  // // The endpoint respond with 201 or 200 and provide a header Location
  // return response.headers.get('Location');
};
