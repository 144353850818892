import componentRegistry from '../registry.js';

export default class Dialog {
  el;
  #open = false;

  /**
   * Like HTMLDialogElement by untill support of Web Components
   *
   * @param {Element} el
   */
  constructor({ el } = {}) {
    this.el = el ?? document.createElement('div');
  }

  /*
  Untill WebComplnenets are supported, act as a proxy of the element
  */
  addEventListener(...args) {
    this.el.addEventListener.apply(this.el, args);
  }

  removeEventListener(...args) {
    this.el.removeEventListener.apply(this.el, args);
  }

  dispatchEvent(...args) {
    this.el.dispatchEvent.apply(this.el, args);
  }

  show() {
    throw new Error('Not implemented');
  }

  showModal() {
    if (this.#open) {
      throw new DOMException('Dialog already open', 'InvalidStateError'); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement/showModal#Exceptions
    }

    // if(!this.el.isConnected){
    //   throw new DOMException(`Failed to execute 'showModal' on 'HTMLDialogElement': The element is not in a Document.`);
    // }

    this.#open = true;

    // Dispatch custom event that bubble
    this.el.dispatchEvent(new Event('show', { bubbles: true }));
  }

  get open() {
    return this.#open;
  }

  close() {
    if (!this.#open) {
      return;
    }

    this.#open = false;
    this.dispatchEvent(new Event('close', { bubbles: true }));
  }
}
componentRegistry.define('js-dialog', Dialog);
