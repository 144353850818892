/**
 * Call executor only when necessary Kind of throttle function
 *
 * Call the first time. Consecutive call return the same result, until force is request or TTL is reached
 *
 * @example
 *   const value = new CacheableResult(() => fetch('http://example.com/today').then((res) => res.text()), 4000);
 *   setInterval(async () => console.log(await value.read()), 500);
 * @example
 *   const value = new CacheableResult(() => new Date(), 4000);
 *   setInterval(() => console.log({now: new Date(), lastUpdate: value.read()})), 500);
 */
export default class {
  #executor;
  #ttl;
  #value = undefined;
  #date = -1;

  /**
   * @param {Function} executor Function to store the result value
   * @param {number} ttl Time To Live
   */
  constructor(executor, ttl = 0) {
    this.#executor = executor;
    this.#ttl = ttl;
  }

  read(force) {
    const now = performance.now();
    if (force || this.#date < 0 || (this.#ttl > 0 && now > this.#date + this.#ttl)) {
      this.#date = now;
      this.#value = this.#executor();
    }

    return this.#value;
  }
}
