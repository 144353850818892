/**
 * Resolve if the HTTP code is in range of 200 to 299. Reject other cases
 *
 * @param {Promise} promise
 * @returns {Promise}
 */
export default (promise) =>
  promise.then((response) => {
    const { ok, status, statusText } = response;

    // Resolve only response with status inside the range 200-299
    if (ok) {
      return response;
    }

    return Promise.reject(response);
  });
