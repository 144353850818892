import rejectNonOkResponse from '../rejectNonOkResponse.js';
export default async (url) =>
  (
    await rejectNonOkResponse(
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/html',
        },
        redirect: 'error', // will trigger a "TypeError: Failed to fetch" in case of the popin has been redirected to an other URL
      })
    )
  ).text();
