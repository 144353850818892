import componentRegistry from '../../registry.js';
import Kamino from '../kamino.js';

import './index.less';

export default class KaminoBillboard extends Kamino {
  constructor({ el }) {
    super({ el });
  }
}

componentRegistry.define('js-kamino-billboard', KaminoBillboard);
