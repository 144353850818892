import componentRegistry from '../registry.js';
import Touchy, { TOUCHY_EVENTS } from '../touchy/index.js';
import { isMobile, isTouch } from '../../configuration/Configuration.js';
import { MEDIAS_CLASSNAMES } from '../product-medias/constants.js';
import DebounceEventListener from '../../helpers/event/debounce-listener.js';
import ResizeObserver from '../../scripts/polyfills/web.naive-resizeobserver.js';
import renderNCarousel from './ncarousel.liquid';
import './ncarousel.less';
import parseHTML from '../../helpers/dom/parseHTMLFragment.js';

const CSS_PREFIX = 'f-nCarousel';
export const NCAROUSEL_CLASSNAMES = {
  SLIDER: `${CSS_PREFIX}`,
  SCROLLER: `${CSS_PREFIX}__scroller`,
  WRAPPER: `${CSS_PREFIX}__wrapper`,
  ITEM: `${CSS_PREFIX}__item`,
  ITEM_INNER: `${CSS_PREFIX}__itemInner`,
  ARROWS: `${CSS_PREFIX}--arrows`,
  ARROW: `${CSS_PREFIX}__arrow`,
  ARROW_PREV: `${CSS_PREFIX}__arrow--prev`,
  ARROW_NEXT: `${CSS_PREFIX}__arrow--next`,
  ARROW_ICON: `${CSS_PREFIX}__arrowIcon`,
  NOSCROLL: `${CSS_PREFIX}--noScroll`,
  HAS_JS: `hasJs`,
  BULLETS: `${CSS_PREFIX}--bullets`,
};
export const NCAROUSEL_EVENTS = {
  RESIZE: 'carouselresize', // FIXME use ResizeObserver instead!
  SCROLL_START: 'carouselscrollstart',
  SCROLL_END: 'carouselscrollend',
  CLEAR_AUTOPLAY: 'carouselclearautoplay',
};

/**
 * NCarousel Native js carousel
 *
 * @param {HTMLElement} el The DOM element
 * @param {object} options
 * @example
 *   ```
 *   HTML Usage:
 *   <div class="f-nCarousel js-NCarousel">
 *    <div class="myItem">...</div>
 *    <div class="myItem">...</div>
 *    <div class="myItem">...</div>
 *   </div>
 *
 *   With options:
 *   <div class="f-nCarousel js-NCarousel" data-visibles="1" data-auto="true">
 *
 *   Programmatically:
 *   const newCarousel = new NCarousel({el, options: {visibles: 2, arrows: false}});
 *
 *   or
 *   export default class MyCarouselClassName extends NCarousel {
 *    constructor({ el }) {
 *      super({
 *       el,
 *       options: {
 *         visibles: 1,
 *         responsive: [
 *           {
 *             breakpoint: 768,
 *             options: {
 *               visibles: 2,
 *             },
 *           },
 *         ],
 *       },
 *     });
 *    }
 *   }
 *
 *   Setting item width with CSS:
 *
 *   // Fixed width
 *   .myItem {
 *      width: 300px;
 *   }
 *
 *   // Responsive width (this won't apply if 'visibles' option is set)
 *   .f-nCarousel__item {
 *      min-width: 90%;
 *
 *      @media (min-width: 768px) {
 *        min-width: 50%;
 *      }
 *   }
 *   ```;
 */
export default class NCarousel {
  #pageIndex;
  #nav;
  #items;
  #visibles;
  #mergedOptions;
  #options;
  #scroller;
  #wrapper;
  #innerItems;
  #scrollerPosition;
  #bullets;
  #arrowPrev;
  #arrowNext;
  #itemWidth;
  #pageCount;
  #resizeObserver;

  constructor({ el, options = {} }) {
    if (!el) {
      throw new TypeError('Element not found.');
    }
    this.el = el;

    // Reroute properties, remove that when use Web Components
    Object.defineProperties(this.el, {
      scrollToPage: {
        value: this.scrollToPage.bind(this),
        configurable: true,
      },
      resize: {
        value: this.resize.bind(this),
        configurable: true,
      },
      pageIndex: {
        get: () => this.pageIndex,
        set: (value) => {
          this.pageIndex = value;
        },
        configurable: true,
      },
      items: {
        get: () => this.items,
        configurable: true,
      },
      activeItem: {
        get: () => this.activeItem,
        configurable: true,
      },
    });

    const defaults = {
      startIndex: 0,
      visibles: undefined,
      nav: undefined, // NCarousel html element
      animationDuration: 400, // to avoid weird reverse scroll impression
      animationEasing: 'ease-in-out',
      swipeThreshold: 50,
      arrows: !isMobile,
      arrowPrev: null,
      arrowNext: null,
      touch: isTouch,
      bullets: false,
      loop: false,
      auto: false,
      autoInterval: 5000,
      nativeScroll: isMobile,
    };
    this.#mergedOptions = {
      ...defaults,
      ...optionsFromDataset(this.el.dataset),
      ...options,
    };
    this.#options = this.#mergedOptions;

    // NativeScroll option will use default browser srollbar (scrollSnap)
    // This behavior is useless for nav functionality
    if (this.#options.nativeScroll && !this.#options.nav) {
      return;
    }

    // Get current children
    const items = Array.from(el.children);
    if (!items.length) {
      throw new TypeError(`No item found.`);
    }

    // 1. remove all el.children as items, but keep a ref
    // 2. generate an HTMLFragment as children
    // 3. inject items in it
    // 4. insert children in el
    this.el.classList.add(NCAROUSEL_CLASSNAMES.SLIDER, NCAROUSEL_CLASSNAMES.HAS_JS);
    this.el.replaceChildren(); // remove all children
    const children = parseHTML(
      renderNCarousel({
        classNames: NCAROUSEL_CLASSNAMES,
        count: items.length,
        arrows: this.#options.arrows && !this.#options.arrowPrev, // case when a DOM arrow already exists
        bullets: this.#options.bullets,
      })
    );
    this.#items = children.querySelectorAll(`.${NCAROUSEL_CLASSNAMES.ITEM}`);
    this.#innerItems = items;
    for (const [i, item] of items.entries()) {
      item.classList.add(NCAROUSEL_CLASSNAMES.ITEM_INNER);
      this.#items[i].appendChild(item);
    }
    this.el.appendChild(children);

    this.#scroller = this.el.querySelector(`.${NCAROUSEL_CLASSNAMES.SCROLLER}`);
    this.#wrapper = this.el.querySelector(`.${NCAROUSEL_CLASSNAMES.WRAPPER}`);

    // Scrolling params
    this.#pageIndex = parseInt(this.#options.startIndex);
    this.#scrollerPosition = 0;
    this.#scroller.style.transition = `transform ${this.#options.animationDuration}ms ease-out 0s`;

    // Touchy events
    if (this.#options.touch) {
      const touchyScroller = new Touchy({ el: this.#wrapper, options: { touchAction: 'pan-y' } });
      touchyScroller.on(`${TOUCHY_EVENTS.PANSTART} ${TOUCHY_EVENTS.PAN} ${TOUCHY_EVENTS.PANEND}`, (e) =>
        this.#touchHandler(e)
      );
    }

    // As navigation
    // Slider as nav will act with target slider as a puppet master
    // adding callback actions to it
    // FIXME: orchestration shouldn't made by the carousel itself. Use an independent subcomponents that are impotent (like "sliders" for both slides and thumbnails) -> Pageable and Pager
    if (this.#options.nav) {
      this.#nav = this.#options.nav;

      // Click on nav items will scroll target carousel
      this.#items.forEach((item, i) =>
        item.addEventListener('click', () => {
          if (i === this.#nav.pageIndex) return;
          this.#nav.scrollToPage(i);
        })
      );

      // Slide on target carousel will activate matching nav item
      this.#nav.addEventListener(NCAROUSEL_EVENTS.SCROLL_START, ({ detail: { currentIndex, animated } }) => {
        this.#navSetActiveItem(currentIndex, animated);
      });

      // Resizing main slider will also resize nav slider
      // this.#nav.addEventListener(NCAROUSEL_EVENTS.RESIZE, () => {
      //   this.resize();
      // });

      // First load nav item selection
      this.#navSetActiveItem(this.#pageIndex);
    }

    // With arrows
    if (this.#options.arrows) {
      this.el.classList.add(NCAROUSEL_CLASSNAMES.ARROWS);
      this.#arrowPrev = this.#options.arrowPrev || this.el.querySelector(`.${NCAROUSEL_CLASSNAMES.ARROW_PREV}`);
      this.#arrowNext = this.#options.arrowNext || this.el.querySelector(`.${NCAROUSEL_CLASSNAMES.ARROW_NEXT}`);
      [this.#arrowPrev, this.#arrowNext].forEach((a) => this.#bindArrow(a));
      this.#setColorArrow(this.#pageIndex);
    }

    // With bullets
    if (this.#options.bullets) {
      this.#bullets = this.el.querySelector(`.${NCAROUSEL_CLASSNAMES.BULLETS}`);
      new NCarousel({ el: this.#bullets, options: { nav: this.el, arrows: false, visibles: this.#items.length } });
    }

    // Resizer
    // Nav resizing depends on target 'onResize' event handler
    if (!this.#options.nav) {
      window.addEventListener('resize', new DebounceEventListener(() => this.resize(), 200));
    }

    this.#resizeObserver = new ResizeObserver(() => this.resize());
    this.#resizeObserver.observe(this.#wrapper);

    // Autoplay
    this.#setAutoPlay();

    if (this.#options.auto && !this.#options.touch && !this.#options.nav) {
      // Autoplay handlers
      el.addEventListener('mouseenter', this.#clearAutoPlay.bind(this));
      el.addEventListener('mouseleave', this.#setAutoPlay.bind(this));
    }
  }

  get items() {
    return this.#items;
  }

  get arrowsAreReady() {
    return this.#options.arrows && this.#arrowPrev && this.#arrowNext;
  }

  get activeItem() {
    return this.el.querySelector(`.${NCAROUSEL_CLASSNAMES.ITEM_INNER}.${MEDIAS_CLASSNAMES.ACTIVE}`);
  }

  get nextLimit() {
    return this.isNextLimit && !this.#options.loop;
  }

  get prevLimit() {
    return this.isPrevLimit && !this.#options.loop;
  }

  get pageIndex() {
    return this.#pageIndex;
  }
  set pageIndex(value) {
    this.scrollToPage(value);
  }

  /**
   * Binds an arrow element
   *
   * @param {HTMLElement} element
   */
  #bindArrow(element) {
    element.addEventListener('click', ({ target }) => {
      if (target.classList.contains(MEDIAS_CLASSNAMES.DISABLED)) return;
      element === this.#arrowPrev ? this.#scrollPrev() : this.#scrollNext();
      this.#options.touch && this.#clearAutoPlay();
    });
  }

  /**
   * Set values according to viewport and set items width this.visibles: number of visible items in a page
   * this.pageCount: number of pages
   */
  resize() {
    this.#getResponsiveOptions();
    this.el.classList.remove(NCAROUSEL_CLASSNAMES.NOSCROLL);

    // Item width
    // Either dynamic or fixed
    if (!this.#options.visibles) {
      this.#itemWidth = this.#scroller.firstElementChild.offsetWidth;
      this.#visibles = Math.max(Math.floor(this.#wrapper.clientWidth / this.#itemWidth), 1);
    } else {
      this.#itemWidth = this.#wrapper.offsetWidth / this.#options.visibles;
      this.#visibles = Math.floor(this.#options.visibles);
      // Use min & max-width to have priority to flex width
      this.#items.forEach((item) => {
        item.style.minWidth = item.style.maxWidth = `${this.#itemWidth}px`;
      });
    }
    this.#pageCount = Math.ceil(this.#items.length / this.#visibles);
    const scrollerWidth = this.#items.length * this.#itemWidth;

    // Case when no scroll is needed
    const noScroll = this.#wrapper.offsetWidth > 0 && scrollerWidth > 0 && scrollerWidth <= this.#wrapper.offsetWidth;
    noScroll && this.el.classList.add(NCAROUSEL_CLASSNAMES.NOSCROLL);
    this.arrowsAreReady &&
      [this.#arrowPrev, this.#arrowNext].forEach((a) => (a.style.display = noScroll ? '' : 'block'));

    // Resize callback
    this.el.dispatchEvent(new Event(NCAROUSEL_EVENTS.RESIZE));

    // Replace carousel at right index
    this.scrollToPage(this.#pageIndex, false);
  }

  /**
   * Changes options depending on the window size. The breakpoint calculation is mobile first (min-width). If no
   * breakpoint is found, mergedOptions are applied.
   */
  #getResponsiveOptions() {
    if (!Array.isArray(this.#options.responsive)) {
      return;
    }
    const match = this.#options.responsive.reduce(
      (accumulator, current) => {
        return current.breakpoint > accumulator.breakpoint &&
          window.matchMedia(`(min-width: ${current.breakpoint}px)`).matches
          ? current
          : accumulator;
      },
      { breakpoint: 0, options: this.#mergedOptions }
    );
    this.#options = { ...this.#options, ...match.options };
  }

  /**
   * Handles gesture callbacks
   *
   * @param {Event} e Original event
   */
  #touchHandler = (e) => {
    if (this.el.classList.contains(MEDIAS_CLASSNAMES.GESTURE)) {
      e.preventDefault();
      return false;
    }

    let deltaX;
    switch (e.type) {
      case TOUCHY_EVENTS.PANSTART:
        this.#clearAutoPlay();
        break;
      case TOUCHY_EVENTS.PAN:
        this.#scrollToPosition(e.deltaX);
        break;
      case TOUCHY_EVENTS.PANEND:
        ({ deltaX } = e);
        if (deltaX > 0 && Math.abs(deltaX) > this.#options.swipeThreshold) {
          this.prevLimit ? this.#scrollToPosition() : this.#scrollPrev();
        } else if (deltaX < 0 && Math.abs(deltaX) > this.#options.swipeThreshold) {
          this.nextLimit ? this.#scrollToPosition() : this.#scrollNext();
        } else {
          this.#scrollToPosition();
        }
        break;
    }
  };

  /**
   * Scrolls to this.scrollerPosition, + an optional value CSS animation is used instead of js animate method, because
   * in some very rare cases and for unknown reason, animate will lose offset position
   *
   * @param {number} deltaX
   * @param {boolean} animated
   */
  #scrollToPosition(deltaX = 0, animated = true) {
    this.#scroller.style.transitionDuration = animated ? `${this.#options.animationDuration}ms` : '0s';
    this.#scroller.style.transform = `translateX(${-this.#scrollerPosition + deltaX}px)`;

    // SCROLL_END event handler
    if (!animated) {
      this.#dispatchCustomEvent(NCAROUSEL_EVENTS.SCROLL_END);
      return;
    }
    this.#scroller.addEventListener('transitionend', () => this.#dispatchCustomEvent(NCAROUSEL_EVENTS.SCROLL_END), {
      once: true,
    });
    if (this.#options.arrows) {
      this.#setColorArrow(this.#pageIndex);
    }
  }

  /**
   * Scrolls to a specific page
   *
   * @param {number} pageIndex Index of targeted page
   * @param {boolean} animated
   */
  scrollToPage(pageIndex, animated = true) {
    const prevIndex = this.#pageIndex;
    this.#pageIndex = this.#checkIndex(pageIndex);
    this.#dispatchCustomEvent(NCAROUSEL_EVENTS.SCROLL_START, {
      animated,
      prevIndex: prevIndex === this.#pageIndex ? undefined : prevIndex,
    });

    if (!this.#options.nav && this.visibles) {
      this.#setActiveItem(pageIndex);
    }

    this.#setTabIndex();

    // Scroller position value
    // Standard calculation (full page) = this.#itemWidth * this.visibles * this.#pageIndex
    // Smart calculation (only remaining items) = this.#itemWidth * this.getSmartScrollIndex()
    this.#scrollerPosition = this.#itemWidth * this.#getSmartScrollIndex();
    this.#scrollToPosition(null, animated);
  }

  /**
   * @param {string} type Event type name
   * @param {object} options
   */
  #dispatchCustomEvent(type, options) {
    this.el.dispatchEvent(
      new CustomEvent(type, {
        detail: {
          currentIndex: this.#pageIndex,
          ...options,
        },
      })
    );
  }

  /**
   * To scroll only what's left
   *
   * @returns {number} Number of items to scroll
   */
  #getSmartScrollIndex() {
    if (this.#pageIndex === 0) {
      return 0;
    }
    const remainingItems = this.#items.length - this.#pageIndex * this.#visibles;
    return this.#visibles * this.#pageIndex - (remainingItems < this.#visibles ? this.#visibles - remainingItems : 0);
  }

  /** Scrolls to next page */
  #scrollNext() {
    this.scrollToPage(this.#pageIndex + 1);
  }

  /** Scrolls to previous page */
  #scrollPrev() {
    this.scrollToPage(this.#pageIndex - 1);
  }

  /**
   * Check page limits
   *
   * @param {number} pageIndex Index of targeted page
   */
  #checkIndex(pageIndex = this.#pageIndex) {
    const prevLimit = 0;
    const nextLimit = this.#pageCount - 1;
    this.isPrevLimit = this.#options.loop ? pageIndex < prevLimit : pageIndex <= prevLimit;
    this.isNextLimit = this.#options.loop ? pageIndex > nextLimit : pageIndex >= nextLimit;

    if (!this.#options.loop) {
      if (this.arrowsAreReady) {
        this.#arrowPrev.classList.toggle(MEDIAS_CLASSNAMES.DISABLED, this.isPrevLimit);
        this.#arrowNext.classList.toggle(MEDIAS_CLASSNAMES.DISABLED, this.isNextLimit);
      }
      if (this.isPrevLimit || this.isNextLimit) this.#clearAutoPlay();
    }

    if (this.isPrevLimit) {
      return this.#options.loop ? nextLimit : prevLimit;
    }
    if (this.isNextLimit) {
      return this.#options.loop ? prevLimit : nextLimit;
    }
    return pageIndex;
  }

  /**
   * Adds active class on an item
   *
   * @param {number} index Item index
   */
  #setActiveItem(index) {
    this.#innerItems.forEach((item, i) => item.classList.toggle(MEDIAS_CLASSNAMES.ACTIVE, i === index));
  }

  /**
   * Checks if selected nav item is visible by user. If not, scrolls the nav in order to position item correctly.
   *
   * @param {number} index Item index
   * @param {boolean} animated
   */
  #navCheckScrollPosition(index, animated) {
    if (!this.#visibles) {
      return false;
    }

    // Refocus on active item
    const itemPage = Math.floor(index / this.#visibles);
    if (itemPage !== this.#pageIndex) {
      this.scrollToPage(itemPage, animated);
    }
  }

  #setTabIndex() {
    for (const [i, item] of this.#items.entries()) {
      const focusableElements = item.querySelectorAll('a[href], button, [tabindex]:not([tabindex="-1"]');
      focusableElements.forEach((elem) => {
        if (i >= this.#pageIndex && i < this.#pageIndex + this.#visibles) {
          elem.removeAttribute('tabindex');
        } else elem.tabIndex = -1;
      });
    }
  }

  /**
   * Set nav active item
   *
   * @param {number} index Item index
   * @param {boolean} animated
   */
  #navSetActiveItem(index, animated) {
    this.#setActiveItem(index);
    this.#navCheckScrollPosition(index, animated);
  }

  /** Checks if auto-playing is needed */
  #setAutoPlay() {
    if (!this.#options.auto || this.#options.nav) {
      return;
    }
    this.#clearAutoPlay();
    this.autoPlayTimer = setInterval(this.#scrollNext.bind(this), this.#options.autoInterval);
  }

  /** Stop auto-playing */
  #clearAutoPlay() {
    if (!this.autoPlayTimer) {
      return;
    }
    clearTimeout(this.autoPlayTimer);
    this.autoPlayTimer = 0;
    this.#dispatchCustomEvent(NCAROUSEL_EVENTS.CLEAR_AUTOPLAY);
  }

  /**
   * Set color to arrow
   *
   * @param {number} index
   */
  #setColorArrow(index) {
    const element = this.#innerItems[index];
    if (!element) return;

    const itemStyle = getComputedStyle(element);
    if (itemStyle == null) return;

    const propertyColor = '--f-ncarousel-arrow-color';
    const propertyBgColor = '--f-ncarousel-arrow-bg-color';
    const color = itemStyle.getPropertyValue(propertyColor) || null;
    const bgColor = itemStyle.getPropertyValue(propertyBgColor) || null;
    const iconPrev = this.#arrowPrev.querySelector('.' + NCAROUSEL_CLASSNAMES.ARROW_ICON);
    const iconNext = this.#arrowNext.querySelector('.' + NCAROUSEL_CLASSNAMES.ARROW_ICON);

    const setOrRemoveProperty = (propertyName, propertyType, value) =>
      value == null
        ? [iconPrev, iconNext].forEach((icon) => icon?.style.removeProperty(propertyName))
        : [iconPrev, iconNext].forEach((icon) =>
            icon?.style.setProperty(propertyName, 'var(' + propertyType + ',' + value + ')')
          );

    setOrRemoveProperty('color', propertyColor, color);
    setOrRemoveProperty('background-color', propertyBgColor, bgColor);
  }
}

componentRegistry.define('js-NCarousel', NCarousel);

/**
 * Revive dataset option from string
 *
 * @param {DOMStringMap} dataset
 */
function optionsFromDataset(dataset) {
  return Object.fromEntries(
    Object.entries(dataset)
      .map(([key, value]) => {
        switch (key) {
          // uint (number)
          case 'startIndex':
          case 'visibles': // data-visibles="1"
          case 'animationDuration': // data-animation-duration="400"
          case 'swipeThreshold': // data-swipe-threshold="50"
          case 'autoInterval': {
            // data-auto-interval="5000"
            const parsedValue = parseInt(value, 10);
            // Invalid format
            if (Number.isNaN(parsedValue) || parsedValue < 0) {
              return null;
            }
            return [key, parsedValue];
          }

          // DOM ID
          case 'nav': // data-nav="some-element-id"
          case 'arrowPrev': // data-arrow-prev="some-element-id"
          case 'arrowNext': // data-arrow-next="some-element-id"
            return [key, document.getElementById(value)];

          // boolean
          case 'arrows': // data-arrows="true"
          case 'touch': // data-touch="true"
          case 'bullets': // data-bullets="true"
          case 'loop': // data-loop="true"
          case 'auto': // data-auto="true"
          case 'nativeScroll': // data-native-scroll="true"
            return [key, value !== 'false']; // `a`, `a=""`, `a="test"`, `a="true"` are all equal true

          // string
          case 'animationEasing':
            return [key, value];

          // JSON
          case 'responsive': {
            try {
              return [key, JSON.parse(value)]; // FIXME: check schema
            } catch {
              return null;
            }
          }

          // Others are ignored
          default:
            return null;
        }
      })
      .filter((kv) => kv != null)
  );
}
