import isBodyOverflowing from './is-body-overflowing.js';
import getScrollBarWidth from './get-scroll-bar-width.js';
import './disable-scroll.less';

// sticky basket is using fixed property
const FIXED_CONTENT_SELECTOR = '.js-Header, body';
// sticky or absolute
const STICKY_CONTENT_SELECTOR = '.Main-marketingHeader, .stickyHeader';

/**
 *
 */
export function lock() {
  if (isBodyOverflowing()) {
    const scrollbarWidth = getScrollBarWidth();
    const fixedContent = document.querySelectorAll(FIXED_CONTENT_SELECTOR);
    const stickyContent = document.querySelectorAll(STICKY_CONTENT_SELECTOR);

    // Adjust padding of fixed content and body
    fixedContent.forEach((element) => {
      const initialPaddingRight = window.getComputedStyle(element, null).getPropertyValue('padding-right');
      const paddingRight = parseFloat(initialPaddingRight);
      element.setAttribute('data-padding-right', initialPaddingRight);
      element.style.setProperty('padding-right', `${paddingRight + scrollbarWidth}px`, 'important');
    });

    // Adjust sticky content margin
    stickyContent.forEach((element) => {
      const initialMargin = window.getComputedStyle(element, null).getPropertyValue('margin-right');
      const marginRight = parseFloat(initialMargin);
      element.setAttribute('data-margin-right', initialMargin);
      element.style.setProperty('margin-right', `${marginRight + scrollbarWidth}px`, 'important');
    });
  }
  document.body.classList.add('disable-global-scroll');
}

/**
 *
 */
export function unlock() {
  document.body.classList.remove('disable-global-scroll');
  // Restore padding of fixed content and body
  const fixedContent = document.querySelectorAll(FIXED_CONTENT_SELECTOR);
  fixedContent.forEach((element) => {
    const padding = element.dataset.paddingRight;
    element.removeAttribute('data-padding-right');
    element.style.paddingRight = padding;
  });

  // Restore sticky content
  const stickyContent = document.querySelectorAll(STICKY_CONTENT_SELECTOR);
  stickyContent.forEach((element) => {
    const margin = element.dataset.marginRight;
    element.removeAttribute('data-margin-right');
    element.style.marginRight = margin;
  });
}
