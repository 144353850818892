// See src/OrderPipe/Steps/WebBusiness/MVC/BaseStepsMvcWeb/Controllers/CrossSellBasket/CrossSellBasketController.cs
import { DEFAULT_OFFER } from '../../helpers/product/product.js';

const productIdentifier = /^(\d+)(?:-([0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12})|\((\d+)\))?$/i;

/** @typedef {import('./typedefs.js').CartProduct} CartProduct */

/**
 * Parse product from input value to object
 *
 * @param {string} value
 * @returns {CartProduct | null}
 * @example
 *   parseProductDataFromValue('1-00000000-0000-0000-0000-000000000000') ===
 *     { prid: '1', offer: '00000000-0000-0000-0000-000000000000' }; // marketplace offer
 * @example
 *   parseProductDataFromValue('1234(5678)') === { prid: '5678', relatedPrid: '1234' }; // service
 * @example
 *   parseProductDataFromValue('1234') === { prid: '5678', relatedPrid: '1234' }; // service
 */
export default (value) => {
  if (value === null) {
    return null;
  }

  const result = value.match(productIdentifier);
  if (result == null) {
    return null;
  }

  const [, prid, offer = DEFAULT_OFFER, service = null] = result;
  return {
    prid: service ?? prid,
    offer,
    relatedPrid: service ? prid : null,
  };
};
