/**
 * Postpone its execution until after wait milliseconds have elapsed since the last time it was invoked. Useful for
 * implementing behavior that should only happen after the input has stopped arriving. If immediately is set to true,
 * the execution will be on the leading instead of the trailing edge of the wait interval. Useful in circumstances like
 * preventing accidental double-clicks.
 *
 * @example
 *   input.addEventListener('input', (event) => console.log(event, 'normal'));
 *   input.addEventListener('input', new DebounceEventListener((event) => console.log(event, 'debounce'), 250));
 *   form.addEventListener('submit', new DebounceEventListener((event) => console.log(event, 'submit'), 1000, true)); // Prevent double sumbit when double-click
 */
// See https://developer.mozilla.org/en-US/docs/Web/API/EventListener

export default class {
  #timeoutID = 0;
  #listener;
  #delay;
  #immediate;

  constructor(listener, delay = 0, immediate = false) {
    this.#listener = listener;
    this.#delay = delay;
    this.#immediate = immediate;
  }

  #exec(listener) {
    if (this.#timeoutID === 0 && this.#immediate) {
      listener();
    } else {
      clearTimeout(this.#timeoutID);
    }

    this.#timeoutID = setTimeout(() => {
      this.#timeoutID = 0;
      if (!this.#immediate) listener();
    }, this.#delay);
  }

  handleEvent(event) {
    this.#exec(() => this.#listener(event));
  }

  // This is a hack to support jQuery that don't handle EventListener (object with handleEvent())
  // $(input).on('input', (event) => console.log(event, 'normal'))
  //    .on('input', new DebounceEventListener((event) => console.log(event, 'debounce'), 250))
  // https://github.com/jquery/jquery/issues/1735
  // https://github.com/jquery/jquery/blob/e539bac79e666bba95bba86d690b4e609dca2286/src/event.js#L334
  // TODO remove when jQuery is no more supported or DebounceEventListener is no more used in jQuery contexts
  apply(thisArg, args) {
    this.#exec(() => this.#listener.apply(thisArg, args));
  }
}
