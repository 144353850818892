// See /definitions/product https://www.fnac.com/json-schema/digital-data/custom-ceddl-01
/**
 * @param {object} param0
 * @param {string} [param0.prid]
 * @param {'1' | '3' | null} [param0.catalog]
 * @param {string | null} [param0.offer]
 * @param {number | null} [param0.quantity]
 * @param {string | null} [param0.availability]
 * @param {string | null} [param0.nature]
 * @param {string[] | null} [param0.eqNatures]
 * @param {string[] | null} [param0.sellerTypes]
 * @param {string | null} [param0.condition]
 * @param {string | null} [param0.fulfillment]
 * @param {string | null} [param0.sellerId]
 * @param {string | null} [param0.sellerLocation]
 * @param {string | null} [param0.sellerName]
 * @param {string | null} [param0.sellerType]
 */
export default ({
  prid,
  catalog,
  offer,
  quantity,
  availability,
  nature,
  eqNatures = [],
  sellerTypes = [],
  condition,
  fulfillment,
  sellerId,
  sellerLocation,
  sellerName,
  sellerType,
}) => ({
  attributes: {
    catalog: catalog === '3' ? 'metacat' : 'newref',
    offerID: offer,
    availabilityID: availability,
    // Create fake offers with minimal infos
    offer: sellerTypes.map((type) => ({
      sellerType: type, // fnac, store, private, professional
    })),
    nature,
    condition,
    fulfillment,
    sellerID: sellerId,
    sellerName,
    sellerLocation,
    sellerType,
  },
  productInfo: {
    productID: prid,
  },
  // Create fake linked products (alternatives) with minimal infos
  linkedProduct: eqNatures.map((nature) => ({
    attributes: {
      catalog: catalog === '3' ? 'metacat' : 'newref',
      nature,
    },
    productInfo: {
      productID: prid,
    },
  })),
  quantity,
});
