import rejectNonOkResponse from '../rejectNonOkResponse.js';
import { searchAutoCompleteURL } from '../../../configuration/Configuration.js';

export default async (term, { products = 0, suggestions = 0, categories = 0, shows = 0 } = {}) => {
  const url = new URL(searchAutoCompleteURL, document.baseURI);
  url.searchParams.set('q', term);
  // limit=s_bil_p_m where : s "suggestions", bil "billetterie", p "produits", m "catégories"
  url.searchParams.set('limit', [suggestions, shows, products, categories].join('_'));
  return (await rejectNonOkResponse(fetch(url))).json();
};
