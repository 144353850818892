import componentRegistry from '../../components/registry.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';
import isIntersecting from '../../helpers/dom/isIntersecting.js';

export default class LazyHTML {
  #loadCallback;

  /**
   * @param {object} param0
   * @param {Element} param0.el
   * @param {function():Promise<any>} [param0.load] Function called when load happened. Enable additional wait of async
   *   task (other than the load of the source itself)
   * @see https://github.com/github/include-fragment-element
   */
  constructor({ el, load = () => Promise.resolve() }) {
    this.el = el;
    this.#loadCallback = load;

    isIntersecting(this.el).then(() => this.#load());
  }

  #load = async () => {
    const { src } = this.el.dataset;
    try {
      const [html] = await Promise.all([rejectNonOkResponse(fetch(src)).then((r) => r.text()), this.#loadCallback?.()]);
      this.el.innerHTML = html;
      this.el.dispatchEvent(new Event('load', { bubbles: true }));
    } catch (e) {
      this.el.innerHTML = '<!-- Error -->';
      this.el.dispatchEvent(new Event('error', { bubbles: true }));
    }
  };
}

componentRegistry.define('js-lazy-html', LazyHTML);
