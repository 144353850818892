import { isUserAuthenticated } from '../../configuration/Configuration.js';
import createWishlist from '../../scripts/api/wishlist/createWishlist.js';
import addWishlistItem from '../../scripts/api/wishlist/addWishlistItem.js';
import getWishlists from '../../scripts/api/wishlist/getCacheableWishlists.js';
import fulfill from '../../helpers/promise/fulfill.js';
import Dialog from '../../components/dialog/index.js';
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import Overlay from '../overlay/index.js';

export default class extends Dialog {
  prid = null;
  catalog = null;
  magnificPopup;
  #renderChoices;
  #renderUnauthenticated;
  #renderComplete;
  #renderItems;
  #container;
  #updateWishlists;

  /**
   * @abstract
   * @param {object} param0
   * @param {Element} param0.el
   * @param {function(object):string} [param0.renderChoices]
   * @param {function(object):string} [param0.renderUnauthenticated]
   * @param {function(object):string} [param0.renderComplete]
   * @param {function({wishlistItems: ({id: string, name: string, numberOfItems: number, selected: boolean})[]}): string} [param0.renderItems]
   * @param {function(Promise<void>): void} [param0.updateWishlists]
   */
  constructor({
    el,
    renderChoices = () => '',
    renderUnauthenticated = () => '',
    renderComplete = () => '',
    renderItems = () => '',
    updateWishlists = () => {},
  }) {
    super({ el });
    this.#renderChoices = renderChoices;
    this.#renderUnauthenticated = renderUnauthenticated;
    this.#renderComplete = renderComplete;
    this.#renderItems = renderItems;
    this.#updateWishlists = updateWishlists;

    this.#container = this.el.querySelector('.js-addtoWishlist-container');
    this.el.addEventListener('submit', this.#handleSubmitEvent.bind(this));
    this.magnificPopup = new MagnificPopup();
  }

  showModal(options) {
    if (!this.open) {
      this.magnificPopup.open(options);
      // we don't want to make an api request if user is not log in
      if (isUserAuthenticated) {
        this.#container.innerHTML = this.#renderChoices({});
        this.#updateModalWishlists();
      } else {
        this.#container.innerHTML = this.#renderUnauthenticated({});
      }
    }
    super.showModal();
  }

  async #handleSubmitEvent(event) {
    event.preventDefault();

    const form = event.target;

    const wishlistNameInput = form.elements.namedItem('wishlist_name');
    const wishlistName = wishlistNameInput?.value.trim();

    const loader = new Overlay({ container: this.#container, modifier: 'inside__rounded' });

    /*
    FIXME get the form submitter to check if it's for create a new wishlist or add to wishlit.
    Use 2 forms for this 2 differents tasks is not possible: IE don't support (extra form) button "form" attribute: https://caniuse.com/#feat=form-attribute
    */
    if (wishlistName) {
      loader.show();
      const { publicId } = await createWishlist(wishlistName);
      wishlistNameInput.value = '';
      const update = this.#updateModalWishlists(publicId);
      this.#updateWishlists(update); // provide a way to extends classes to know when wishlists are updated (for ex: to show a loader)
      await update;
      loader.hide();
      return;
    }

    const wishlistID = form.elements.namedItem('wishlists').value;

    // API add to wishlist, ignore response (just wait)
    await fulfill(addWishlistItem(wishlistID, this.prid, this.catalog));

    // change modal template on success
    this.#container.innerHTML = this.#renderComplete({});

    this.dispatchEvent(new CustomEvent('complete', { detail: wishlistID }));
  }

  async #updateModalWishlists(preselectedWishlistID = null) {
    const wishlists = await fulfill(getWishlists(true), []);
    const preselectedWishlistIndex = Math.max(
      wishlists.findIndex((wishlist) => wishlist.publicId === preselectedWishlistID),
      0
    ); // default to 0 (first)
    const wishlistChoices = wishlists.map(({ publicId: id, numberOfItems, name }, index) => ({
      id,
      name,
      numberOfItems,
      selected: index === preselectedWishlistIndex,
    }));

    for (const element of this.el.querySelectorAll('.js-f-wishlist-dialog-form button[type=submit]')) {
      element.disabled = wishlistChoices.length === 0;
    }
    this.el.querySelector('.js-f-wishlist-dialog-count').innerText = `(${wishlistChoices.length})`;
    // create checkboxes
    this.el.querySelector('.js-f-wishlist-dialog-list').innerHTML = this.#renderItems({
      wishlistItems: wishlistChoices,
    });
  }
}
