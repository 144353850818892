import getTabContent from '../../scripts/api/headerTab/getTabContent.js';
import translation from '../../helpers/translation/translation.js';
import isFromEscapeKey from '../../helpers/event/is-from-escape-key.js';
import iconHTML from '../../helpers/html/icon.js';
import iconCloseURL from '@fnacdarty/fnac-icons/svg/close.svg?svgref';

const FOCUSABLE_SELECTOR = 'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])';

/** Common functions and properties of all header tabs */
export default class {
  #loading = false;
  #name = null;
  #closeButton = null;
  #toggler = null;
  #firstFocusableElem = null;
  #lastFocusableElem = null;

  constructor({ el, name, loadContent }) {
    this.el =
      el || Object.assign(document.createElement('div'), { className: 'js-HeaderTabContent f-header__panel-content' });
    this.#loadContent = loadContent || this.#loadContent;
    this.#name = name;
    this.#closeButton = this.#createCloseButton();
    this.#toggler = document.activeElement;

    Object.defineProperties(this.el, {
      name: {
        get: () => this.name,
      },
    });
    this.el.addEventListener('keydown', this.#handleKeys);
    this.#closeButton.addEventListener('click', this.#handleCloseEvent);
  }

  get name() {
    return this.#name;
  }

  async load() {
    if (this.#loading) {
      return;
    }
    this.#loading = true;

    try {
      await this.#loadContent();
    } catch (error) {
      this.#renderError(error);
      reportError(error);
    } finally {
      this.#loading = false;
    }
  }

  /** Display error message */
  #renderError() {
    this.el.innerHTML = '';
    this.el.dispatchEvent(new Event('error', { bubbles: true }));
  }

  #loadContent = async () => {
    this.el.innerHTML = await getTabContent({ type: this.name });
    // a11y
    this.setFocusableContent();
  };

  setFocusableContent() {
    this.el.insertAdjacentElement('afterbegin', this.#closeButton);
    this.#closeButton.focus();
    const focusableElements = [...this.el.querySelectorAll(FOCUSABLE_SELECTOR)].filter(
      (el) => !el.hasAttribute('disabled') && !el.getAttribute('aria-hidden')
    );
    this.#firstFocusableElem = focusableElements[0];
    this.#lastFocusableElem = focusableElements.pop();
  }

  #createCloseButton = () => {
    const closeButton = document.createElement('button');
    closeButton.classList.add('f-header__panel-closeBtn');
    closeButton.setAttribute('type', 'button');
    closeButton.setAttribute('aria-label', translation('assets.javascript.magnificpopup.close'));
    closeButton.setAttribute('title', translation('assets.javascript.magnificpopup.close'));
    closeButton.innerHTML = iconHTML(iconCloseURL);
    return closeButton;
  };

  #handleKeys = (event) => {
    if (isFromEscapeKey(event)) {
      this.#handleCloseEvent();
      return;
    }

    const isTabPressed = event.key === 'Tab';
    if (!isTabPressed) {
      return;
    }

    if (event.shiftKey) {
      if (document.activeElement === this.#firstFocusableElem) {
        this.#lastFocusableElem.focus();
        event.preventDefault();
      }
    } else {
      if (document.activeElement === this.#lastFocusableElem) {
        this.#firstFocusableElem.focus();
        event.preventDefault();
      }
    }
  };

  #handleCloseEvent = () => {
    window.dispatchEvent(new CustomEvent('header-tab:close', { bubbles: true }));
    this.#toggler?.focus();
  };
}
