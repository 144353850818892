import HeaderTabContent from './HeaderTabContent.js';
import getBasketTabContent from '../../scripts/api/headerTab/getBasketTabContent.js';
import deleteCartProduct from '../../scripts/api/cart/deleteCartProduct.js';
import updateCartProduct from '../../scripts/api/cart/updateCartProduct.js';
import Overlay from '../overlay/index.js';

export default class extends HeaderTabContent {
  #items = null;
  #itemQuantities = new WeakMap();

  constructor({ el } = {}) {
    super({ el, name: 'cart', loadContent: () => this.#loadContent() });

    this.el.addEventListener('delete', this.#handleDelete.bind(this));
    this.el.addEventListener('change', this.#handleChange.bind(this));
  }

  #handleDelete(event) {
    const { prid, offer, catalog, relatedPrid } = event.detail;

    this.#cartChange(
      () =>
        deleteCartProduct([
          {
            prid,
            catalog,
            offer,
            relatedPrid,
          },
        ]) /*FIXME dispatch also event deletecartitem */
    );
  }

  #handleChange({ target, detail: { prid, offer, catalog, relatedPrid, quantity } = {} }) {
    // Wrong event
    if (prid == null) {
      return;
    }

    // The API is strange and use relative not absolute quantities.
    // You need the previous value to make the diff
    const relativeQuantity = quantity - (this.#itemQuantities.get(target) ?? quantity);
    this.#itemQuantities.set(target, quantity); // update with new value

    this.#cartChange(
      () =>
        updateCartProduct([
          {
            prid,
            offer,
            catalog,
            relatedPrid,
            quantity: relativeQuantity,
          },
        ]) /*FIXME dispatch also event cartitemchange */
    );
  }

  async #cartChange(executor = () => {}) {
    const overlayCart = new Overlay({ container: this.el, modifier: 'inside__rounded' });

    try {
      overlayCart.show();
      await executor();
      window.dispatchEvent(
        new CustomEvent('cartchange', {
          detail: {
            items: [
              /*FIXME list the full content of the cart*/
            ],
          },
        })
      );
    } finally {
      await this.#loadContent();
      overlayCart.hide();
    }
  }

  async #loadContent() {
    this.el.innerHTML = await getBasketTabContent();

    this.#items = this.el.querySelectorAll('.js-BasketList-item');

    // Commponent aka fake WebComponent aren't initialized yet, only after the next MutationObserver callback call
    queueMicrotask(() => {
      // Cache the current quantities to compute differential values
      for (const item of this.#items) {
        this.#itemQuantities.set(item, item.quantity);
      }
    });
    // a11y
    super.setFocusableContent();
  }
}
