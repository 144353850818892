/**
 * Get form action with optional submitter (see SubmitEvent.submitter)
 *
 * @param {HTMLFormElement} form
 * @param {HTMLButtonElement | HTMLInputElement} submitter
 * @returns {string}
 */
export default (form, submitter) => {
  /*
  Note: submitter.formAction default value is not submitter.form.action but submitter.baseURI see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-fs-formaction
  Note: submitter.action default value is not null but submitter.baseURI see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-fs-action
  This why we need to check the attribute instead of the property formAction itself. Also the attribute is not rebased (ie. submitter.getAttribute("formaction)==="/page" && submitter.formAction==="https://example.com/page")
  */
  if (submitter && submitter.form !== form) {
    throw new TypeError('Invalid submitter');
  }

  return submitter?.hasAttribute('formaction') ?? false ? submitter.formAction : form?.action;
};
