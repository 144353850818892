import rejectNonOkResponse from '../rejectNonOkResponse.js';
import { cartPreviewCrossSellURL } from '../../../configuration/Configuration.js';

export default async (products) => {
  const response = await rejectNonOkResponse(
    fetch(cartPreviewCrossSellURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
      body: JSON.stringify({
        GetCrossSellRequestItems: products.map(({ prid, catalog, relatedPrid, quantity, offer }) => ({
          prid: prid,
          catalog: catalog,
          masterProductID: relatedPrid,
          quantity: quantity,
          offerId: offer,
        })),
      }),
    })
  );

  return response.text();
};
