import componentRegistry from '../registry.js';
import './sub-side-nav-panel.less';
import trapFocus from '../../helpers/focus/trapFocus.js';

export default class SubSideNavPanel {
  #activePanelBloc = null;

  constructor({ el }) {
    this.el = el;
    this.closeButton = this.el.querySelector('.close');
    Object.defineProperties(this.el, {
      show: {
        value: this.show,
        configurable: true,
      },
      hide: {
        value: this.hide,
      },
      appendPanelBloc: {
        value: this.appendPanelBloc,
      },
      activeID: {
        value: this.activePanelBloc?.id,
      },
    });
  }

  /**
   * Show/Hide panel bloc
   *
   * @param {string | null} id Panel bloc
   */
  set activePanelBloc(id) {
    // hide previous bloc
    this.#activePanelBloc?.setAttribute('aria-hidden', 'true');
    this.#activePanelBloc?.classList.add('hide');
    // show active bloc
    this.#activePanelBloc = this.el.querySelector(`#${id}`);
    this.#activePanelBloc?.setAttribute('aria-hidden', 'false');
    this.#activePanelBloc?.classList.remove('hide');
  }

  get activePanelBloc() {
    return this.#activePanelBloc;
  }

  /** @param {HTMLDivElement} panelBloc */
  appendPanelBloc = (panelBloc) => {
    this.preRenderPanelBloc(panelBloc);
    this.el.appendChild(panelBloc);
  };

  /**
   * Change structure of nav panel bloc.
   *
   * @param {HTMLDivElement} panelBloc
   */
  preRenderPanelBloc(panelBloc) {
    const divContent = Object.assign(document.createElement('div'), { className: 'SubSideNavPanelBloc-contentInner' });
    const divMenu = Object.assign(document.createElement('div'), { className: 'SubSideNavPanelBloc-links' });
    const promos = panelBloc.querySelector('.autopromos');
    divMenu.append(...panelBloc.querySelectorAll('.SubSideNavPanelBloc-linksRow'));
    divContent.append(divMenu);
    if (promos) {
      divContent.append(promos);
      panelBloc.classList.add('SubSideNavPanelBloc--promos');
    }
    const panelTitle = panelBloc.querySelector('.SubSideNavPanelBloc-linksTitle');
    let quicklinks = panelBloc.querySelector('.quicklinks');
    if (!quicklinks) {
      quicklinks = Object.assign(document.createElement('div'), { className: 'quicklinks' });
    }
    panelBloc.querySelector('.SubSideNavPanelBloc-content').insertBefore(quicklinks, panelTitle);
    panelBloc.querySelector('.SubSideNavPanelBloc-content').append(divContent);
  }

  show = (id) => {
    this.activePanelBloc = id;
    this.activePanelBloc.prepend(this.closeButton);
    this.closeButton.focus();
    trapFocus(this.activePanelBloc);
  };

  hide = () => (this.activePanelBloc = null);
}

componentRegistry.define('js-SubSideNavPanel', SubSideNavPanel);
