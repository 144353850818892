/**
 * Handle the visibility of sub header when user scroll
 *
 * @param {HTMLElement} el
 */
export default ({ el }) => {
  const headerNav = el.querySelector('.js-header-nav');
  const headerSubNav = el.querySelector('.js-header-subnav');
  const subNavHeight = headerSubNav.clientHeight;

  const handleScroll = () => {
    const scrollDiff = window.scrollY;
    // handle position
    if (scrollDiff > subNavHeight) {
      headerSubNav.style.opacity = '0';
      el.style.transform = `translateY(-${subNavHeight}px)`;
      headerNav.classList.add('isBordered');
    } else {
      el.style.transform = `translateY(-${scrollDiff}px)`;
      headerSubNav.style.opacity = (0.9 - scrollDiff / subNavHeight).toFixed(1);
      headerNav.classList.remove('isBordered');
    }

    const headerHeight = el.clientHeight - (scrollDiff > subNavHeight ? subNavHeight : scrollDiff); // height header - (diff scroll)
    window.dispatchEvent(
      new CustomEvent('header-sub-nav:scroll', {
        detail: { headerHeight },
      })
    );
  };

  window.addEventListener('scroll', handleScroll);
  // bad for CLS ?
  handleScroll(); // run on page laod
  document.addEventListener('DOMContentLoaded', handleScroll);
};
