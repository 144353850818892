import { isUserAuthenticated } from '../../configuration/Configuration.js';
import getWishlists from '../../scripts/api/wishlist/getCacheableWishlists.js';
import getWishlist from '../../scripts/api/wishlist/getCacheableWishlist.js';
import deleteWishlistItem from '../../scripts/api/wishlist/deleteWishlistItem.js';
import translation from '../../helpers/translation/translation.js';
import fulfill from '../../helpers/promise/fulfill.js';
import ellipsis from '../../helpers/string/ellipsis.js';
import WishlistAssignmentRequest from '../wishlist-dialog/WishlistAssignmentRequest.js';
import findWishlistFromList from '../../scripts/api/wishlist/findWishlistFromList.js';
import componentRegistry from '../registry.js';
import getProductDataFromAttributes from '../../helpers/tracking/getProductDataFromAttributes.js';

// FIXME use component
// Handle differents styles cases.
const classNames = {
  'thumbnail-btnOpen': 'thumbnail-btnOpen--added', // Desktop: mini FA & thumbnail (search + some other pages)
  'f-toolbarButton': 'f-toolbarButton--added', // Desktop: FA
  'f-faTools__item': 'f-faTools__item--added', // Mobile: FA
};

export default class WishlistButton {
  /**
   * @type {Promise<?object> | ?object}
   * @private
   */
  #wishlist = null;

  constructor({ el } = {}) {
    this.el = el || Object.assign(document.createElement('button'), { type: 'button' });

    this.el.addEventListener('click', this.#handleClick.bind(this));
    this.el.ownerDocument.defaultView.addEventListener('wishlistschange', this.#handleWishlistsChange.bind(this));

    this.#updateState(isUserAuthenticated ? fulfill(getWishlist(this.prid, this.catalog), null) : null); // get wishlist (with cache)
  }

  get prid() {
    return this.el.dataset.prid;
  }

  get catalog() {
    return this.el.dataset.catalog;
  }

  async #handleClick(event) {
    // already handled
    if (event.defaultPrevented) {
      return;
    }

    const { prid, catalog } = this;
    // Enrich the first product with attributes (used for the tracking), skip some fields
    const items = [
      {
        ...getProductDataFromAttributes(this.el),
        offer: null,
      },
    ];
    const relatedTarget = this.el;

    // If a wishlist already exist (wait for it to proceed), remove
    const wishlist = await this.#wishlist;
    if (wishlist) {
      const beforeEvent = new CustomEvent('beforewishlistitemremove', {
        bubbles: true,
        cancelable: true,
        detail: {
          items,
          wishlist: wishlist.publicId,
          relatedTarget,
        },
      });

      // Is the before event default prevented
      if (!this.el.dispatchEvent(beforeEvent)) {
        return;
      }

      if (!(await fulfill(deleteWishlistItem(wishlist.publicId, prid, catalog), false))) {
        // End here, can't remove from wishlist
        return;
      }

      // Lets others know an item has been added to a wishlist
      this.el.dispatchEvent(
        new CustomEvent('wishlistitemremove', {
          bubbles: true,
          detail: {
            items,
            relatedTarget,
            wishlist: wishlist.publicId,
          },
        })
      );
    } else {
      // Lets others know an item has been added to a wishlist
      const beforeEvent = new CustomEvent('beforeaddwishlistitem', {
        bubbles: true,
        detail: {
          items,
          relatedTarget,
          //wishlist,// not know yet
        },
      });

      // Is the before event default prevented
      if (!this.el.dispatchEvent(beforeEvent)) {
        return;
      }

      // Request the user to choose which wishlist to add to
      let wishlist;
      try {
        wishlist = await new WishlistAssignmentRequest({
          prid,
          catalog,
        }).show();
      } catch (error) {
        reportError(error);
        // End here, can't add to wishlist
        return;
      }

      // Lets others know an item has been added to a wishlist
      this.el.dispatchEvent(
        new CustomEvent('addwishlistitem', {
          bubbles: true,
          detail: {
            items,
            wishlist,
            relatedTarget,
          },
        })
      );
    }

    // Let's everyone know wishlists change
    this.el.ownerDocument.defaultView.dispatchEvent(
      new CustomEvent('wishlistschange', {
        bubbles: true,
        detail: await fulfill(getWishlists(true), []), // get wishlists (without cache),
      })
    );
  }

  #handleWishlistsChange({ detail: wishlists }) {
    this.#updateState(findWishlistFromList(wishlists, this.prid, this.catalog));
  }

  /** @param {object | null} wishlist */
  async #updateState(wishlist = null) {
    this.#wishlist = wishlist;
    const resolvedWishlist = await wishlist;

    // Check if there is no other pending promise (set before the current one resolve)
    if (this.#wishlist !== wishlist) {
      return;
    }

    const className = Object.keys(classNames).find((className) => this.el.classList.contains(className));
    if (className) {
      this.el.classList.toggle(classNames[className], Boolean(resolvedWishlist));
    }

    this.el.title = resolvedWishlist
      ? translation('wishlist.popinheader.button.tooltipadded', ellipsis(resolvedWishlist.name, 20))
      : translation('wishlist.popinheader.button.addtowishlist');

    this.el.setAttribute('aria-label', this.el.title);
  }
}

componentRegistry.define('js-wishlist-button', WishlistButton);
