import { createWishlistURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';

export default async (name) => {
  const response = await rejectNonOkResponse(
    fetch(createWishlistURL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name }),
    })
  );

  /*
{
    "name":"test3",
    "publicId":"a96cc5dd-c72f-493a-b08b-0cccdf66bfad",
    "numberOfItems":0
}
*/

  return response.json();
};
