const escapeMap = {
  '&': '&amp;',
  '<': '&lt;',
  '"': '&quot;',
  "'": '&#39;',
};

/**
 * @param {string} value
 * @returns {string} The given value with some char transformed to HTML entities
 * @see https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#output-encoding-for-html-attribute-contexts
 */
export default function (value) {
  return String(value).replace(/&|<|"|'/g, (m) => escapeMap[m]);
}
