import componentRegistry from '../registry.js';
import GetPopin from '../get-popin/index.js';
import Cookies from 'js-cookie';
import './otherOffersPopin.less';

export default class OtherOffersPopin extends GetPopin {
  constructor({ el }) {
    const popinUrl =
      Cookies.get('SortingPopinOtherOffers') !== undefined
        ? `${el.dataset.popinUrl}&OrderId=${Cookies.get('SortingPopinOtherOffers')}`
        : el.dataset.popinUrl;
    super({
      el,
      options: {
        popinUrl,
        popinWrapinto: el.dataset.popinWrapinto,
        autoload: new URLSearchParams(location.search).has('otheroffers'),
      },
    });
  }
}

componentRegistry.define('js-otherOffersPopin', OtherOffersPopin);
