import { trackEvent } from '../../helpers/tracking/track.js';
import digitalDataProduct from '../../helpers/tracking/digitalDataProduct.js';
import { cartOneClickURL } from '../../configuration/Configuration.js';
import getAction from '../../helpers/form/getAction.js';
import getComponentID from '../../helpers/tracking/getComponentID.js';

window.addEventListener('addcartitem', ({ detail: { items, relatedTarget } }) => {
  const form = relatedTarget?.form;
  const componentID = getComponentID(relatedTarget) ?? getComponentID(form) ?? 'BuyBox';
  const action = getAction(form, relatedTarget);
  const option = action === new URL(cartOneClickURL, document.baseURI).href ? 'oneclick' : 'default'; // to check if it's oneclick, we check the action of the form with the one that should be used

  trackEvent('addcartitem', componentID, {
    store: items[0]?.store ?? null,
    item: items.map(
      ({
        prid,
        catalog,
        offer,
        quantity,
        availability,
        nature,
        eqNatures,
        sellerTypes,
        condition,
        fulfillment,
        sellerId,
        sellerName,
        sellerLocation,
        sellerType,
      }) =>
        digitalDataProduct({
          prid,
          catalog,
          offer,
          quantity,
          availability,
          nature,
          eqNatures,
          sellerTypes,
          condition,
          fulfillment,
          sellerId,
          sellerName,
          sellerLocation,
          sellerType,
        })
    ),
    option,
  });
});
