export default (value) => {
  value = value?.toLowerCase();
  switch (value) {
    case 'inline':
    case 'self':
    case 'modal':
      return value;
  }
  return null;
};
