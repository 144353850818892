import isIterable from './is-iterable.js';
import isArrayLike from './is-array-like.js';

/**
 * Get an array of string from the given object. If the given object is a string or non-iterable and non-array-like, the
 * result will be an array with the given obj as string as only one item. Note iterables must be finite.
 *
 * @param {any} obj
 * @returns {string[]}
 */
export default (obj) =>
  typeof obj !== 'string' && (isIterable(obj) || isArrayLike(obj)) ? Array.from(obj, (v) => String(v)) : [String(obj)];
