import componentRegistry from '../registry.js';
import getOneTrust from '../../helpers/vendor/getOneTrust.js';

export default class KaminoSponsored {
  constructor({ el }) {
    el.addEventListener('click', this.#onClick);
  }

  #onClick = async (event) => {
    event.preventDefault();
    (await getOneTrust()).ToggleInfoDisplay();
  };
}

componentRegistry.define('js-kamino-sponsored', KaminoSponsored);
