import getHistorySuggestionsInternal from './getHistorySuggestionsInternal.js';
import comparable from '../../../helpers/string/comparable.js';
import setHistorySuggestionsInternal from './setHistorySuggestionsInternal.js';

export default (value) => {
  const previousSuggestions = getHistorySuggestionsInternal();
  const comparableValue = comparable(value);
  const suggestions = previousSuggestions.filter((v) => comparable(v) !== comparableValue);
  setHistorySuggestionsInternal(suggestions);
  return suggestions.length !== previousSuggestions.length;
};
