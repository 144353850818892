/**
 * Get all tabbable elements contains in a root element
 *
 * @param {Element} element
 * @returns {Element[]}
 */
export default function (element) {
  // FIXME handle disabled fieldset, iframe, display: none, etc.
  // See https://allyjs.io/data-tables/focusable.html
  // See also https://stackoverflow.com/questions/1599660/which-html-elements-can-receive-focus/1600194
  // Focusable != tabbable
  // https://github.com/medialize/ally.js/blob/master/src/query/focusable.js
  return [
    ...element.querySelectorAll(
      'button:not([disabled]), a[href]:not([disabled]), input:not([type=hidden]):not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([disabled]):not([tabindex^="-"]), details:not([disabled]), summary:not(:disabled)'
    ),
  ];
}
