import WishlistDialog from './index.js';

/**
 * Request to assign item to a wishlist
 *
 * @example
 *   new WishlistAssignmentRequest({ prid: '1234', catalog: '1' }).show();
 */
export default class {
  #prid;
  #catalog;
  constructor({ prid, catalog }) {
    this.#prid = prid;
    this.#catalog = catalog;
  }
  get prid() {
    return this.#prid;
  }
  get catalog() {
    return this.#catalog;
  }
  show() {
    const modal = new WishlistDialog(); // conditional compilation between implementations
    modal.prid = this.prid;
    modal.catalog = this.catalog;

    if (IS_MOBILE) {
      document.body.appendChild(modal.el);
    }

    return new Promise((resolve, reject) => {
      modal.addEventListener('error', ({ error }) => reject(error));
      modal.addEventListener('complete', ({ detail }) => resolve(detail));
      modal.addEventListener('close', () => reject());
      try {
        modal.showModal();
      } catch (error) {
        reject(error);
      }
    });
  }
}
