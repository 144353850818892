import componentRegistry from '../../registry.js';
import Kamino from '../kamino.js';

import './index.less';

export default class KaminoTotem extends Kamino {
  constructor({ el }) {
    super({ el });

    if (this.carousel) this.#initCarousel();
  }

  #initCarousel() {
    const wrapper = this.el.querySelector('.f-kamino-totem__wrapper');

    this.carousel.classList.add('f-nCarousel--hidden');

    wrapper.addEventListener('mouseenter', () => {
      this.carousel.classList.remove('f-nCarousel--hidden');
    });
    wrapper.addEventListener('mouseleave', () => {
      this.carousel.classList.add('f-nCarousel--hidden');
    });

    this.carousel.querySelectorAll('.js-ProductBuy-add').forEach((product) => {
      product.addEventListener('click', () => {
        setTimeout(() => {
          this.carousel.classList.remove('f-nCarousel--hidden');
        }, '250');
      });
    });
  }
}

componentRegistry.define('js-kamino-totem', KaminoTotem);
