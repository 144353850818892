/**
 * Get form data with optional submitter (see SubmitEvent.submitter) TODO replace with new FormData(form, submitter) API
 * (with a polyfill)
 *
 * @param {HTMLFormElement} form
 * @param {HTMLButtonElement | HTMLInputElement} submitter
 * @returns {FormData}
 */
export default function (form, submitter = null) {
  const formData = new FormData(form);

  if (submitter && submitter.form !== form) {
    throw new TypeError('Invalid submitter');
  }

  // append submitter value (not included when generate a FormData)
  if (submitter?.name && !submitter.disabled) {
    formData.append(submitter.name, submitter.value);
  }

  return formData;
}
