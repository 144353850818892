/**
 * Parse HTML as document fragment (not as HTML document)
 *
 * @param {{}} source HTML source
 * @returns {DocumentFragment}
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentHTML
 * @example
 *   const button = parseHTML('<button type="button">Button</button>').firstElementChild;
 * @example
 *   const svg = parseHTML(
 *     '<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400"><circle cx="100" cy="100" r="50" stroke="black" stroke-width="5" fill="red" /></svg>'
 *   ).firstElementChild;
 * @example
 *   const { childElementCount } = parseHTML('<i>i</i> <b>b</b> <em>em</em> <strong>strong</strong>');
 * @example
 *   parseHTML('<tr><td></td></tr>').firstElementChild.matches('tr');
 * @example
 *   document.body.append(parseHTML('<script>alert("hello")</script>').firstElementChild); // will open a alert with "hello"
 * @example
 *   document.body.appendChild(parseHTML('<p>Paragraph 1</p> <p>Paragraph 2</p><p>Paragraph 3</p>'));
 */
export default (source) =>
  // Parse a template with createContextualFragment. This allows scripts to be activable and table elements to be parsed correctly
  document.createRange().createContextualFragment(`<template>${source}</template>`).firstElementChild.content;
