/*
Translation handling in JS

If a translation is missing a placeholder is provided
If update ~/Nav/Core/Shared/_JavaScriptConfiguration.cshtml to add new keys
 */

const translations = {};
for (const translationsElement of document.getElementsByClassName('js-translations')) {
  try {
    Object.assign(translations, JSON.parse(translationsElement.text));
  } catch (error) {
    reportError(error);
  }
}

export default (key = '', ...data) => {
  key = key.toLowerCase();
  if (Object.prototype.hasOwnProperty.call(translations, key)) {
    return translations[key].replace(/{(\d+)}/g, (match, index) => {
      const value = data[parseInt(index)];
      return value === undefined || value === null ? '' : value;
    });
  }

  return `[translation "${key}"${data.map((value) => ` "${String(value).replace(/"/g, '\\$&')}"`).join('')}]`;
};
