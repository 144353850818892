import Cookies from 'js-cookie';

/**
 *
 */
export default function () {
  const count = parseInt(Cookies.get('NBART'), 10);
  if (isNaN(count)) {
    return 0;
  }
  return count;
}
