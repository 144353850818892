import { Liquid } from 'liquidjs';
import _plugin0 from "./configuration/liquid-plugin/index.js";

const templates = new Map();

export const registerTemplate = (filepath, content) => templates.set(filepath, content);

const liquid = new Liquid({
  fs: {
    readFileSync: (filepath) => templates.get(filepath),
    readFile: (filepath) => Promise.resolve(templates.get(filepath)),
    existsSync: (filepath) => templates.has(filepath),
    exists: (filepath) => Promise.resolve(templates.has(filepath)),
    resolve: (root, filepath, ext) => root + '/' + (filepath.endsWith(ext) ? filepath : filepath + ext),
  },
  cache: Number.POSITIVE_INFINITY, // no limit
  root: ["../www","."],
  extname: ".liquid",
  relativeReference: false, // disable it or VolatilFileSystem need to implement dirname and sep methods. See https://github.com/harttle/liquidjs/blob/e401a30972c70be501d91fd999dc305ec67a15a2/src/liquid-options.ts#L183-L186
});
for (const plugin of [_plugin0]) {
  liquid.plugin(plugin);
}

export default liquid;