/**
 * A simple IntersectionObserver
 * It use the native IntersectionObserver if supported or naive fallback where all targets are always intersecting
 * Use it only if you don't care about precise intersection observing or don't care if on unsupported browsers targets always intersecting
 * If you need a real MutationObserver (care about intersections), use a polyfill like https://github.com/w3c/IntersectionObserver/blob/master/polyfill/intersection-observer.js or https://github.com/jeremenichelli/intersection-observer-polyfill/blob/master/intersection-observer-polyfill.js
 */
const defaultTimeout = 500;

// Detect native intersection observer with toString of it constructor
const nativeIntersectionObserver =
  typeof window.IntersectionObserver === 'function' &&
  Function.prototype.toString.call(IntersectionObserver).indexOf('[native code]') >= 0 &&
  window.IntersectionObserver;

// Use native mutation observer or setInterval as fallback (don't use costly polyfill)
// Support an additional param, shouldn't be a problem with the native implementation, which ignore it
export default nativeIntersectionObserver ||
  class {
    _id = null;
    _targets = []; // contains only what the user want
    _requestedTargets = []; // contains requested targets, but not already taken
    // Note: requested targets are only cleared after a call of takeRecords() (or automatically after timeout)

    constructor(callback, { root = null, rootMargin = '0%', threshold = [0] } = {}, timeout = defaultTimeout) {
      this._callback = callback;
      this.root = root;
      this.rootMargin = rootMargin;
      this.threshold = threshold;
      this._timeout = timeout;
    }

    observe(target) {
      // Search if the target is not already observed
      if (this._targets.indexOf(target) >= 0) {
        return;
      }

      this._targets.push(target);

      // Register a new requested target
      if (this._requestedTargets.indexOf(target) < 0) {
        this._requestedTargets.push(target);
      }

      // Has already a set timeout
      if (this._id !== null) {
        return;
      }

      // delayed intersection (let other scripts to accumulate requested targets via observe/unobserve)
      this._id = setTimeout(() => {
        this._id = null;
        const records = this.takeRecords();
        if (records.length === 0) {
          return;
        }
        this._callback(records, this);
      }, this._timeout);
    }

    unobserve(target) {
      // Search if the target has been wanted
      const index = this._targets.indexOf(target);

      // Not found
      if (index < 0) {
        return;
      }

      this._targets.splice(index, 1);
    }

    disconnect() {
      this._targets.length = 0;
      this._requestedTargets.length = 0;
      clearTimeout(this._id);
      this._id = null;
    }

    takeRecords() {
      const entries = [];
      const time = Date.now(); // or use performance.now()
      for (let i = 0; i < this._requestedTargets.length; i++) {
        const target = this._requestedTargets[i];
        // Ignore this requested target, because it's no more a wanted target
        if (this._targets.indexOf(target) < 0) {
          continue;
        }

        entries.push({
          target,
          isIntersecting: true,
          time,
          // TODO:
          // rootBounds: {x: ...},
          // intersectionRatio: ...,
          // intersectionRect: ...,
        });
      }
      this._requestedTargets.length = 0;
      return entries;
    }
  };
