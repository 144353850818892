/**
 * A naive ResizeObserver It use the native ResizeObserver if supported or naive fallback (for Safari iOS <= 13.3) based
 * on setInterval Use it only if you don't care about precise resize observation or don't care if on unsupported
 * browsers targets always resize If you need a real ResizeObserver (care about sizes changes), use a polyfill like
 * https://github.com/juggle/resize-observer
 */
const defaultTimeout = 150;
const entries = Object.freeze([]);

// Detect native mutation observer with toString of it constructor
const nativeResizeObserver =
  typeof window.ResizeObserver === 'function' &&
  Function.prototype.toString.call(ResizeObserver).indexOf('[native code]') >= 0 &&
  window.ResizeObserver;

// Use native mutation observer or setInterval as fallback (don't use costly polyfill)
// Support an additional param, shouldn't be a problem with the native implementation, which ignore it
export default nativeResizeObserver ||
  class {
    _id = null;

    constructor(callback, timeout = defaultTimeout) {
      this._callback = callback;
      this._timeout = timeout;
    }

    observe() {
      // Has already a set interval
      if (this._id) {
        return;
      }

      this._id = setInterval(this._callback.bind(null, entries, this), this._timeout);
    }

    unobserve() {
      // no-op
    }

    disconnect() {
      clearInterval(this._id);
      this._id = null;
    }
  };
