import componentRegistry from '../registry.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';
import Overlay from '../overlay/index.js';

/** SideNav toggler Opens the Mega Memu */
export default class SideNavToggler {
  #isMenuLoaded = false;
  constructor({ el }) {
    this.el = el;
    this.sideNavPanel = document.getElementById(this.ariaControl);

    this.overlayInside = new Overlay({
      modifier: 'inside__roundedRight',
      container: this.sideNavPanel,
    });

    this.overlay = new Overlay({
      modifier: 'menu',
      spinner: false,
      closeOnClick: true,
      disableScroll: true,
    });
    this.overlay.addEventListener('close', () => this.hideSideNavPanel());

    el.addEventListener('click', this.showSideNavPanel);
    this.sideNavPanel.addEventListener('hide', () => this.overlay.hide());
  }

  get ariaControl() {
    return this.el.getAttribute('aria-controls');
  }

  /** @param {boolean} expanded */
  set ariaExpanded(expanded) {
    this.el.setAttribute('aria-expanded', expanded);
  }

  get ariaExpanded() {
    return JSON.parse(this.el.getAttribute('aria-expanded')) === true;
  }

  /**
   * Launch an webapi call to get mega menu html element TODO replace
   * FnacDirect.Nav.WebServices.Controllers.HeaderController with an ApiController
   */
  async loadMegaMenu() {
    this.overlayInside.show();
    try {
      const { megamenuUrl } = this.el.dataset;
      const response = await rejectNonOkResponse(fetch(megamenuUrl));
      const megamenuContent = await response.text();
      this.sideNavPanel.querySelector('.js-SideNavPanel-listWrapper').insertAdjacentHTML('beforeend', megamenuContent);
      this.#isMenuLoaded = true;
    } catch (error) {
      reportError(error);
    } finally {
      this.overlayInside.hide();
    }
  }

  hideSideNavPanel() {
    this.ariaExpanded = false;
    this.el.classList.remove('hide');
    this.sideNavPanel.slideOut();
    this.el.focus();
  }

  showSideNavPanel = async (e) => {
    e.preventDefault();
    this.overlay.show();
    this.ariaExpanded = true;
    this.sideNavPanel.onLoading();
    this.el.classList.add('hide');
    if (!this.#isMenuLoaded) {
      await this.loadMegaMenu();
    }
    // if the user close the side panel before it loads, DO NOT slideIn
    if (this.ariaExpanded) {
      this.sideNavPanel.slideIn({
        activeMenuIndex: 0,
      });
    }
  };
}

componentRegistry.define('js-SideNav-toggler', SideNavToggler);
