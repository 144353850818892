import { cartAddURL } from '../../../configuration/Configuration.js';
import addToCartInternal from './addToCartInternal.js';

/** @typedef {import('../../../../components/product-buy-form/typedefs.js').CartProduct} CartProduct */

/*
{
	"MainProduct": {
		"Services": [],
		"MasterProductId": 0,
		"Referentiel": 1,
		"Prid": 11027877
	},
	"CurrentProducts": [
		{
			"ProductId": 11027877,
			"Title": "Harry Potter à l'école des sorciers",
			"ImagePath": "https://static.fnac-static.com/multimedia/Images/FR/NR/ba/d8/1d/1956026/1540-1/tsp20171002094855/Harry-Potter-a-l-ecole-des-sorciers.jpg",
			"Content": null,
			"OfferId": "00000000-0000-0000-0000-000000000000",
			"Quantity": {
				"Count": 1,
				"Max": 881,
				"HasMax": true,
				"DefaultMax": 0,
				"ClickAndCollect": false,
				"MaxClickAndCollect": 0
			},
			"Availability": {
				"HasQuantityMax": true,
				"HasLimitedQuantity": false,
				"Availability": "En Stock",
				"FormAtHome": false,
				"DematSoft": false,
				"Numerical": false,
				"IsPreOrder": false
			},
			"CrossSellRichItemChildrenViewModels": []
		}
	],
	"AllProducts": [
		{
			"Services": [],
			"MasterProductId": 0,
			"Referentiel": 1,
			"Prid": 11027877
		}
	],
	"DeletedProducts": [],
	"HasMaxItemsInBasketMessage": false,
	"OrderPipeUrl": "https://secure.fnac.fd-dev.net/Front.Fnaccom/orderpipe?pipe=pop&reset=1",
	"IsFnacPro": false,
	"ErrorMessages": []
}
*/

/**
 * Add products to cart
 *
 * @param {CartProduct[]} products Products to add
 * @param {string} url URL is different than cartAddURL
 */
export default async (products, url = cartAddURL) =>
  (await addToCartInternal({ url, headers: { Accept: 'application/json' }, products })).json();
