/**
 * Get the fields of form in tree order
 *
 * @param {HTMLFormElement} form
 * @param {HTMLButtonElement | HTMLInputElement} submitter
 * @see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#constructing-the-form-data-set
 */
export default (form, submitter = null) => {
  // "If submitter is non-null, then:"
  // "If submitter is not a submit button, then throw a TypeError."
  // "If submitter’s form owner is not form, then throw a "NotFoundError" DOMException."
  return Array.from(form.elements).filter((el) => {
    // "If any of the following are true:"
    if (
      // "field has a datalist element ancestor;"
      el.closest('datalist') != null ||
      // "field is disabled;"
      el.closest('fieldset[disabled]') != null ||
      el.disabled ||
      // "field is a button, but it is not submitter;"
      (el instanceof HTMLButtonElement && el !== submitter) ||
      // "field is an input element whose type attribute is in the Checkbox state and whose checkedness is false; or"
      // "field is an input element whose type attribute is in the Radio Button state and whose checkedness is false,"
      ((el.type === 'checkbox' || el.type === 'radio') && !el.checked)
    )
      // "then continue."
      return false;

    // "If the field element is an input element whose type attribute is in the Image Button state, then:"
    if (
      el instanceof HTMLInputElement &&
      el.type === 'image' &&
      // "If the field element is not submitter, then continue."
      el !== submitter
    )
      return false;

    // "If the field is a form-associated custom element, then perform the entry construction algorithm given field and entry list, then continue."
    // We can't support form-associated custom element here.

    // "If either the field element does not have a name attribute specified, or its name attribute's value is the empty string, then continue."
    if (el.name === '') return false;

    return true;
  });
};
