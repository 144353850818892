import './squad/index.js';
import './impulse/index.js';
import './sword/index.js';
import './billboard/index.js';
import './native/index.js';
import './totem/index.js';
import './video.js';
import './sponsored.js';
import './sponsored-popin.less';

class ThirdPartyTracker {
  _callQueue = [];
  _callbackFn;

  constructor() {}

  enqueue(element) {
    if (this._callbackFn && typeof this._callbackFn === 'function') {
      this._callbackFn(element);
    } else {
      return this._callQueue.push(element);
    }
  }

  dequeue() {
    if (this._callQueue.length > 0) {
      return this._callQueue.shift();
    }
  }

  peek() {
    return this._callQueue[this._callQueue.length - 1];
  }

  size() {
    return this._callQueue.length;
  }

  isEmpty() {
    return this._callQueue.length == 0;
  }

  clear() {
    this._callQueue = [];
  }

  addCallback(func) {
    if (typeof func != 'function') {
      throw new Error('invalid callback function');
    }
    this._callbackFn = func;
    this.processQueueCalls();
  }

  processQueueCalls() {
    const e = this._callQueue;
    this.clear();
    e.forEach((callQueue) => {
      this._callbackFn(callQueue);
    });
  }
}

window.kminoTracking = new ThirdPartyTracker();
