import rejectNonOkResponse from '../rejectNonOkResponse.js';
import { cartAddURL } from '../../../configuration/Configuration.js';

/** @typedef {import('../../../../components/product-buy-form/typedefs.js').CartProduct} CartProduct */

/**
 * Add products to cart. Internal use only, do not use directly
 *
 * @param {object} param0
 * @param {string} [param0.url]
 * @param {'error' | 'follow' | 'manual'} [param0.redirect] Warning this feature is not supported by fetch polyfill
 * @param {CartProduct[]} param0.products Products to add
 * @param {object} [param0.headers] Additional headers like X-Requested-With: XMLHttpRequest
 */
export default ({ products, url = cartAddURL, headers = {}, redirect = 'follow' }) =>
  rejectNonOkResponse(
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      redirect,
      // Override given headers with some values (set not append)
      // Use reduce instead of Object.assign because we want respect precedence with normalize and case insensitive match
      // See https://fetch.spec.whatwg.org/#concept-header-value-normalize and https://fetch.spec.whatwg.org/#header-list-contains
      headers: Object.entries({ 'Content-Type': 'application/json', 'X-Requested-With': 'XMLHttpRequest' }).reduce(
        (headers, [name, value]) => (headers.set(name, value), headers),
        new Headers(headers)
      ),
      body: JSON.stringify(
        products.map(({ prid, quantity, catalog, offer, store, relatedPrid }) => ({
          productID: prid,
          referential: catalog,
          quantity,
          offer,
          shopID: store,
          masterProductID: relatedPrid,
        }))
      ),
    })
  );
