import { cartAddURL, cartOneClickURL } from '../../configuration/Configuration.js';
import Overlay from '../overlay/index.js';
import translation from '../../helpers/translation/translation.js';
import addToCartModalContent from '../../scripts/api/cart/addToCartModalContent.js';
import CartPreviewDialog from '../cart-preview-dialog/index.js';
import componentRegistry from '../registry.js';
import parseProductDataFromValue from './parseProductDataFromValue.js';
import getTargetValue from './getTargetValue.js';
import getFormData from '../../helpers/form/getFormData.js';
import getAction from '../../helpers/form/getAction.js';
import { FNAC_OFFER } from '../../helpers/product/product.js';
import addToCart from '../../scripts/api/cart/addToCart.js';
import getProductDataFromAttributes from '../../helpers/tracking/getProductDataFromAttributes.js';
import parseHTML from '../../helpers/dom/parseHTMLFragment.js';
import renderCartPreviewDialogDarcos from './_CartPreviewDialogDarcos.liquid';
import addToCartLocation from '../../scripts/api/cart/addToCartLocation.js';
import iconHTML from '../../helpers/html/icon.js';
import iconCheckURL from '@fnacdarty/fnac-icons/svg/icon_i_102_check_03.svg?svgref';
import getCrossSellServices from '../../scripts/api/crossSell/crossSell.js';
import getFields from '../../helpers/form/getFields.js';
import number from '../../helpers/type/number.js';

/** @typedef {import('./typedefs.js').CartProduct} CartProduct */

/**
 * Before add cart item event This event is cancellable
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CartProduct[]} detail.items Products to add to cart
 * @event beforeaddcartitem
 */

/**
 * Cart add item event This event is not cancellable
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CartProduct[]} detail.items Products added to cart
 * @event addcartitem
 */

/**
 * Cart change event This event is not cancellable
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CartProduct[]} detail.items Products in the cart
 * @event cartchange
 */

/**
 * Error add cart item This event is not cancellable
 *
 * @type {CustomEvent}
 * @property {object} detail
 * @property {CartProduct[]} detail.items Products to add to cart
 * @event error
 */

/**
 * Component to add product(s) to cart `data-target` define behavior:
 *
 * - `modal` (default): open a modal with the cart content (or partial content)
 * - `self`: navigate to destination (action)
 * - `inline`: update buttons only ("added to cart") submitter `data-formtarget` override form target (see data-target)
 *
 * @fires addcartitem
 * @fires beforeaddcartitem
 * @fires cartchange
 * @fires error
 * @example
 *   ```html
 *     <form class="js-ProductBuy" action="/basket/add" method="post">
 *       <input type="hidden" name="products" value="10337739-19742a70-1efd-8c09-f550-e282e75ad46d" data-prid="10337739" data-offer="19742a70-1efd-8c09-f550-e282e75ad46d">
 *       <input type="checkbox" name="products" value"10337739(5516265)" data-prid="5516265" data-relatedprid="10337739" id="somewarranty"><label for="somewarranty">Some warranty</label>
 *       <button>Add to cart</button>
 *       <label for="quantity">Quantity (1-100):</label><input type="number" name="products[10337739-19742a70-1efd-8c09-f550-e282e75ad46d].Quantity" min="1" max="100" value="1" id="quantity">
 *       <button name="shopid" value="17">Add to cart for pickup at Fnac Paris Forum des Halles</button>
 *       <button name="products" value"10337739(5516265)" data-prid="5516265" data-relatedprid="10337739">Add to cart with warranty</button>
 *       <!-- fields name="products[{id}].Order" are used to order products -->
 *     </form>
 *     <button form="someform" action="/basket/oneclick" data-formtarget="self">Add to cart one click (+ remote)</button>
 *   ```;
 */
export default class ProductBuyForm {
  constructor({ el }) {
    this.el = el;
    this.el.addEventListener('submit', this.#handleSubmit.bind(this));
  }

  /**
   * @param {SubmitEvent} event
   * @returns {Promise<void>}
   */
  async #handleSubmit(event) {
    const { submitter } = event;
    const items = this.#getProducts(submitter);

    const beforeEvent = new CustomEvent('beforeaddcartitem', {
      bubbles: true,
      cancelable: true,
      detail: { items },
    });

    // Is the before event default prevented
    if (!this.el.dispatchEvent(beforeEvent)) {
      event.preventDefault(); // prevent default action of form submit
      return;
    }

    // Get target and action values from submitter or the form itself or default
    const target = getTargetValue(submitter?.dataset.formtarget) ?? getTargetValue(this.el.dataset.target) ?? 'modal';
    // /!\ button.formAction default value is not button.form.action but button.baseURI see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-fs-formaction
    // /!\ button.action default value is not null but button.baseURI see https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#dom-fs-action
    // This why we need to check the attribute instead of the property formAction itself. Also the attribute not rebase (ie. submitter.getAttribute("formaction)==="/page" && submitter.formAction==="https://example.com/page")
    const action = getAction(this.el, submitter);

    // There are different ways to show to the user how the product has been added to cart:
    try {
      switch (target) {
        case 'inline':
          await this.#submitInline(items, event, action);
          return;
        case 'self':
          await this.#submitSelf(items, event, action);
          return;
        case 'modal':
        default:
          await this.#submitModal(items, event, action);
          return;
      }
    } catch {
      this.el.dispatchEvent(
        new CustomEvent('error', {
          bubbles: true,
          detail: { items },
        })
      );
    }
  }

  async #submitInline(products, event, action) {
    const { submitter } = event;
    event.preventDefault();

    // Update the button immediately, no need to wait the network
    const { className, innerHTML, disabled } = submitter; // save current state
    // TODO buttons (as components) should update themself instead, by listing addcartitem or cartchange events
    //submitter.disable = true;// FIXME the style is disabled too
    // case cart modal warranty
    if (submitter.classList.contains('f-basketPopin-basketAdd')) {
      submitter.classList.add('f-basketPopin-basketAdd--added');
    }
    // default buy button
    else if (submitter.classList.contains('ff-button')) {
      submitter.classList.remove('ff-button--orange', 'ff-button--blue' /*, 'ff-button--green'*/);
      submitter.classList.add('ff-button--green');
    }

    submitter.innerHTML = `${iconHTML(iconCheckURL, {
      className: 'ff-button-icon',
    })}<span class="ff-button-label">${translation('front.core.views.product.fa.webbuybox.basket.addedtocart')}</span>`;

    try {
      await addToCart(products, action);
      this.#dispatchCartChange(products, submitter);
    } catch (error) {
      // In case of error (like network) restore the previous state
      submitter.className = className;
      submitter.innerHTML = innerHTML;
      submitter.disabled = disabled;
      throw error;
    }
  }

  async #submitSelf(products, event, action) {
    await TMP_fixAPIInconsistencies(action, products, event);

    if (action === cartAddURL || action === cartOneClickURL) {
      //this quick fix exclude "addcartitem" for StoreLocatorWithAvailability
      this.#dispatchCartChange(products, event.submitter);
    }
  }

  async #submitModal(products, event, action) {
    event.preventDefault();

    const loader = new Overlay({ modifier: IS_MOBILE ? 'cartPreview' : 'all', disableScroll: IS_MOBILE });
    loader.show();

    try {
      const [data, crossSell] = await Promise.all([
        addToCartModalContent(products, action),
        getCrossSellServices(products),
      ]);

      const dialogContent = parseHTML(crossSell);
      const darcosElement = dialogContent.querySelector('.js-BasketPopin-Darcos');

      if (darcosElement) {
        darcosElement.innerHTML = renderCartPreviewDialogDarcos({
          BookDeliveryPush: data.BookDeliveryPush,
        });
      }

      this.#dispatchCartChange(products, event.submitter);
      this.#openDialogWithContent(dialogContent.firstElementChild);
    } catch (e) {
      reportError(e);
    } finally {
      loader.hide();
    }
  }

  /**
   * @param {CartProduct[]} items
   * @param {Element} relatedTarget The element that initiate the cart change
   */
  #dispatchCartChange(items, relatedTarget = null) {
    this.el.dispatchEvent(
      new CustomEvent('addcartitem', {
        bubbles: true,
        detail: { items, relatedTarget },
      })
    );

    // Let's everyone know cart change
    window.dispatchEvent(
      new CustomEvent('cartchange', {
        detail: { items: [] /*TODO list the content of the cart await getCart().items*/, relatedTarget },
      })
    );
  }

  /**
   * Build and open the pop-in basket.
   *
   * @param {Node} el
   */
  #openDialogWithContent(el) {
    // FIXME this should be auto instantiable component
    const modal = new CartPreviewDialog({ el });

    if (IS_MOBILE) {
      // TMP fix remove old modals
      document.querySelector('.js-basketModal')?.remove();

      document.body.appendChild(modal.el);
    }

    modal.showModal();
  }

  /**
   * Get products object from the form and optional submitter Used for dispatched events/datalayer or some custom
   * actions
   *
   * @param {HTMLButtonElement | HTMLInputElement} submitter
   * @returns {CartProduct[]}
   */
  #getProducts(submitter = null) {
    const formData = getFormData(this.el, submitter);
    // should be named "storeid", but CrossSellBasketController name it differently (and consequently CommunityController StoreLocatorWithAvailability)
    // same store for all products
    const store = formData.get('shopid');

    return getFields(this.el, submitter)
      .filter((el) => el.name === 'products')
      .map((element) => ({
        order: number(parseInt(formData.get(`products[${element.value}].Order`), 10), Number.POSITIVE_INFINITY),
        element,
      }))
      .sort((a, b) => a.order - b.order)
      .map(({ element }) => ({
        store,
        quantity: number(parseInt(formData.get(`products[${element.value}].Quantity`), 10), 1), // each products can its own quantity defined by a field named "product[{id}].Quantity" (where "id" is the product identifier like "PRID"/"PRID-OFFER"/"MASTERPRID(PRID)")
        ...getProductDataFromAttributes(element),
        relatedPrid: element.dataset.relatedprid,

        // Parse product data from field value until the (mobile) controller CommunityController.StoreLocatorWithAvailability() provide a view model with data to render products attributes to inputs. See _StoreLocatorArticleAvailability.cshtml
        ...parseProductDataFromValue(element.value),
      }));
  }
}

// FIXME TMP until an API (Orderpipe CrossSellBasketController) support POST form data, multi params with same name + quantity param + store ID
/**
 * @param {string} action
 * @param {CartProduct[]} products
 * @param {SubmitEvent} event
 * @returns {Promise<void>}
 */
async function TMP_fixAPIInconsistencies(action, products, event) {
  // Fix the fact that cart API don't support all parameters the form need to provide. The only way to do that is to use JSON (that HTML form can't support)
  // CrossSellBasketController support:
  // - products=1234&products=1234(2345)&products=1234-ce6bf99b-1620-ca69-072a-f286b3f4ef08&shopid=250 but not quantity
  // - productid=2345&masterproductid=1234&offer=ce6bf99b-1620-ca69-072a-f286b3f4ef08&quantity=2&shopid=250 but not multiple products nor a single product identifier (eg. PRID, master(PRID) or PRID-offer)
  // - productid[0]=1234&quantity[0]=2 but no store ID nor single product identifier
  // - JSON POST (but can't be used with an native HTML form)
  // We need something like POST application/x-www-form-urlencoded or GET:
  // products.index=1234&products[1234].Quantity=2&products.index=1234(2345)&products.index=3456&storeid=250 or products=1234&products[1234].Quantity=2&products=1234(2345)&products=3456&storeid=250 that allow to define single product identifier, quantity and store id.
  // This way is supported by ASP.NET Core, but could be supported to ASP.NET
  // That use the JQueryQueryStringValueProvider and JQueryFormValueProvider syntax. See https://github.com/dotnet/aspnetcore/blob/c2cb6009fe07dfb139353bca3e965ed68fc94bd2/src/Mvc/Mvc.Core/src/ModelBinding/Binders/CollectionModelBinder.cs#L519 and https://github.com/dotnet/aspnetcore/blob/main/src/Mvc/test/Mvc.IntegrationTests/DictionaryModelBinderIntegrationTest.cs
  // See also https://fnacdarty.atlassian.net/wiki/spaces/FnacFrontEndDocumentation/pages/30409092/Charte+front-end+back-end#Donn%C3%A9es-et-APIs "supporter l'envoi de tableau en query string"
  if (action === cartAddURL) {
    event.preventDefault();
    //window.location = await addToCartLocation(action, formData);
    // Format URL for GET instead of POST
    const url = new URL(action, document.baseURI);
    const [{ store } = {}] = products; // since all products have the same store get if from the first product define the store to pickup from
    url.search = new URLSearchParams({
      // Note this format don't handle quantity
      products: products
        .map(({ prid, offer, relatedPrid }) =>
          offer && offer !== FNAC_OFFER ? `${prid}-${offer}` : relatedPrid ? `${relatedPrid}(${prid})` : prid
        )
        .join(','),
      // shopid is optional
      ...(store != null && {
        shopid: store,
      }),
    });
    window.location = url.href;
    return;
  }

  // Fix the fact that cartOneClickURL work exactly like cartAddURL, but with one exception: when the user go to the onepage he see only the added product (instead of the whole cart content)
  // See ComputeAddResult in CrossSellBasketController and OneClickBasketController
  // need to call cartAddURL first then got to oneclick/onepage page
  if (action === cartOneClickURL) {
    event.preventDefault();
    window.location = await addToCartLocation(products, action);
  }
}

componentRegistry.define('js-ProductBuy', ProductBuyForm);
