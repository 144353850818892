import componentRegistry from '../registry.js';

export default class BasketTabItem {
  constructor({ el }) {
    this.el = el;
    this.el.querySelector('.js-BasketList-delete')?.addEventListener('click', this._handleDeleteClick.bind(this));
    this.el
      .querySelector('.js-BasketList-decrease')
      ?.addEventListener('click', this._handleDecreaseQuantityClick.bind(this));
    this.el
      .querySelector('.js-BasketList-increase')
      ?.addEventListener('click', this._handleIncreaseQuantityClick.bind(this));
    this._quantityInput = this.el.querySelector('.js-BasketList-quantity');

    // Until this will be a WebComponent (this instanceof HTMLElement) we need to bridge some properties between this component and it's DOM element:
    Object.defineProperties(this.el, {
      // TODO for others
      quantity: {
        get: () => this.quantity,
      },
    });

    // TMP for compatibility, can be remove after 01/05/2021
    // Some attributes are renamed to be more "standarized"
    if (this.el.dataset.prid == null) {
      this.el.setAttribute('data-prid', this.el.getAttribute('data-product-id'));
      this.el.setAttribute('data-offer', this.el.getAttribute('data-offer-id'));
      this.el.setAttribute('data-related-prid', this.el.getAttribute('data-master-product-id'));
      this.el.setAttribute('data-max', this.el.getAttribute('data-quantity-max'));
    }
  }

  get prid() {
    return this.el.dataset.prid;
  }

  get catalog() {
    return this.el.dataset.catalog;
  }

  get offer() {
    return this.el.dataset.offer;
  }

  get relatedPrid() {
    return this.el.dataset.relatedPrid;
  }

  get quantity() {
    //return this._quantityInput?.valueAsNumber ?? NaN;
    return parseInt(this._quantityInput?.value, 10); // TODO add that data if not quantity input
  }

  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#max
  get max() {
    return this.el.dataset.max ? parseInt(this.el.dataset.max, 10) : Number.POSITIVE_INFINITY;
  }

  async _handleDeleteClick(event) {
    this._delete();
  }

  _handleDecreaseQuantityClick(event) {
    this._setQuantity(this.quantity - 1);
  }

  _handleIncreaseQuantityClick(event) {
    this._setQuantity(this.quantity + 1);
  }

  _delete() {
    this.el.dispatchEvent(
      new CustomEvent('delete', {
        bubbles: true,
        detail: { prid: this.prid, catalog: this.catalog, offer: this.offer, relatedPrid: this.relatedPrid },
      })
    );
  }

  _setQuantity(value) {
    value = Math.min(Math.max(value, 0), this.max);

    // If the new quantity is lower than 1, let's delete this item
    if (value <= 0) {
      this._delete();
      return;
    }

    // If we reached the maximum quantity allowed for this item
    if (value > this.max) {
      return;
    }

    this.el.dispatchEvent(
      new CustomEvent('change', {
        bubbles: true,
        detail: {
          prid: this.prid,
          catalog: this.catalog,
          offer: this.offer,
          relatedPrid: this.relatedPrid,
          quantity: value,
        },
      })
    );
  }
}

componentRegistry.define('js-BasketList-item', BasketTabItem);
