/**
 * Function to check if the user clicked on space bar or enter keys
 *
 * @param {KeyboardEvent} event
 * @param {string} event.key
 * @returns {boolean}
 */
export default ({ key }) => {
  return ['Space', ' ', 'Enter'].includes(key);
};
