import { headerTabTemplateURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';
/**
 * @param {object} params
 * @param {'shipping' | 'store-gen' | 'store-fav' | 'account-connected'} params.type
 * @returns {Promise<any>}
 */
export default async (params) => {
  const response = await rejectNonOkResponse(fetch(headerTabTemplateURL(params)));

  const { html } = await response.json();
  return html;
};
