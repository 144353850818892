import componentRegistry from '../registry.js';
import { isMobile } from '../../configuration/Configuration.js';
import getPopinContent from '../../scripts/api/popins/getPopinContent.js';
// TODO: replace MagnificPopup by native light dialog component
import MagnificPopup from '../../scripts/common/dynamic-imports/MagnificPopup.js';
import Overlay from '../overlay/index.js';

/** @typedef {import('./typedefs.js').PopinOptions} PopinOptions */
/** @typedef {import('./typedefs.js').NormalizedPopinOptions} NormalizedPopinOptions */

const DEFAULT_POPIN_PARAMS = {
  mainClass: `mfp--modal${isMobile ? ' mfp--mobile' : ''}`,
  midClick: true,
  fixedContentPos: true,
};

/**
 * @param {HTMLElement} el DOM element
 * @param {PopinOptions} options
 * @example
 *   HTML Usage:
 *   <button type="button" class="js-GetPopin" data-src="https://www.example.com">
 *   <button type="button" class="js-GetPopin" data-for="popinId"><!-- for <div id="popinId"></div> -->
 *   <button type="button" class="js-GetPopin" data-autoload data-for="someid">
 *
 *   Programmatically:
 *   const newPopin = new GetPopin({el, options: {src: "https://www.example.com"}});
 *
 *   or
 *   export default class MyPopinClassName extends GetPopin {
 *   constructor({ el }) {
 *   super({ el, options: { src: "https://www.example.com" } });
 *   }
 *   }
 */
export default class GetPopin {
  /** @type {NormalizedPopinOptions} */
  #options;
  #magnificPopup;

  constructor({ el, options }) {
    this.el = el;
    const {
      src,
      content,
      for: element,
      wrapper,
      autoload,
      padding,
      open,
      beforeClose,
      beforeOpen,
    } = {
      padding: true,
      ...this.el.dataset,
      autoload: 'autoload' in this.el.dataset,
      ...Object(options),
    };
    const data = (element ? `#${element}` : null) || content;

    if (!src && !data) {
      throw new TypeError('No content options provided.');
    }

    this.#options = {
      src,
      data,
      wrapper,
      autoload,
      padding,
      open,
      beforeClose,
      beforeOpen,
    };

    this.#magnificPopup = new MagnificPopup();

    if (this.#options.autoload) {
      this.#handler();
    }

    el.addEventListener('click', (e) => {
      e.preventDefault();
      this.#handler();
    });
  }

  #handler() {
    const { src, data } = this.#options;

    if (src) {
      this.#fetchPopin(src);
    } else if (data) {
      this.#openPopin(data);
    } else {
      throw new TypeError('No option provided.');
    }
  }

  /**
   * Fetch popin
   *
   * @param {string} url URL string
   */
  async #fetchPopin(url) {
    if (!url) return;

    const loader = new Overlay({ modifier: 'all' });
    loader.show();

    try {
      const response = await getPopinContent(url);
      this.#openPopin(response);
    } catch (error) {
      reportError(error);
    } finally {
      loader.hide();
    }
  }

  /**
   * Open popin
   *
   * @param {string} data CSS selector or HTML blob of the content to display
   */
  #openPopin(data) {
    if (!data) return;

    const { wrapper, padding, open, beforeClose, beforeOpen } = this.#options;

    this.#magnificPopup.open({
      ...DEFAULT_POPIN_PARAMS,
      items: {
        src: wrapper ? `<div class="ff-modal ${wrapper}">${data}</div>` : data,
        type: 'inline',
      },
      callbacks: {
        beforeOpen: () => beforeOpen?.({ el: this.el }),
        open: () => {
          this.el.dispatchEvent(
            new CustomEvent('popin:open', {
              bubbles: true,
            })
          );
          const popinContent = document.querySelector('.mfp-content').firstChild;
          popinContent.classList.add('ff-modal');
          if (!padding) popinContent.classList.add('ff-modal--nopadding');
          open?.({ instance: this, el: this.el, popin: this.#magnificPopup.instance.currItem.inlineElement[0] });
        },
        beforeClose: () =>
          beforeClose?.({ el: this.el, popin: this.#magnificPopup.instance.currItem.inlineElement[0] }),
        close: () => {
          this.el.dispatchEvent(
            new CustomEvent('popin:close', {
              bubbles: true,
            })
          );
        },
      },
    });
  }

  /** Close popin */
  closePopin = () => {
    this.#magnificPopup.close();
  };
}

componentRegistry.define('js-GetPopin', GetPopin);
