/**
 * Get the closest component identifier of an DOM element data-componentid or data-component-id (former)
 *
 * @param {Element} element
 * @returns {string | null}
 */
export default (element) => {
  const closest = element?.closest('[data-componentid], [data-component-id]');
  return closest ? closest.getAttribute('data-componentid') ?? closest.getAttribute('data-component-id') : null;
};
