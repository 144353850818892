import { trackEvent } from '../../helpers/tracking/track.js';
import digitalDataProduct from '../../helpers/tracking/digitalDataProduct.js';
import getComponentID from '../../helpers/tracking/getComponentID.js';

window.addEventListener('addwishlistitem', ({ detail: { items, wishlist, relatedTarget } }) =>
  trackEvent('addwishlistitem', getComponentID(relatedTarget) ?? 'Toolbar', {
    item: items.map(({ prid, catalog, offer, quantity, availability, nature, eqNatures, sellerTypes }) =>
      digitalDataProduct({ prid, catalog, offer, quantity, availability, nature, eqNatures, sellerTypes })
    ),
    wishlist,
  })
);
