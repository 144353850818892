export const TOUCHY_EVENTS = {
  PINCH: 'pinch',
  PINCHIN: 'pinchin',
  PINCHOUT: 'pinchout',
  PAN: 'pan',
  PANEND: 'panend',
  PANSTART: 'panstart',
  SWIPELEFT: 'swipeleft',
  SWIPERIGHT: 'swiperight',
  SWIPETOP: 'swipetop',
  SWIPEDOWN: 'swipedown',
  TAP: 'tap',
  DOUBLETAP: 'doubletap',
  POINTERDOWN: 'POINTER_DOWN',
};

const DEFAULT_OPTIONS = {
  pinchThreshold: 120,
  swipeThreshold: 50,
  touchAction: 'none',
};

/**
 * Touchy Detects gestures and dispatches events accordingly
 *
 * @class
 * @param {object} el The DOM element
 * @param {object} options
 */
class Touchy {
  constructor({ el, options = {} }) {
    this.el = el;
    const { pinchThreshold, swipeThreshold, touchAction } = { ...DEFAULT_OPTIONS, ...options };
    this.el.style.touchAction = touchAction;
    this.pointersLog = [];
    this.singleTapTimer = undefined;
    this.pointer = {
      startX: 0,
      startY: 0,
    };
    this.pinch = {
      diffCache: {
        deltaX: 0,
        deltaY: 0,
      },
      sentEvent: {
        [TOUCHY_EVENTS.PINCHIN]: false,
        [TOUCHY_EVENTS.PINCHOUT]: false,
      },
      threshold: pinchThreshold,
      thresholdGap: 10,
    };
    this.swipe = {
      threshold: swipeThreshold,
      thresholdGap: 10,
      sentEvent: {
        [TOUCHY_EVENTS.SWIPELEFT]: false,
        [TOUCHY_EVENTS.SWIPERIGHT]: false,
        [TOUCHY_EVENTS.SWIPETOP]: false,
        [TOUCHY_EVENTS.SWIPEDOWN]: false,
      },
    };
    this.tap = {
      count: 0,
      singleTapDelay: 300,
    };
    this.panCount = 0;

    this.el.onpointerdown = this.pointerDown.bind(this);
    this.el.onpointermove = this.pointerMove.bind(this);
    this.el.onpointerup = this.pointerUp.bind(this);
    document.onpointerup = this.flushAll.bind(this);
  }

  /**
   * Adds event listeners
   *
   * @param {string} eventNames Inline event names (e.g. 'tap swiperight swipeleft')
   * @param {Function} callback
   */
  on(eventNames, callback) {
    eventNames.split(' ').forEach((e) => {
      this.el.addEventListener(e, callback, false);
    });
  }

  /**
   * PointerDown event handler
   *
   * @param {object} e Event object
   */
  pointerDown(e) {
    this.pointersLog.push(e);
    this.sendEvent(TOUCHY_EVENTS.POINTERDOWN, e);

    let deltaX, deltaY;

    switch (this.pointersLog.length) {
      // Single touch
      case 1:
        this.tap.count++;
        this.pointer.startX = e.clientX;
        this.pointer.startY = e.clientY;
        break;

      // Two fingers
      case 2:
        this.tap.count = 0;
        ({ deltaX, deltaY } = this.getTowFingersDeltas());

        // Cache the distance for the next move event
        this.pinch.diffCache = {
          deltaX,
          deltaY,
        };
        break;
    }
    e.preventDefault();
  }

  /**
   * PointerUp event handler
   *
   * @param {object} e Event object
   */
  pointerUp(e) {
    this.sendEvent(TOUCHY_EVENTS.PANEND, e, {
      deltaX: e.clientX - this.pointer.startX,
      deltaY: e.clientY - this.pointer.startY,
    });

    // Single touch
    if (this.pointersLog.length === 1) {
      switch (this.tap.count) {
        case 1:
          this.singleTapTimer = window.setTimeout(() => {
            this.tap.count = 0;
            this.sendEvent(TOUCHY_EVENTS.TAP, e);
          }, this.tap.singleTapDelay);
          break;
        case 2:
          window.clearTimeout(this.singleTapTimer);
          this.tap.count = 0;
          this.sendEvent(TOUCHY_EVENTS.DOUBLETAP, e);
          break;
      }
    }

    // Removes pointers from cache
    this.flushAll();

    e.preventDefault();
  }

  /**
   * PointerMove event handler
   *
   * @param {object} e Event object
   */
  pointerMove(e) {
    // Find this event in the cache and update its record with this event
    this.pointersLog = [...this.pointersLog.map((log) => (log.pointerId === e.pointerId ? e : log))];

    let deltaX, deltaY;
    switch (this.pointersLog.length) {
      // Pan / Swipe
      case 1:
        // Prevents PANSTART event to be dispatched when 2 fingers touch is made
        if (this.panCount === 1) {
          this.sendEvent(TOUCHY_EVENTS.PANSTART, e);
        }
        this.panCount++;

        deltaX = e.clientX - this.pointer.startX;
        deltaY = e.clientY - this.pointer.startY;

        this.sendEvent('pan', e, {
          deltaX,
          deltaY,
        });

        deltaX < 0
          ? this.handleGesture(this.swipe, deltaX, TOUCHY_EVENTS.SWIPELEFT, e)
          : this.handleGesture(this.swipe, deltaX, TOUCHY_EVENTS.SWIPERIGHT, e);
        deltaY < 0
          ? this.handleGesture(this.swipe, deltaY, TOUCHY_EVENTS.SWIPETOP, e)
          : this.handleGesture(this.swipe, deltaY, TOUCHY_EVENTS.SWIPEDOWN, e);
        break;

      // Pinch (2 fingers)
      case 2:
        ({ deltaX, deltaY } = this.getTowFingersDeltas());

        this.sendEvent(TOUCHY_EVENTS.PINCH, e, {
          deltaX,
          deltaY,
        });

        deltaX > this.pinch.diffCache.deltaX
          ? this.handleGesture(this.pinch, deltaX, TOUCHY_EVENTS.PINCHOUT, e)
          : this.handleGesture(this.pinch, deltaX, TOUCHY_EVENTS.PINCHIN, e);
        deltaY > this.pinch.diffCache.deltaY
          ? this.handleGesture(this.pinch, deltaY, TOUCHY_EVENTS.PINCHOUT, e, true)
          : this.handleGesture(this.pinch, deltaY, TOUCHY_EVENTS.PINCHIN, e, true);
        break;
    }

    e.preventDefault();
  }

  /**
   * Triggers gesture event if delta value is beyond threshold value
   *
   * @param {object} gesture Gesture params object
   * @param {number} delta Delta between pointers
   * @param {string} eventName
   * @param {object} e Original event
   * @param {boolean} isVertical Gesture direction
   */
  handleGesture(gesture, delta, eventName, e, isVertical) {
    if (Math.abs(delta) > gesture.threshold && !gesture.sentEvent[eventName]) {
      gesture.sentEvent[eventName] = true;

      // Get pinch middle point
      const data = gesture === this.pinch ? this.getTowFingersMiddlePoint(delta, isVertical) : {};

      this.sendEvent(eventName, e, data);
    }
  }

  /**
   * Returns the middle point coordinates (point between the two fingers)
   *
   * @param {number} delta
   * @param {boolean} isVertical
   * @returns {{ pinchOffsetX: number; pinchOffsetY: number }} Point x & y values
   */
  getTowFingersMiddlePoint(delta, isVertical) {
    const direction = isVertical ? 'offsetY' : 'offsetX';
    const higherPointer = Math.max(this.pointersLog[0][direction], this.pointersLog[1][direction]);
    return {
      [isVertical ? 'pinchOffsetY' : 'pinchOffsetX']: higherPointer - delta / 2,
    };
  }

  /**
   * Returns the distance between the two fingers
   *
   * @returns {{ deltaX: number; deltaY: number }} Horizontal & vertical deltas
   */
  getTowFingersDeltas() {
    return {
      deltaX: Math.abs(this.pointersLog[0].clientX - this.pointersLog[1].clientX),
      deltaY: Math.abs(this.pointersLog[0].clientY - this.pointersLog[1].clientY),
    };
  }

  /** Reset pointers settings from cache */
  flushAll() {
    if (!this.pointersLog.length) return;

    // Reset gesture booleans
    [this.pinch, this.swipe].forEach((g) => {
      const sent = g.sentEvent;
      Object.keys(sent).forEach((key) => {
        sent[key] = false;
      });
    });

    // Reset pan count
    this.panCount = 0;

    // Flush pointers
    this.pointersLog = [];
  }

  /**
   * Dispatches event
   *
   * @param {string} eventName
   * @param {object} e Original event
   * @param {number} extraData Extra data from event handler
   */
  sendEvent(eventName, e, extraData = {}) {
    this.el.dispatchEvent(
      new TouchyEvent(eventName, {
        clientX: e.clientX,
        clientY: e.clientY,
        offsetX: e.offsetX,
        offsetY: e.offsetY,
        pageX: e.pageX,
        pageY: e.pageY,
        screenX: e.screenX,
        screenY: e.screenY,
        x: e.x,
        y: e.x,
        ...extraData,
      })
    );
  }
}

/** Custom touchy event */
class TouchyEvent extends Event {
  #clientX;
  #clientY;
  #offsetX;
  #offsetY;
  #pageX;
  #pageY;
  #screenX;
  #screenY;
  #x;
  #y;
  #deltaX;
  #deltaY;
  #pinchOffsetX;
  #pinchOffsetY;

  constructor(
    type,
    {
      bubbles = false,
      cancelable = false,
      clientX,
      clientY,
      offsetX,
      offsetY,
      pageX,
      pageY,
      screenX,
      screenY,
      x,
      y,
      deltaX,
      deltaY,
      pinchOffsetX,
      pinchOffsetY,
    }
  ) {
    super(type, { bubbles, cancelable });

    this.#clientX = clientX;
    this.#clientY = clientY;
    this.#offsetX = offsetX;
    this.#offsetY = offsetY;
    this.#pageX = pageX;
    this.#pageY = pageY;
    this.#screenX = screenX;
    this.#screenY = screenY;
    this.#x = x;
    this.#y = y;
    this.#deltaX = deltaX;
    this.#deltaY = deltaY;
    this.#pinchOffsetX = pinchOffsetX;
    this.#pinchOffsetY = pinchOffsetY;
  }

  get clientX() {
    return this.#clientX;
  }
  get clientY() {
    return this.#clientY;
  }
  get offsetX() {
    return this.#offsetX;
  }
  get offsetY() {
    return this.#offsetY;
  }
  get pageX() {
    return this.#pageX;
  }
  get pageY() {
    return this.#pageY;
  }
  get screenX() {
    return this.#screenX;
  }
  get screenY() {
    return this.#screenY;
  }
  get x() {
    return this.#x;
  }
  get y() {
    return this.#y;
  }
  get deltaX() {
    return this.#deltaX;
  }
  get deltaY() {
    return this.#deltaY;
  }
  get pinchOffsetX() {
    return this.#pinchOffsetX;
  }
  get pinchOffsetY() {
    return this.#pinchOffsetY;
  }
}

export default Touchy;
