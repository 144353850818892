import comparable from '../../../helpers/string/comparable.js';
import getHistorySuggestionsInternal from './getHistorySuggestionsInternal.js';
import getSuggestionsInternal from './getSuggestionsInternal.js';
import escape from '../../../helpers/html/escape.js';
import removeTags from '../../../helpers/html/remove-tags.js';

export default async (value, { historyCount = 2, count = 7 } = {}) => {
  const comparableValue = comparable(value);
  let history =
    historyCount > 0 ? getHistorySuggestionsInternal().filter((v) => comparable(v).includes(comparableValue)) : [];
  let suggestions = [];

  // Add non-history suggestions too
  if (historyCount !== count) {
    const result = await getSuggestionsInternal(value, { suggestions: count });

    suggestions = (result?.s ?? []).map(({ ct }) => ct);

    // Limit history entries to let enough room for suggestions
    history = history.slice(0, Math.max(count - suggestions.length, historyCount));

    // Remove suggestions that have same normalization as history ones, aka "duplicates"
    const normalizedHistory = new Set(history.map((v) => comparable(v)));
    suggestions = suggestions.filter((v) => !normalizedHistory.has(comparable(removeTags(v))));
  }

  return [
    ...history.map((v) => ({
      type: 'history',
      value: v,
      title: value
        ? v
            .split(value)
            .map((c) => escape(c))
            .join(`<b>${escape(value)}</b>`)
        : escape(v),
    })),
    ...suggestions.map((v) => ({
      type: 'suggestion',
      value: removeTags(v),
      title: v,
    })),
  ].slice(0, count);
};
