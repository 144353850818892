import addToCartInternal from './addToCartInternal.js';

/** @typedef {import('../../../../components/product-buy-form/typedefs.js').CartProduct} CartProduct */

/*
Cart content HTML chunk:

<div class="BasketPopin ...

    <div class="BasketPopin-products...
</div>
*/

/**
 * Add products to cart and get modal HTML content
 *
 * @param {CartProduct[]} products Products to add
 * @param {string} url URL is different than cartAddURL
 */
export default async (products, url) => {
  const response = await addToCartInternal({
    url,
    products,
    headers: { Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest' },
  });

  return response.json();
};
