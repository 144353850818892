import componentRegistry from '../registry.js';
import { getOffersListTemplateURL } from '../../configuration/Configuration.js';
import rejectNonOkResponse from '../../scripts/api/rejectNonOkResponse.js';

/**
 * Offer list
 *
 * Used in 4 places:
 *
 * - Product page more sellers block
 * - FSI page more sellers block
 * - Product page Offer list popin
 * - Search page Offer list popin
 */
export default class OfferList {
  #defaultFilter;
  #defaultOrder;
  /** @param {HTMLElement} el The DOM element */
  constructor({ el }) {
    this.el = el;
    this.#defaultFilter = this.el.querySelector('.js-offersFilter:checked').value;
    this.#defaultOrder = this.el.querySelector('.js-offersOrderBy').value || null;

    // filterby & orderBy
    this.el.addEventListener('change', (e) => {
      e.preventDefault();
      const filterValue = this.el.querySelector('.js-offersFilter:checked').value;
      const orderValue = this.el.querySelector('.js-offersOrderBy').value || null;
      const filter = e.target.closest('.js-offersFilter');
      const orderBy = e.target.closest('.js-offersOrderBy');
      if (!filter && !orderBy) return;

      if (filter) {
        this.#displayOffersList(1, orderValue, e.target.value);
      }

      if (orderBy) {
        this.#displayOffersList(1, e.target.value, filterValue);
      }
    });

    // Pagination
    this.el.addEventListener('click', (e) => {
      const pageButton = e.target.closest('.js-pageButton');
      const filterValue = this.el.querySelector('.js-offersFilter:checked').value;
      const orderValue = this.el.querySelector('.js-offersOrderBy').value || null;

      if (!pageButton) return;
      e.preventDefault();
      this.#displayOffersList(parseInt(pageButton.dataset.page, 10), orderValue, filterValue);
    });
  }

  /**
   * Fetches entire popin & replace HTML
   *
   * @param {number} page
   * @param {string} orderBy
   * @param {string} filterBy
   */
  async #displayOffersList(page, orderBy = this.#defaultOrder, filterBy = this.#defaultFilter) {
    const url = getOffersListTemplateURL({
      prid: this.el.dataset.prid,
      catalog: this.el.dataset.catalog,
      page,
      contextofferPid: this.el.dataset.contextoffer,
      filter: filterBy,
      order: orderBy,
    });

    try {
      const html = await (await rejectNonOkResponse(fetch(url))).text();
      this.el.innerHTML = html;
    } catch (error) {
      reportError(error);
    }
  }
}

componentRegistry.define('js-offerList', OfferList);
