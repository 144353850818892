import rejectNonOkResponse from '../rejectNonOkResponse.js';
import { cartDeleteURL } from '../../../configuration/Configuration.js';

/**
 * Delete products from cart
 *
 * @param {object[]} products Products to delete
 * @param {string} products[].prid The product ID (aka PRID) of an product.
 * @param {string} products[].offer The product's offer.
 * @param {string} products[].relatedPrid The product's related PRID (in case of a service).
 */
export default async (products) =>
  rejectNonOkResponse(
    fetch(cartDeleteURL, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
      body: JSON.stringify(
        products.map(({ prid, catalog, offer, relatedPrid }) => ({
          productID: prid,
          referential: catalog,
          offer,
          masterProductID: relatedPrid,
        }))
      ),
    })
  ).then(() => true);
