import comparable from '../../../helpers/string/comparable.js';
import getHistorySuggestionsInternal from './getHistorySuggestionsInternal.js';
import setHistorySuggestionsInternal from './setHistorySuggestionsInternal.js';

/**
 * Add given term to the history and remove suggestions that match the same normalized form
 *
 * @param {string} value New term to suggest
 */
export default (value) => {
  const previousSuggestions = getHistorySuggestionsInternal();
  const comparableValue = comparable(value);
  // try to delete the previous entry that have the same normalized form (shouldn't have more that one)
  const suggestions = previousSuggestions.filter((v) => comparable(v) !== comparableValue);
  suggestions.unshift(value);
  setHistorySuggestionsInternal(suggestions);
  return suggestions.length;
};
