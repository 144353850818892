import { deleteWishlistItemTemplateURL } from '../../../configuration/Configuration.js';
import rejectNonOkResponse from '../rejectNonOkResponse.js';

export default (wishlistPublicId, prid, catalog) =>
  rejectNonOkResponse(
    fetch(deleteWishlistItemTemplateURL({ wishlistPublicId, prid, catalog }), {
      method: 'DELETE',
      credentials: 'include',
    })
  ).then(() => true);
