import componentRegistry from '../../registry.js';
import Kamino from '../kamino.js';
import './index.less';

export default class KaminoSquad extends Kamino {
  #image;
  trackingClick;

  constructor({ el }) {
    super({ el });
    this.#image = el.querySelector('.js-kamino-image');
    this.trackingClick = el.dataset.trackingClick;

    this.#image?.addEventListener('click', this.#onClickImage.bind(this));
  }

  #onClickImage() {
    //fire on click product info tracking event
    super.postTracking(this.trackingClick, { click_zone: 'image', act: 'redirect' });
  }
}

componentRegistry.define('js-kamino-squad', KaminoSquad);
